import { WaterproofingTypes } from "@iko-design-center/shared";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { styles } from "../../../styles";
import { InfoIcon } from "../../common/Icons";
import { Spacer } from "../../common/Spacer";
import { FontFamilies, H1, H2, P } from "../../common/Typography";
import { Sidebar } from "../Sidebar";
import { useWaterproofing } from "./waterproofing.hooks";
import { Intro } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";
import { AirPurifyingIcon, CirculairIcon, FlameFreeIcon, FlyingFireIcon, LiquidIcon, OneBituminousIcon, TwoBituminousIcon } from "../../common/WaterproofingIcons";
import { IconRadioButton, IconRadioButtonRow } from "../../common/IconRadioButton";

const WaterproofingTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;

  @media screen and (pointer: coarse) {
    padding-inline: 16px;
  }

  h5 {
    font-size: 14px;
    line-height: 20px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const IconRadioButtonList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  @media screen and (pointer: coarse) {
    gap: 16px;
    padding: 16px;
  }
`;

const doubleBituminousList = [
  {
    type: WaterproofingTypes.TWO_BITUMINOUS, info: "flatRoof.waterproofing.twoBituminousInfo",
    properties: [{ label: "flatRoof.waterproofing.twoBituminousLabel", icon: <TwoBituminousIcon /> }]
  },
  {
    type: WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR, info: "flatRoof.waterproofing.twoBituminousAirPurifyingCirculairInfo",
    properties: [{ label: "flatRoof.waterproofing.circulairLabel", icon: <CirculairIcon /> }, { label: "flatRoof.waterproofing.airPurifyingLabel", icon: <AirPurifyingIcon /> }]
  },
  {
    type: WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_FLAME_FREE, info: "flatRoof.waterproofing.twoBituminousAirPurifyingFlameFreeInfo",
    properties: [{ label: "flatRoof.waterproofing.flyingFireLabel", icon: <FlyingFireIcon /> }, { label: "flatRoof.waterproofing.airPurifyingLabel", icon: <AirPurifyingIcon /> }]
  },
  {
    type: WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR_FLAME_FREE, info: "flatRoof.waterproofing.twoBituminousAirPurifyingCirculairFlameFreeInfo",
    properties: [{ label: "flatRoof.waterproofing.circulairLabel", icon: <CirculairIcon /> }, { label: "flatRoof.waterproofing.airPurifyingLabel", icon: <AirPurifyingIcon /> }, { label: "flatRoof.waterproofing.flyingFireLabel", icon: <FlyingFireIcon /> }]
  },
  {
    type: WaterproofingTypes.TWO_BITUMINOUS_CIRCULAIR, info: "flatRoof.waterproofing.twoBituminousCirculairInfo",
    properties: [{ label: "flatRoof.waterproofing.circulairLabel", icon: <CirculairIcon /> }]
  },
  {
    type: WaterproofingTypes.TWO_BITUMINOUS_FLAME_FREE, info: "flatRoof.waterproofing.twoBituminousFlameFreeInfo",
    properties: [{ label: "flatRoof.waterproofing.flyingFireLabel", icon: <FlyingFireIcon /> }]
  },
  {
    type: WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING, info: "flatRoof.waterproofing.twoBituminousAirPurifyingInfo",
    properties: [{ label: "flatRoof.waterproofing.airPurifyingLabel", icon: <AirPurifyingIcon /> }]
  },
  // { type: WaterproofingTypes.TWO_BITUMINOUS, label: "flatRoof.waterproofing.twoBituminous", info: "flatRoof.waterproofing.twoBituminousInfo", icons: [<TwoBituminousIcon />] },
  // { type: WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING, label: "flatRoof.waterproofing.twoBituminousAirPurifying", info: "flatRoof.waterproofing.twoBituminousAirPurifyingInfo", icons: [<AirPurifyingIcon />] },
  // { type: WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR, label: "flatRoof.waterproofing.twoBituminousAirPurifyingCirculair", info: "flatRoof.waterproofing.twoBituminousAirPurifyingCirculairInfo", icons: [<CirculairIcon />, <AirPurifyingIcon />] },
  // { type: WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_FLAME_FREE, label: "flatRoof.waterproofing.twoBituminousAirPurifyingFlameFree", info: "flatRoof.waterproofing.twoBituminousAirPurifyingFlameFreeInfo", icons: [<AirPurifyingIcon />, <FlyingFireIcon />] },
  // { type: WaterproofingTypes.TWO_BITUMINOUS_CIRCULAIR, label: "flatRoof.waterproofing.twoBituminousCirculair", info: "flatRoof.waterproofing.twoBituminousCirculairInfo", icons: [<CirculairIcon />] },
  // { type: WaterproofingTypes.TWO_BITUMINOUS_FLAME_FREE, label: "flatRoof.waterproofing.twoBituminousFlameFree", info: "flatRoof.waterproofing.twoBituminousFlameFreeInfo", icons: [<FlyingFireIcon />] },
  // { type: WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR_FLAME_FREE, label: "flatRoof.waterproofing.twoBituminousAirPurifyingCirculairFlameFree", info: "flatRoof.waterproofing.twoBituminousAirPurifyingCirculairFlameFreeInfo", icons: [<CirculairIcon />, <AirPurifyingIcon />, <FlyingFireIcon />] },
]
const singleBituminousList = [
  {
    type: WaterproofingTypes.ONE_BITUMINOUS_AIR_PURIFYING_CIRCULAIR, info: "flatRoof.waterproofing.oneBituminousAirPurifyingCirculairInfo",
    properties: [{ label: "flatRoof.waterproofing.circulairLabel", icon: <CirculairIcon /> }, { label: "flatRoof.waterproofing.airPurifyingLabel", icon: <AirPurifyingIcon /> }]
  },
  {
    type: WaterproofingTypes.ONE_BITUMINOUS_FLAME_FREE, info: "flatRoof.waterproofing.oneBituminousFlameFreeInfo",
    properties: [{ label: "flatRoof.waterproofing.flameFreeLabel", icon: <FlameFreeIcon /> }]
  },
  // { type: WaterproofingTypes.ONE_BITUMINOUS_AIR_PURIFYING_CIRCULAIR, label: "flatRoof.waterproofing.oneBituminousAirPurifyingCirculair", info: "flatRoof.waterproofing.oneBituminousAirPurifyingCirculairInfo", icons: [<AirPurifyingIcon />, <CirculairIcon />] },
  // { type: WaterproofingTypes.ONE_BITUMINOUS_FLAME_FREE, label: "flatRoof.waterproofing.oneBituminousFlameFree", info: "flatRoof.waterproofing.oneBituminousFlameFreeInfo", icons: [<FlameFreeIcon />] },
]
const liquidList = [
  {
    type: WaterproofingTypes.LIQUID, info: "flatRoof.waterproofing.liquidInfo",
    properties: [{ label: "flatRoof.waterproofing.liquidLabel", icon: <LiquidIcon /> }]
  },
  // { type: WaterproofingTypes.LIQUID, label: "flatRoof.waterproofing.liquid", info: "flatRoof.waterproofing.liquidInfo", icons: [<LiquidIcon />] },
]

export const Waterproofing = () => {
  const {
    t,
    waterproofingOptions,
    flatRoofWaterproofingType,
    setWaterproofingType,
    handleNextButtonClick,
    nextButtonDisabled,
  } = useWaterproofing();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  const renderIconRadioButton = (type: WaterproofingTypes, properties: any[]) => {
    if (!waterproofingOptions.includes(type)) return
    return (
      <IconRadioButton key={type} checked={flatRoofWaterproofingType === type} onClick={() => setWaterproofingType(type)} count={properties.length}>
        {properties.map((property, index) =>
        (<IconRadioButtonRow key={index}>
          <div className="icon">{property.icon}</div>
          <div className="label">{t(property.label)}</div>
        </IconRadioButtonRow>))}
      </IconRadioButton>
    );
  }

  const renderInfo = (label: string, info: string) => {
    if (!info) return null;
    return (
      <Info
        key={`info-${label}`}
        title={t(label)}
        info={t(info)}
      />
    );
  }

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonDisabled={nextButtonDisabled}
      nextButtonText={`${t("flatRoof.waterproofing.nextButton")} (${currentStep + 1}/${totalSteps})`}
    >
      <Intro>
        <H1>{t("flatRoof.waterproofing.title")}</H1>
      </Intro>
      {!isMobile() && <Spacer heightPx={40} />}

      {/* Double Bituminous */}
      {doubleBituminousList.some(item => waterproofingOptions.includes(item.type)) &&
        <>
          <WaterproofingTitle>
            <h5>{t("flatRoof.waterproofing.twoBituminous")}</h5>
            <TwoBituminousIcon />
          </WaterproofingTitle>
          <Spacer heightPx={16} />
          <IconRadioButtonList>
            {doubleBituminousList.filter(item => waterproofingOptions.includes(item.type)).map(item => renderIconRadioButton(item.type, item.properties))}
          </IconRadioButtonList>
          {!isMobile() && <Spacer heightPx={40} />}
        </>
      }

      {/* Single Bituminous */}
      {singleBituminousList.some(item => waterproofingOptions.includes(item.type)) &&
        <>
          <WaterproofingTitle>
            <h5>{t("flatRoof.waterproofing.oneBituminous")}</h5>
            <OneBituminousIcon />
          </WaterproofingTitle>
          <Spacer heightPx={16} />
          <IconRadioButtonList>
            {singleBituminousList.filter(item => waterproofingOptions.includes(item.type)).map(item => renderIconRadioButton(item.type, item.properties))}
          </IconRadioButtonList>
          {!isMobile() && <Spacer heightPx={40} />}
        </>
      }

      {/* Liquid */}
      {liquidList.some(item => waterproofingOptions.includes(item.type)) &&
        <>
          <WaterproofingTitle>
            <h5>{t("flatRoof.waterproofing.liquid")}</h5>
            <LiquidIcon />
          </WaterproofingTitle>
          <Spacer heightPx={16} />
          <IconRadioButtonList>
            {liquidList.filter(item => waterproofingOptions.includes(item.type)).map(item => renderIconRadioButton(item.type, item.properties))}
          </IconRadioButtonList>
          {!isMobile() && <Spacer heightPx={40} />}
        </>
      }

      {/* INFO */}

      {/* Double Bituminous */}
      {doubleBituminousList.map((item: any) => {
        if (flatRoofWaterproofingType === item.type) return renderInfo(item.label, item.info);
        else return null
      })}

      {/* Single Bituminous */}
      {singleBituminousList.map((item: any) => {
        if (flatRoofWaterproofingType === item.type) return renderInfo(item.label, item.info);
        else return null
      })}

      {/* Liquid */}
      {liquidList.map((item: any) => {
        if (flatRoofWaterproofingType === item.type) return renderInfo(item.label, item.info);
        else return null
      })}
    </Sidebar>
  );
};

type InfoProps = {
  title: string;
  info: string;
};

const Text = styled.div`
  font-size: ${13 / 16}rem;
  color: ${styles.colors.darkBlue};
  line-height: 1.2rem;

  @media screen and (pointer: coarse) {
    padding: 0;
    font-size: 15px;
    line-height: 22px;
    color: #2e3341;
  }
`;


const InfoContainer = styled.div`
  padding: 0;

  h2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;

    @media screen and (pointer: coarse) {
      margin-bottom: 8px;
    }
  }

  @media screen and (pointer: coarse) {
    padding-inline: 16px;
    margin-bottom: 32px;
  }
`;

const Info = ({ title, info }: InfoProps) => {
  return (
    <InfoContainer>
      <Spacer heightPx={!isMobile() ? 40 : 16} />
      <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
        {title}
      </H2>
      {!isMobile() && <Spacer heightPx={16} />}
      {isMobile() ? (
        <Text>{info}</Text>
      ) : (
        <P fontSizePx={13} color={styles.colors.darkBlue}>
          {info}
        </P>
      )}
    </InfoContainer>
  );
};

const DisabledInfoContainer = styled.div`
  display: inline-flex;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  font-family: ${FontFamilies.ROBOTO};
  font-size: ${12 / 16}rem;
  color: ${styles.colors.dark2};
  font-weight: 500;
  line-height: 1rem;
  color: ${styles.colors.darkBlue};
`;

type DisabledInfoProps = {
  disabledText: string;
  disabledReturnLink?: string;
  disabledReturnText?: string;
};

// eslint-disable-next-line
const DisabledInfo = ({
  disabledText,
  disabledReturnLink,
  disabledReturnText,
}: DisabledInfoProps) => {
  return (
    <>
      <Spacer heightPx={12} />
      <DisabledInfoContainer>
        <P fontSizePx={12} lineHeightPx={16} color={styles.colors.darkBlue}>
          <InfoIcon />
          {"\u00A0"}
          {disabledText}
          {"\u00A0"}
          {disabledReturnLink && disabledReturnText && (
            <StyledLink to={disabledReturnLink}>
              {disabledReturnText}
            </StyledLink>
          )}
        </P>
      </DisabledInfoContainer>
    </>
  );
};

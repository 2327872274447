import {
  FEMaterial,
  FLAT_ROOF_R_VALUE_RSI,
  FLAT_ROOF_R_VALUE_RSE,
  ThermalPerformanceLayer,
  FlatRoofConfigurationState,
  FlatRoofThermalPerformanceState,
  initialFlatRoofThermalPerformanceState,
} from "@iko-design-center/shared"

export function updateThermalLayer(
  thermalPerformance: FlatRoofThermalPerformanceState,
  material: FEMaterial,
  type: string
) {
  const draft: FlatRoofThermalPerformanceState = { ...thermalPerformance }
  // @ts-ignore
  draft[type] = getThermalPerformanceLayer(material)
  return calculateThermalPerformance(draft)
}

function getThermalPerformanceLayer(
  material: FEMaterial
): ThermalPerformanceLayer | null {
  if (!material) return null

  const isVariable =
    material.lambdaThicknessThresholds &&
    material.lambdaThicknessThresholds.length > 0 &&
    material.lambdas.length > 1

  const thickness = material.thickness
  let lambda: number

  if (!isVariable) {
    lambda = material.lambdas[0]
  } else {
    material.lambdaThicknessThresholds!.forEach(
      (threshold: any, index: any) => {
        if (thickness > threshold) {
          lambda = material.lambdas[index + 1]
        }
      }
    )
  }

  return {
    thickness,
    lambda: lambda!,
    RValue: null,
  }
}

export function getThermalPerformance(
  state: FlatRoofConfigurationState
): FlatRoofThermalPerformanceState {
  const thermalPerformance = { ...initialFlatRoofThermalPerformanceState }

  const topLayerPerformance = getThermalPerformanceLayer(
    state.roofStructure.topLayerMaterial!
  )
  if (topLayerPerformance) {
    thermalPerformance.topLayer = topLayerPerformance
  }

  const bottomLayerPerformance = getThermalPerformanceLayer(
    state.roofStructure.bottomLayerMaterial!
  )

  if (bottomLayerPerformance) {
    thermalPerformance.bottomLayer = bottomLayerPerformance
  }

  const insulationPerformance = getThermalPerformanceLayer(
    state.roofStructure.insulationMaterial!
  )
  if (insulationPerformance) {
    thermalPerformance.insulation = insulationPerformance
  }

  const vaporShieldPerformance = getThermalPerformanceLayer(
    state.roofStructure.vaporShieldMaterial!
  )
  if (vaporShieldPerformance) {
    thermalPerformance.vaporShield = vaporShieldPerformance
  }

  const roofFloorPerformance = getThermalPerformanceLayer(
    state.roofStructure.roofFloorMaterial!
  )
  if (roofFloorPerformance) {
    thermalPerformance.roofFloor = roofFloorPerformance
  }

  return calculateThermalPerformance(thermalPerformance)
}

function calculateThermalPerformance(
  state: FlatRoofThermalPerformanceState
): FlatRoofThermalPerformanceState {
  const draft = { ...state }

  ;[
    "topLayer",
    "bottomLayer",
    "insulation",
    "vaporShield",
    "roofFloor",
  ].forEach((layer: string) => {
    // @ts-ignore
    const { thickness, lambda } = draft[layer] as ThermalPerformanceLayer

    if (thickness && lambda) {
      // @ts-ignore
      draft[layer] = {
        thickness,
        lambda,
        RValue: parseFloat((thickness / 1000 / lambda).toFixed(2)),
      }
    }
  })

  draft.RTValue = parseFloat(
    (
      draft.topLayer.RValue! +
      draft.bottomLayer.RValue! +
      draft.insulation.RValue! +
      draft.vaporShield.RValue! +
      draft.roofFloor.RValue! +
      FLAT_ROOF_R_VALUE_RSE +
      FLAT_ROOF_R_VALUE_RSI -
      0.14
    ).toFixed(1)
  )
  if (draft.RTValue) {
    draft.UCValue = parseFloat((1 / draft.RTValue).toFixed(2))
  } else {
    draft.UCValue = 0
  }

  return draft
}

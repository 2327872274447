import { FLAT_ROOF_MINIMUM_INSULATION_THICKNESS } from "@iko-design-center/shared"
import { SyntheticEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { updateThermalLayer } from "../../../store/flat-roof/thermalPerformance"
import { useModal } from "../../../store/modal/hooks"

export function useThermalCalculation() {
  const {
    configurationState,
    thermalPerformance,
    setInsulationMaterial,
  } = useFlatRoof()
  const { closeModal } = useModal()
  const { t } = useTranslation() as any

  const [localThermalPerformance, setLocalThermalPerformance] = useState(
    thermalPerformance
  )
  const [localInsulationMaterial, setLocalInsulationMaterial] = useState(
    configurationState.roofStructure.insulationMaterial!
  )
  const [editableThicknessValue, setEditableThicknessValue] = useState(
    thermalPerformance.insulation.thickness!.toString()
  )
  const [editableThicknessValid, setEditableThicknessValid] = useState(true)

  function handleEditableThicknessChange(
    event: SyntheticEvent<HTMLInputElement>
  ) {
    const value = (event.target as HTMLInputElement).value
    setEditableThicknessValue(value)
    const thickness = parseInt(value)

    if (
      thickness &&
      thickness >= FLAT_ROOF_MINIMUM_INSULATION_THICKNESS &&
      thickness.toString().length <= 3
      // && localInsulationMaterial.lambdaThicknessThresholds?.every(
      //   (threshold) => thickness >= threshold
      // )
    ) {
      const updatedMaterial = {
        ...localInsulationMaterial,
        thickness,
      }
      setLocalInsulationMaterial(updatedMaterial)
      setLocalThermalPerformance(
        updateThermalLayer(
          localThermalPerformance,
          updatedMaterial,
          "insulation"
        )
      )
      setEditableThicknessValid(true)
    } else {
      setEditableThicknessValid(false)
    }
  }

  function handleCardClick(e: SyntheticEvent) {
    e.stopPropagation()
  }

  function handleCancel() {
    closeModal()
  }

  function handleApply() {
    setInsulationMaterial(localInsulationMaterial)
    closeModal()
  }

  return {
    t,

    thermalPerformance: localThermalPerformance,
    topLayer: configurationState.securingMethods.topLayer!,
    bottomLayer: configurationState.securingMethods.bottomLayer!,
    insulation: configurationState.securingMethods.insulation!,
    vaporShield: configurationState.securingMethods.vaporShield!,
    roofFloorType: configurationState.roofFloor.type!,

    editableThicknessValue,
    editableThicknessValid,
    handleEditableThicknessChange,

    handleCardClick,
    handleCancel,
    handleApply,
  }
}

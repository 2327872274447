import React from "react";
import styled from "styled-components";
import { InfoIconBig } from "../common/Icons";
import { Models } from "./renderer.api";
import { useModal } from "../../store/modal/hooks";
import { useApplication } from "../../store/application/hooks";
import { useViewerDetail } from "./viewerDetail.hooks";

const SectionRight = styled.div`
  position: absolute;
  right: 64px;
  top: 20px;
  z-index: 7;
  display: flex;
  align-items: flex-start;
  transition: 0.1s opacity ease-out;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  @media screen and (pointer: coarse) {
    top: unset;
    bottom: 16px;
    right: 16px;
  }
`;

export const ViewerDetail = () => {
  const { displayDetailInfoModal } = useModal();
  const { currentModel } = useApplication();
  const { detailDescription } = useViewerDetail();
  const description = detailDescription();
  const shouldShow =
    currentModel !== Models.DOLLHOUSE &&
    // FLAT ROOF
    currentModel !== Models.FLAT_ROOF &&
    // CAVITY WALL
    currentModel !== Models.CAVITY_WALL &&
    // SLOPED ROOF
    currentModel !== Models.SLOPED_ROOF &&
    // VENTILATED WALL
    currentModel !== Models.VENTILATED_WALL &&
    // FLOOR
    currentModel !== Models.FLOOR &&
    description !== undefined;

  function handleInfoClick() {
    displayDetailInfoModal();
  }

  return (
    <SectionRight>
      {shouldShow && (
        <InfoIconBig onClick={handleInfoClick} />
      )}
    </SectionRight>
  );
};

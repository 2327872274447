import React from "react";

export const TiledRoofIcon = () => (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" transform="translate(0.870117 0.977539)" fill="white" />
        <path d="M13.6887 8.58455C13.7044 7.85503 14.5594 6.396 17.8541 6.396C21.1487 6.396 21.8076 7.99623 21.8076 8.58455" stroke="black" stroke-width="2.5" />
        <path d="M13.6887 21.4703C13.7044 20.7408 14.5594 19.2817 17.8541 19.2817C21.1487 19.2817 21.8076 20.882 21.8076 21.4703" stroke="black" stroke-width="2.5" />
        <path d="M13.6887 33.0523C13.7044 32.3228 14.5594 30.8638 17.8541 30.8638C21.1487 30.8638 21.8076 32.464 21.8076 33.0523" stroke="black" stroke-width="2.5" />
        <path d="M13.6887 45.3902C13.7044 44.6607 14.5594 43.2017 17.8541 43.2017C21.1487 43.2017 21.8076 44.8019 21.8076 45.3902" stroke="black" stroke-width="2.5" />
        <path d="M30.1245 8.58455C30.1402 7.85503 30.9952 6.396 34.2899 6.396C37.5845 6.396 38.2434 7.99623 38.2434 8.58455" stroke="black" stroke-width="2.5" />
        <path d="M30.1245 21.4703C30.1402 20.7408 30.9952 19.2817 34.2899 19.2817C37.5845 19.2817 38.2434 20.882 38.2434 21.4703" stroke="black" stroke-width="2.5" />
        <path d="M30.1245 33.0523C30.1402 32.3228 30.9952 30.8638 34.2899 30.8638C37.5845 30.8638 38.2434 32.464 38.2434 33.0523" stroke="black" stroke-width="2.5" />
        <path d="M30.1245 45.3902C30.1402 44.6607 30.9952 43.2017 34.2899 43.2017C37.5845 43.2017 38.2434 44.8019 38.2434 45.3902" stroke="black" stroke-width="2.5" />
        <path d="M14.9402 8.58447H8.24219C7.13762 8.58447 6.24219 9.4799 6.24219 10.5845V43.5588C6.24219 44.6634 7.13762 45.5588 8.24219 45.5588H14.9497M20.5365 8.58447H31.37M20.5365 45.5588H31.3795M37.0743 8.58447H43.4977C44.6023 8.58447 45.4977 9.4799 45.4977 10.5845V43.5588C45.4977 44.6634 44.6023 45.5588 43.4977 45.5588H36.9928" stroke="black" stroke-width="2.5" />
        <path d="M13.6836 45.3907V9.00928" stroke="black" stroke-width="2.5" />
        <path d="M21.7864 45.3907V9.00928" stroke="black" stroke-width="2.5" />
        <path d="M30.1245 45.3907V9.00928" stroke="black" stroke-width="2.5" />
        <path d="M38.3215 45.3907V9.00928" stroke="black" stroke-width="2.5" />
        <line x1="6.24219" y1="20.2202" x2="13.6836" y2="20.2202" stroke="black" stroke-width="2.5" />
        <line x1="6.24219" y1="31.8022" x2="13.6836" y2="31.8022" stroke="black" stroke-width="2.5" />
        <line x1="22.1492" y1="20.2202" x2="29.5906" y2="20.2202" stroke="black" stroke-width="2.5" />
        <line x1="22.1492" y1="31.8022" x2="29.5906" y2="31.8022" stroke="black" stroke-width="2.5" />
        <line x1="38.0564" y1="20.2202" x2="45.4979" y2="20.2202" stroke="black" stroke-width="2.5" />
        <line x1="38.0564" y1="31.8022" x2="45.4979" y2="31.8022" stroke="black" stroke-width="2.5" />
    </svg>
);
export const SlatedRoofIcon = () => (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" transform="translate(0.870117 0.000488281)" fill="white" />
        <rect x="7.49219" y="6.67969" width="36.7556" height="36.6418" rx="2.75" stroke="black" stroke-width="2.5" />
        <path d="M6.44604 15.165H45.2941" stroke="black" stroke-width="2.5" />
        <path d="M6.24219 25.0576H45.0107" stroke="black" stroke-width="2.5" />
        <path d="M6.24219 34.9502H45.0107" stroke="black" stroke-width="2.5" />
        <path d="M13.75 34.9502L13.75 44.5712" stroke="black" stroke-width="2.5" />
        <path d="M13.75 15.4365L13.75 25.0575" stroke="black" stroke-width="2.5" />
        <path d="M26.4417 34.9502L26.4416 44.5712" stroke="black" stroke-width="2.5" />
        <path d="M26.4417 15.4365L26.4416 25.0575" stroke="black" stroke-width="2.5" />
        <path d="M20.0959 25.3291L20.0959 34.9501" stroke="black" stroke-width="2.5" />
        <path d="M19.5244 5.54395L19.5244 15.165" stroke="black" stroke-width="2.5" />
        <path d="M39.1331 34.9502L39.1331 44.5712" stroke="black" stroke-width="2.5" />
        <path d="M39.1331 15.4365L39.1331 25.0575" stroke="black" stroke-width="2.5" />
        <path d="M32.7874 25.3291L32.7874 34.9501" stroke="black" stroke-width="2.5" />
        <path d="M32.2158 5.54395L32.2158 15.165" stroke="black" stroke-width="2.5" />
    </svg>
);

import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InfoIcon } from "../common/Icons";
import { StandardModal } from "./StandardModal";
import { Spacer } from "../common/Spacer";
import { useViewerDetail } from "../viewer/viewerDetail.hooks";
import { P } from "../common/Typography";

const Content = styled.div`
  display: flex;
  justify-content: left;
  align-items: top;
  color: #fff;

  p {
    color: #fff;
  }
`;

export const DetailInfo = () => {
  const { t } = useTranslation();
  const { detailTitle, detailDescription } = useViewerDetail();

  return (
    <StandardModal
      title={detailTitle() || ""}
      buttonText={t("modal.supportSuccess.button")}
      titleIcon={InfoIcon}
      classnames={"detail-info standard-modal"}
    >
      <Spacer heightPx={19.5} />
      <Content className={"text-white"}>
        <P innerHTML={detailDescription()}> </P>
      </Content>
    </StandardModal>
  );
};

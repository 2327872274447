import {
  RoofTypeClimateClasses,
  RoofTypeTypes,
} from "@iko-design-center/shared";
import React from "react";
import styled from "styled-components";
import { InfoIcon } from "../../common/Icons";
import { InfoList } from "../../common/InfoList";
import { RadioButton } from "../../common/RadioButton";
import { Spacer } from "../../common/Spacer";
import { H1, H2, P } from "../../common/Typography";
import { UnstyledButton } from "../../common/UnstyledButton";
import { Grid } from "../Grid";
import { IconTile } from "../IconTile";
import { useRoofType } from "./roofType.hooks";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";
import { EnergyRoofIcon, GreenRoofIcon, LivingRoofIcon, RetentionRoofIcon, StandardRoofIcon } from "../../common/FlatRoofIcons";

const ClimateClassTitleContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (pointer: coarse) {
    padding: 16px 16px 0 16px;

    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const PositionedInfoIcon = styled(UnstyledButton)`
  margin-left: ${(props) => props.theme.designWidth(7)}px;

  @media screen and (pointer: coarse) {
    margin-left: 12px;
  }
`;

export const RoofType = () => {
  const {
    t,
    roofTypeType,
    roofTypeClimateClass,
    displayClimateClassModal,
    setRoofTypeType,
    setRoofTypeClimateClass,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useRoofType();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1]?.step ?? 0

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("flatRoof.roofType.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("flatRoof.roofType.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("flatRoof.roofType.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <Grid rows={2} gaps={true} className={"tiles-grid -no-overflow"}>
        <IconTile
          selected={roofTypeType === RoofTypeTypes.ENERGY_ROOF}
          text={t("flatRoof.roofType.energyRoof")}
          onClick={() => setRoofTypeType(RoofTypeTypes.ENERGY_ROOF)}
          colorCode="#F2C63A">
          <EnergyRoofIcon />
        </IconTile>
        <IconTile
          selected={roofTypeType === RoofTypeTypes.LIVING_ROOF}
          text={t("flatRoof.roofType.livingRoof")}
          onClick={() => setRoofTypeType(RoofTypeTypes.LIVING_ROOF)}
          colorCode="#D04539">
          <LivingRoofIcon />
        </IconTile>
        <IconTile
          selected={roofTypeType === RoofTypeTypes.GREEN_ROOF}
          text={t("flatRoof.roofType.greenRoof")}
          onClick={() => setRoofTypeType(RoofTypeTypes.GREEN_ROOF)}
          colorCode="#92B838">
          <GreenRoofIcon />
        </IconTile>
        <IconTile
          selected={roofTypeType === RoofTypeTypes.RETENTION_ROOF}
          text={t("flatRoof.roofType.retentionRoof")}
          onClick={() => setRoofTypeType(RoofTypeTypes.RETENTION_ROOF)}
          colorCode="#4F9ED8">
          <RetentionRoofIcon />
        </IconTile>
        <IconTile
          selected={roofTypeType === RoofTypeTypes.STANDARD_ROOF}
          text={t("flatRoof.roofType.standardRoof")}
          onClick={() => setRoofTypeType(RoofTypeTypes.STANDARD_ROOF)}
          colorCode="#535353">
          <StandardRoofIcon />
        </IconTile>
      </Grid>
      <Spacer heightPx={32} />

      <ClimateClassTitleContainer onClick={displayClimateClassModal}>
        <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
          {t("flatRoof.roofType.climateClassTitle")}
        </H2>
        <PositionedInfoIcon>
          <InfoIcon />
        </PositionedInfoIcon>
      </ClimateClassTitleContainer>
      <RadioButtonList>
        {!isMobile() && <Spacer heightPx={12} />}
        <RadioButton
          value={RoofTypeClimateClasses.CLIMATE_CLASS_3}
          checked={
            roofTypeClimateClass === RoofTypeClimateClasses.CLIMATE_CLASS_3
          }
          onClick={() =>
            setRoofTypeClimateClass(RoofTypeClimateClasses.CLIMATE_CLASS_3)
          }
          text={t("flatRoof.roofType.climateClass3")}
          disabled={false}
        />
        {!isMobile() && <Spacer heightPx={12} />}
        <RadioButton
          value={RoofTypeClimateClasses.CLIMATE_CLASS_4}
          checked={
            roofTypeClimateClass === RoofTypeClimateClasses.CLIMATE_CLASS_4
          }
          onClick={() =>
            setRoofTypeClimateClass(RoofTypeClimateClasses.CLIMATE_CLASS_4)
          }
          text={t("flatRoof.roofType.climateClass4")}
          disabled={false}
        />
        <Spacer heightPx={!isMobile() ? 62 : 16} />
        {roofTypeType === RoofTypeTypes.ENERGY_ROOF && (
          <InfoList
            info={[
              t("flatRoof.roofType.energyRoofInfo0"),
              t("flatRoof.roofType.energyRoofInfo1"),
            ]}
            title={t("flatRoof.roofType.energyRoofBottomTitle")}
          />
        )}
        {roofTypeType === RoofTypeTypes.LIVING_ROOF && (
          <InfoList
            info={[
              t("flatRoof.roofType.livingRoofInfo0"),
              t("flatRoof.roofType.livingRoofInfo1"),
            ]}
            title={t("flatRoof.roofType.livingRoofBottomTitle")}
          />
        )}
        {roofTypeType === RoofTypeTypes.GREEN_ROOF && (
          <InfoList
            info={[
              t("flatRoof.roofType.greenRoofInfo0"),
              t("flatRoof.roofType.greenRoofInfo1"),
            ]}
            title={t("flatRoof.roofType.greenRoofBottomTitle")}
          />
        )}
        {roofTypeType === RoofTypeTypes.RETENTION_ROOF && (
          <InfoList
            info={[
              t("flatRoof.roofType.retentionRoofInfo0"),
              t("flatRoof.roofType.retentionRoofInfo1"),
            ]}
            title={t("flatRoof.roofType.retentionRoofBottomTitle")}
          />
        )}
        {roofTypeType === RoofTypeTypes.STANDARD_ROOF && (
          <InfoList
            info={[
              t("flatRoof.roofType.standardRoofInfo0"),
              t("flatRoof.roofType.standardRoofInfo1"),
            ]}
            title={t("flatRoof.roofType.standardRoofBottomTitle")}
          />
        )}
      </RadioButtonList>
    </Sidebar>
  );
};

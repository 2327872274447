import {
  // FLAT ROOF
  RoofFloorTypes,
  RoofTypeTypes,
  SecuringMethodTypes,
  WaterproofingTypes,
  BottomLayerTypes,
  TopLayerTypes,
  InsulationTypes,
  VaporShieldTypes,

  // CAVITY WALL
  WallTypeTypes,
  WallInsulationTypes,

  // SLOPED ROOF
  SlopedRoofTypeTypes,
  SlopedRoofInsulationTypeTypes,

  // VENTILATED WALL
  VentilatedWallWallTypeTypes,
  VentilatedWallFacadeCladdingTypes,
  VentilatedWallInsulationTypes,

  // FLOOR
  FloorHeatingTypes,
  FloorInsulationTypes,
  FloorScreedTypes,

  // SOL
  SolInsulationTypes,
  SolAcousticLayerTypes,
} from "@iko-design-center/shared"
import { ApplicationTypes } from "../../store/application/store"

export enum Models {
  // GENERAL
  DOLLHOUSE = "DOLLHOUSE",

  // FLAT ROOF
  FLAT_ROOF = "FLAT_ROOF",
  ANGLE_CONNECTION = "ANGLE_CONNECTION",
  ANGLE_CONNECTION_OUTSIDE = "ANGLE_CONNECTION_OUTSIDE",
  SETTLEMENT_JOINT = "SETTLEMENT_JOINT",
  ROOF_TERMINAL = "ROOF_TERMINAL",
  ROOF_UPSTAND = "ROOF_UPSTAND",
  ROOF_UPSTAND_HORIZONTAL = "ROOF_UPSTAND_HORIZONTAL",
  ROOF_UPSTAND_VERTICAL = "ROOF_UPSTAND_VERTICAL",
  ROOF_SURFACE = "ROOF_SURFACE",
  DOME = "DOME",
  VERTICAL_WALL_BRICK = "VERTICAL_WALL_BRICK",
  VERTICAL_WALL_CONCRETE = "VERTICAL_WALL_CONCRETE",
  DRAINAGE_PIPE_HORIZONTAL = "DRAINAGE_PIPE_HORIZONTAL",
  DRAINAGE_PIPE_VERTICAL = "DRAINAGE_PIPE_VERTICAL",
  // FLAT ROOF - ATELIA
  FLAT_ROOF_ATELIA = "FLAT_ROOF_ATELIA",
  FLAT_ROOF_ATELIA_ANGLE_CONNECTION = "FLAT_ROOF_ATELIA_ANGLE_CONNECTION",
  FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE = "FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE",
  FLAT_ROOF_ATELIA_SETTLEMENT_JOINT = "FLAT_ROOF_ATELIA_SETTLEMENT_JOINT",
  FLAT_ROOF_ATELIA_ROOF_TERMINAL = "FLAT_ROOF_ATELIA_ROOF_TERMINAL",
  FLAT_ROOF_ATELIA_ROOF_UPSTAND = "FLAT_ROOF_ATELIA_ROOF_UPSTAND",
  FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL = "FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL",
  FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL = "FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL",
  FLAT_ROOF_ATELIA_ROOF_SURFACE = "FLAT_ROOF_ATELIA_ROOF_SURFACE",
  FLAT_ROOF_ATELIA_DOME = "FLAT_ROOF_ATELIA_DOME",
  FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK = "FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK",
  FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE = "FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE",
  FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL = "FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL",
  FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL = "FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL",
  // FLAT ROOF - E CIRCULAIR
  FLAT_ROOF_E_CIRCULAIR = "FLAT_ROOF_E_CIRCULAIR",
  FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION = "FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION",
  FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE = "FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE",
  FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT = "FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT",
  FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL = "FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL",
  FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND = "FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND",
  FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL = "FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL",
  FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL = "FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL",
  FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE = "FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE",
  FLAT_ROOF_E_CIRCULAIR_DOME = "FLAT_ROOF_E_CIRCULAIR_DOME",
  FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK = "FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK",
  FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE = "FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE",
  FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL = "FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL",
  FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL = "FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL",
  // FLAT ROOF - FLAME FREE
  FLAT_ROOF_FLAME_FREE = "FLAT_ROOF_FLAME_FREE",
  FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION = "FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION",
  FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE = "FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE",
  FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT = "FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT",
  FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL = "FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL",
  FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND = "FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND",
  FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL = "FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL",
  FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL = "FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL",
  FLAT_ROOF_FLAME_FREE_ROOF_SURFACE = "FLAT_ROOF_FLAME_FREE_ROOF_SURFACE",
  FLAT_ROOF_FLAME_FREE_DOME = "FLAT_ROOF_FLAME_FREE_DOME",
  FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK = "FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK",
  FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE = "FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE",
  FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL = "FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL",
  FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL = "FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL",
  // FLAT ROOF - RETENTION
  FLAT_ROOF_RETENTION = "FLAT_ROOF_RETENTION",
  FLAT_ROOF_RETENTION_ANGLE_CONNECTION = "FLAT_ROOF_RETENTION_ANGLE_CONNECTION",
  FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE = "FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE",
  FLAT_ROOF_RETENTION_SETTLEMENT_JOINT = "FLAT_ROOF_RETENTION_SETTLEMENT_JOINT",
  FLAT_ROOF_RETENTION_ROOF_TERMINAL = "FLAT_ROOF_RETENTION_ROOF_TERMINAL",
  FLAT_ROOF_RETENTION_ROOF_UPSTAND = "FLAT_ROOF_RETENTION_ROOF_UPSTAND",
  FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL = "FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL",
  FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL = "FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL",
  FLAT_ROOF_RETENTION_ROOF_SURFACE = "FLAT_ROOF_RETENTION_ROOF_SURFACE",
  FLAT_ROOF_RETENTION_DOME = "FLAT_ROOF_RETENTION_DOME",
  FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK = "FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK",
  FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE = "FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE",
  FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL = "FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL",
  FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL = "FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL",

  // CAVITY WALL
  CAVITY_WALL = "CAVITY_WALL",
  CAVITY_WALL_FLAT_ROOF = "CAVITY_WALL_FLAT_ROOF",
  CAVITY_WALL_SLOPED_ROOF = "CAVITY_WALL_SLOPED_ROOF",
  CAVITY_WALL_WINDOW_CONNECTION = "CAVITY_WALL_WINDOW_CONNECTION",
  CAVITY_WALL_FOOTING = "CAVITY_WALL_FOOTING",
  CAVITY_WALL_FACADE = "CAVITY_WALL_FACADE",

  // SLOPED ROOF
  SLOPED_ROOF = "SLOPED_ROOF",
  SLOPED_ROOF_ROOF_SURFACE = "SLOPED_ROOF_ROOF_SURFACE",
  SLOPED_ROOF_VALLEY_GUTTER = "SLOPED_ROOF_VALLEY_GUTTER",
  SLOPED_ROOF_FOOTING_BEAM = "SLOPED_ROOF_FOOTING_BEAM",
  SLOPED_ROOF_CHIMNEY = "SLOPED_ROOF_CHIMNEY",
  SLOPED_ROOF_SKYLIGHT = "SLOPED_ROOF_SKYLIGHT",
  SLOPED_ROOF_RIDGE = "SLOPED_ROOF_RIDGE",

  // VENTILATED WALL
  VENTILATED_WALL = "VENTILATED_WALL",
  VENTILATED_WALL_FACADE = "VENTILATED_WALL_FACADE",
  VENTILATED_WALL_WINDOW_CONNECTION = "VENTILATED_WALL_WINDOW_CONNECTION",
  VENTILATED_WALL_FOOTING = "VENTILATED_WALL_FOOTING",
  VENTILATED_WALL_WALL_CONNECTION = "VENTILATED_WALL_WALL_CONNECTION",

  // FLOOR
  FLOOR = "FLOOR",
  FLOOR_PLANE = "FLOOR_PLANE",
  FLOOR_WALL_CONNECTION = "FLOOR_WALL_CONNECTION",

  // SOL
  SOL_DALLEPORTEE = "SOL_DALLEPORTEE",
  SOL_SOUSCHAPE = "SOL_SOUSCHAPE",
  SOL_SOUSDALLAGE = "SOL_SOUSDALLAGE",
  SOL_PLANE_DALLEPORTEE = "SOL_PLANE_DALLEPORTEE",
  SOL_PLANE_SOUSCHAPE = "SOL_PLANE_SOUSCHAPE",
  SOL_PLANE_SOUSDALLAGE = "SOL_PLANE_SOUSDALLAGE",
  SOL_WALL_CONNECTION_DALLEPORTEE = "SOL_WALL_CONNECTION_DALLEPORTEE",
  SOL_WALL_CONNECTION_SOUSCHAPE = "SOL_WALL_CONNECTION_SOUSCHAPE",
  SOL_WALL_CONNECTION_SOUSDALLAGE = "SOL_WALL_CONNECTION_SOUSDALLAGE",
}

export enum RendererModels {
  // GENERAL
  dollhouse = "dollhouse",

  // FLAT ROOF
  "platdak-overview" = "platdak-overview",
  "platdak-binnenhoek" = "platdak-binnenhoek",
  "platdak-buitenhoek" = "platdak-buitenhoek",
  "platdak-zettingsvoeg" = "platdak-zettingsvoeg",
  "platdak-dakdoorvoer" = "platdak-dakdoorvoer",
  "platdak-dakopstand" = "platdak-dakopstand",
  "platdak-dakopstand-horizontal" = "platdak-dakopstand-horizontal",
  "platdak-dakopstand-vertical" = "platdak-dakopstand-vertical",
  "platdak-dakvlak" = "platdak-dakvlak",
  "platdak-koepel" = "platdak-koepel",
  "platdak-opgaandeMuur-brick" = "platdak-opgaandeMuur-brick",
  "platdak-opgaandeMuur-concrete" = "platdak-opgaandeMuur-concrete",
  "platdak-waterafvoer-horizontal" = "platdak-waterafvoer-horizontal",
  "platdak-waterafvoer-vertical" = "platdak-waterafvoer-vertical",
  // FLAT ROOF - ATELIA
  "platdak-overview-atelia" = "platdak-overview-atelia",
  "platdak-binnenhoek-atelia" = "platdak-binnenhoek-atelia",
  "platdak-buitenhoek-atelia" = "platdak-buitenhoek-atelia",
  "platdak-zettingsvoeg-atelia" = "platdak-zettingsvoeg-atelia",
  "platdak-dakdoorvoer-atelia" = "platdak-dakdoorvoer-atelia",
  "platdak-dakopstand-atelia" = "platdak-dakopstand-atelia",
  "platdak-dakopstand-horizontal-atelia" = "platdak-dakopstand-horizontal-atelia",
  "platdak-dakopstand-vertical-atelia" = "platdak-dakopstand-vertical-atelia",
  "platdak-dakvlak-atelia" = "platdak-dakvlak-atelia",
  "platdak-koepel-atelia" = "platdak-koepel-atelia",
  "platdak-opgaandeMuur-brick-atelia" = "platdak-opgaandeMuur-brick-atelia",
  "platdak-opgaandeMuur-concrete-atelia" = "platdak-opgaandeMuur-concrete-atelia",
  "platdak-waterafvoer-horizontal-atelia" = "platdak-waterafvoer-horizontal-atelia",
  "platdak-waterafvoer-vertical-atelia" = "platdak-waterafvoer-vertical-atelia",
  // FLAT ROOF - E CIRCULAIR
  "platdak-overview_e-circulair" = "platdak-overview_e-circulair",
  "platdak-binnenhoek-e-circulair" = "platdak-binnenhoek-e-circulair",
  "platdak-buitenhoek_e-circulair" = "platdak-buitenhoek_e-circulair",
  "platdak-zettingsvoeg_e-circulair" = "platdak-zettingsvoeg_e-circulair",
  "platdak-dakdoorvoer_e-circulair" = "platdak-dakdoorvoer_e-circulair",
  "platdak-dakopstand_e-circulair" = "platdak-dakopstand_e-circulair",
  "platdak-dakopstand-horizontal_e-circulair" = "platdak-dakopstand-horizontal_e-circulair",
  "platdak-dakopstand-vertical_e-circulair" = "platdak-dakopstand-vertical_e-circulair",
  "platdak-dakvlak_e-circulair" = "platdak-dakvlak_e-circulair",
  "platdak-koepel_e-circulair" = "platdak-koepel_e-circulair",
  "platdak-opgaandeMuur-brick_e-circulair" = "platdak-opgaandeMuur-brick_e-circulair",
  "platdak-opgaandeMuur-concrete_e-circulair" = "platdak-opgaandeMuur-concrete_e-circulair",
  "platdak-waterafvoer-horizontal_e-circulair" = "platdak-waterafvoer-horizontal_e-circulair",
  "platdak-waterafvoer-vertical_e-circulair" = "platdak-waterafvoer-vertical_e-circulair",
  // FLAT ROOF - FLAME FREE
  "platdak-overview_flamefree" = "platdak-overview_flamefree",
  "platdak-binnenhoek_flamefree" = "platdak-binnenhoek_flamefree",
  "platdak-buitenhoek_flamefree" = "platdak-buitenhoek_flamefree",
  "platdak-zettingsvoeg_flamefree" = "platdak-zettingsvoeg_flamefree",
  "platdak-dakdoorvoer_flamefree" = "platdak-dakdoorvoer_flamefree",
  "platdak-dakopstand_flamefree" = "platdak-dakopstand_flamefree",
  "platdak-dakopstand-horizontal_flamefree" = "platdak-dakopstand-horizontal_flamefree",
  "platdak-dakopstand-vertical_flamefree" = "platdak-dakopstand-vertical_flamefree",
  "platdak-dakvlak_flamefree" = "platdak-dakvlak_flamefree",
  "platdak-koepel_flamefree" = "platdak-koepel_flamefree",
  "platdak-opgaandeMuur-brick_flamefree" = "platdak-opgaandeMuur-brick_flamefree",
  "platdak-opgaandeMuur-concrete_flamefree" = "platdak-opgaandeMuur-concrete_flamefree",
  "platdak-waterafvoer-horizontal_flamefree" = "platdak-waterafvoer-horizontal_flamefree",
  "platdak-waterafvoer-vertical_flamefree" = "platdak-waterafvoer-vertical_flamefree",
  // FLAT ROOF - RETENTION
  "platdak-overview_retention" = "platdak-overview_retention",
  "platdak-binnenhoek_retention" = "platdak-binnenhoek_retention",
  "platdak-buitenhoek_retention" = "platdak-buitenhoek_retention",
  "platdak-zettingsvoeg_retention" = "platdak-zettingsvoeg_retention",
  "platdak-dakdoorvoer_retention" = "platdak-dakdoorvoer_retention",
  "platdak-dakopstand_retention" = "platdak-dakopstand_retention",
  "platdak-dakopstand-horizontal_retention" = "platdak-dakopstand-horizontal_retention",
  "platdak-dakopstand-vertical_retention" = "platdak-dakopstand-vertical_retention",
  "platdak-dakvlak_retention" = "platdak-dakvlak_retention",
  "platdak-koepel_retention" = "platdak-koepel_retention",
  "platdak-opgaandeMuur-brick_retention" = "platdak-opgaandeMuur-brick_retention",
  "platdak-opgaandeMuur-concrete_retention" = "platdak-opgaandeMuur-concrete_retention",
  "platdak-waterafvoer-horizontal_retention" = "platdak-waterafvoer-horizontal_retention",
  "platdak-waterafvoer-vertical_retention" = "platdak-waterafvoer-vertical_retention",

  // CAVITY WALL
  "spouwmuur-overview" = "spouwmuur-overview",
  "spouwmuur-plat-dak" = "spouwmuur-plat-dak",
  "spouwmuur-hellend-dak" = "spouwmuur-hellend-dak",
  "spouwmuur-raamaansluiting" = "spouwmuur-raamaansluiting",
  "spouwmuur-muurvoet" = "spouwmuur-muurvoet",
  "spouwmuur-gevelvlak" = "spouwmuur-gevelvlak",

  // SLOPED ROOF
  "hellend-dak-overview" = "hellend-dak-overview",
  "hellend-dak-dakvlak" = "hellend-dak-dakvlak",
  "hellend-dak-kilgoot" = "hellend-dak-kilgoot",
  "hellend-dak-voetbalk" = "hellend-dak-voetbalk",
  "hellend-dak-schouw" = "hellend-dak-schouw",
  "hellend-dak-dakraam" = "hellend-dak-dakraam",
  "hellend-dak-nok" = "hellend-dak-nok",

  // VENTILATED WALL
  "ventgevel-overview" = "ventgevel-overview",
  "ventgevel-gevelvlak" = "ventgevel-gevelvlak",
  "ventgevel-raamaansluiting" = "ventgevel-raamaansluiting",
  "ventgevel-muurvoet" = "ventgevel-muurvoet",
  "ventgevel-muuraansluiting" = "ventgevel-muuraansluiting",

  // FLOOR
  "vloer-overview" = "vloer-overview",
  "vloer-vloervlak" = "vloer-vloervlak",
  "vloer-muuraansluiting" = "vloer-muuraansluiting",

  // SOL
  "vloer_overview_dalleportee_fr" = "vloer_overview_dalleportee_fr",
  "vloer_overview_souschape_fr" = "vloer_overview_souschape_fr",
  "vloer_overview_sousdallage_fr" = "vloer_overview_sousdallage_fr",
  "vloer_vloervlak_dalleportee_fr" = "vloer_vloervlak_dalleportee_fr",
  "vloer_vloervlak_souschape_fr" = "vloer_vloervlak_souschape_fr",
  "vloer_vloervlak_sousdallage_fr" = "vloer_vloervlak_sousdallage_fr",
  "vloer_muuraansluiting_dalleportee_fr" = "vloer_muuraansluiting_dalleportee_fr",
  "vloer_muuraansluiting_souschape_fr" = "vloer_muuraansluiting_souschape_fr",
  "vloer_muuraansluiting_sousdallage_fr" = "vloer_muuraansluiting_sousdallage_fr",
}

type RendererModelsMap = {
  [key in RendererModels]: Models
}

export const rendererModelsMap: RendererModelsMap = {
  // GENERAL
  dollhouse: Models.DOLLHOUSE,

  // FLAT ROOF
  "platdak-overview": Models.FLAT_ROOF,
  "platdak-binnenhoek": Models.ANGLE_CONNECTION,
  "platdak-buitenhoek": Models.ANGLE_CONNECTION_OUTSIDE,
  "platdak-zettingsvoeg": Models.SETTLEMENT_JOINT,
  "platdak-dakdoorvoer": Models.ROOF_TERMINAL,
  "platdak-dakopstand": Models.ROOF_UPSTAND,
  "platdak-dakopstand-horizontal": Models.ROOF_UPSTAND_HORIZONTAL,
  "platdak-dakopstand-vertical": Models.ROOF_UPSTAND_VERTICAL,
  "platdak-dakvlak": Models.ROOF_SURFACE,
  "platdak-koepel": Models.DOME,
  "platdak-opgaandeMuur-brick": Models.VERTICAL_WALL_BRICK,
  "platdak-opgaandeMuur-concrete": Models.VERTICAL_WALL_CONCRETE,
  "platdak-waterafvoer-horizontal": Models.DRAINAGE_PIPE_HORIZONTAL,
  "platdak-waterafvoer-vertical": Models.DRAINAGE_PIPE_VERTICAL,
  // FLAT ROOF - ATELIA
  "platdak-overview-atelia": Models.FLAT_ROOF_ATELIA,
  "platdak-binnenhoek-atelia": Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION,
  "platdak-buitenhoek-atelia": Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE,
  "platdak-zettingsvoeg-atelia": Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT,
  "platdak-dakdoorvoer-atelia": Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL,
  "platdak-dakopstand-atelia": Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND,
  "platdak-dakopstand-horizontal-atelia":
    Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL,
  "platdak-dakopstand-vertical-atelia":
    Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL,
  "platdak-dakvlak-atelia": Models.FLAT_ROOF_ATELIA_ROOF_SURFACE,
  "platdak-koepel-atelia": Models.FLAT_ROOF_ATELIA_DOME,
  "platdak-opgaandeMuur-brick-atelia":
    Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK,
  "platdak-opgaandeMuur-concrete-atelia":
    Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE,
  "platdak-waterafvoer-horizontal-atelia":
    Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL,
  "platdak-waterafvoer-vertical-atelia":
    Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL,
  // FLAT ROOF - E CIRCULAIR
  "platdak-overview_e-circulair": Models.FLAT_ROOF_E_CIRCULAIR,
  "platdak-binnenhoek-e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION,
  "platdak-buitenhoek_e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE,
  "platdak-zettingsvoeg_e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT,
  "platdak-dakdoorvoer_e-circulair": Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL,
  "platdak-dakopstand_e-circulair": Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND,
  "platdak-dakopstand-horizontal_e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL,
  "platdak-dakopstand-vertical_e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL,
  "platdak-dakvlak_e-circulair": Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE,
  "platdak-koepel_e-circulair": Models.FLAT_ROOF_E_CIRCULAIR_DOME,
  "platdak-opgaandeMuur-brick_e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK,
  "platdak-opgaandeMuur-concrete_e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE,
  "platdak-waterafvoer-horizontal_e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL,
  "platdak-waterafvoer-vertical_e-circulair":
    Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL,
  // FLAT ROOF - FLAME FREE
  "platdak-overview_flamefree": Models.FLAT_ROOF_FLAME_FREE,
  "platdak-binnenhoek_flamefree": Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION,
  "platdak-buitenhoek_flamefree":
    Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE,
  "platdak-zettingsvoeg_flamefree":
    Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT,
  "platdak-dakdoorvoer_flamefree": Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL,
  "platdak-dakopstand_flamefree": Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND,
  "platdak-dakopstand-horizontal_flamefree":
    Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL,
  "platdak-dakopstand-vertical_flamefree":
    Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL,
  "platdak-dakvlak_flamefree": Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE,
  "platdak-koepel_flamefree": Models.FLAT_ROOF_FLAME_FREE_DOME,
  "platdak-opgaandeMuur-brick_flamefree":
    Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK,
  "platdak-opgaandeMuur-concrete_flamefree":
    Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE,
  "platdak-waterafvoer-horizontal_flamefree":
    Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL,
  "platdak-waterafvoer-vertical_flamefree":
    Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL,
  // FLAT ROOF - RETENTION
  "platdak-overview_retention": Models.FLAT_ROOF_RETENTION,
  "platdak-binnenhoek_retention": Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION,
  "platdak-buitenhoek_retention":
    Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE,
  "platdak-zettingsvoeg_retention": Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT,
  "platdak-dakdoorvoer_retention": Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL,
  "platdak-dakopstand_retention": Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND,
  "platdak-dakopstand-horizontal_retention":
    Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL,
  "platdak-dakopstand-vertical_retention":
    Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL,
  "platdak-dakvlak_retention": Models.FLAT_ROOF_RETENTION_ROOF_SURFACE,
  "platdak-koepel_retention": Models.FLAT_ROOF_RETENTION_DOME,
  "platdak-opgaandeMuur-brick_retention":
    Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK,
  "platdak-opgaandeMuur-concrete_retention":
    Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE,
  "platdak-waterafvoer-horizontal_retention":
    Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL,
  "platdak-waterafvoer-vertical_retention":
    Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL,

  // CAVITY WALL
  "spouwmuur-overview": Models.CAVITY_WALL,
  "spouwmuur-plat-dak": Models.CAVITY_WALL_FLAT_ROOF,
  "spouwmuur-hellend-dak": Models.CAVITY_WALL_SLOPED_ROOF,
  "spouwmuur-raamaansluiting": Models.CAVITY_WALL_WINDOW_CONNECTION,
  "spouwmuur-muurvoet": Models.CAVITY_WALL_FOOTING,
  "spouwmuur-gevelvlak": Models.CAVITY_WALL_FACADE,

  // SLOPED ROOF
  "hellend-dak-overview": Models.SLOPED_ROOF,
  "hellend-dak-dakvlak": Models.SLOPED_ROOF_ROOF_SURFACE,
  "hellend-dak-kilgoot": Models.SLOPED_ROOF_VALLEY_GUTTER,
  "hellend-dak-voetbalk": Models.SLOPED_ROOF_FOOTING_BEAM,
  "hellend-dak-schouw": Models.SLOPED_ROOF_CHIMNEY,
  "hellend-dak-dakraam": Models.SLOPED_ROOF_SKYLIGHT,
  "hellend-dak-nok": Models.SLOPED_ROOF_RIDGE,

  // VENTILATED WALL
  "ventgevel-overview": Models.VENTILATED_WALL,
  "ventgevel-gevelvlak": Models.VENTILATED_WALL_FACADE,
  "ventgevel-raamaansluiting": Models.VENTILATED_WALL_WINDOW_CONNECTION,
  "ventgevel-muurvoet": Models.VENTILATED_WALL_FOOTING,
  "ventgevel-muuraansluiting": Models.VENTILATED_WALL_WALL_CONNECTION,

  // FLOOR
  "vloer-overview": Models.FLOOR,
  "vloer-vloervlak": Models.FLOOR_PLANE,
  "vloer-muuraansluiting": Models.FLOOR_WALL_CONNECTION,

  // SOL
  vloer_overview_dalleportee_fr: Models.SOL_DALLEPORTEE,
  vloer_overview_souschape_fr: Models.SOL_SOUSCHAPE,
  vloer_overview_sousdallage_fr: Models.SOL_SOUSDALLAGE,
  vloer_vloervlak_dalleportee_fr: Models.SOL_PLANE_DALLEPORTEE,
  vloer_vloervlak_souschape_fr: Models.SOL_PLANE_SOUSCHAPE,
  vloer_vloervlak_sousdallage_fr: Models.SOL_PLANE_SOUSDALLAGE,
  vloer_muuraansluiting_dalleportee_fr: Models.SOL_WALL_CONNECTION_DALLEPORTEE,
  vloer_muuraansluiting_souschape_fr: Models.SOL_WALL_CONNECTION_SOUSCHAPE,
  vloer_muuraansluiting_sousdallage_fr: Models.SOL_WALL_CONNECTION_SOUSDALLAGE,
}

export const initialModel = Models.DOLLHOUSE

export type RendererAPI = {
  // INSTANCE
  instance: any
  // GENERAL
  toggleAllLayers: (
    application: ApplicationTypes | null,
    visible?: boolean
  ) => void
  displayModel: (model: Models) => Promise<any>
  toggleLayers: (layer: any, visible?: string | boolean) => void
  toggleMarkers: () => void
  reset: () => void
  displayMarkers: () => void
  hideMarkers: () => void
  toggleDetails: () => void
  createScreenshot: () => string
  removeInsulationFixation: () => void
  setLayer: (layerType: string, value: string) => void
  toggleLayer: (layer: any, visible?: string | boolean) => void

  // PREVIEW
  loadPreviewModel: (id: string) => Promise<any>
  getPreviewModelMarkerInstances: () => Array<any>
  getPreviewView: () => any
  togglePreviewTransform: (visible?: boolean | null) => void

  // FLAT ROOF
  displayRoofType: (type: RoofTypeTypes | null) => void
  displayRoofFloor: (type: RoofFloorTypes | null) => void
  displayWaterproofing: (type: WaterproofingTypes | null) => void
  displayFlatRoofInsulation: (type: any) => void
  displaySecuringMethod: (
    layerType: string,
    type: SecuringMethodTypes | null
  ) => void
  displayTopLayerMaterial: (id: TopLayerTypes | null) => void
  displayBottomLayerMaterial: (id: BottomLayerTypes | null) => void
  displayInsulationMaterial: (id: InsulationTypes | null) => void
  displayVaporShieldMaterial: (id: VaporShieldTypes | null) => void
  togglePMMALayers: (visible: boolean) => void
  toggleTopLayerProfile: (
    currentModel: Models,
    profileIndex: string,
    visible: boolean
  ) => void

  // CAVITY WALL
  displayWallType: (type: WallTypeTypes) => void
  displayWallInsulation: (type: WallInsulationTypes) => void

  // SLOPED ROOF
  displaySlopedRoofType: (type: SlopedRoofTypeTypes) => void
  displaySlopedRoofInsulation: (type: SlopedRoofInsulationTypeTypes) => void

  // VENTILATED WALL
  displayVentilatedWallWallType: (type: VentilatedWallWallTypeTypes) => void
  displayVentilatedWallFacadeType: (
    type: VentilatedWallFacadeCladdingTypes
  ) => void
  displayVentilatedWallInsulation: (type: VentilatedWallInsulationTypes) => void

  // FLOOR
  displayFloorHeatingType: (type: FloorHeatingTypes) => void
  displayFloorInsulationType: (type: FloorInsulationTypes) => void
  displayFloorScreedType: (type: FloorScreedTypes) => void

  // SOL
  displaySolInsulationType: (type: SolInsulationTypes) => void
  displaySolAcousticLayerType: (type: SolAcousticLayerTypes) => void
}

// TODO: Fill in missing renderer calls
export function createRendererAPI(renderer: any): RendererAPI {
  const toggleAllLayers = (
    application: ApplicationTypes | null,
    visible: boolean = true
  ) => {
    switch (application) {
      // FLAT ROOF
      case "FLAT_ROOF":
        renderer.toggle("DAKBEDEKKING", visible)
        renderer.toggle("ONDERGROND", visible)
        renderer.toggle("DAMPSCHERM", visible)
        renderer.toggle("ISOLATIE-BEVESTIGING", visible)
        renderer.toggle("ONDERLAAG-BEVESTIGING", visible)
        renderer.toggle("ISOLATIE", visible)
        renderer.toggle("TOPLAAG", visible)
        renderer.toggle("ONDERLAAG", visible)
        renderer.toggle("PROFIEL", visible)
        renderer.toggle("KOEPEL", visible)
        renderer.toggle("VENTILATIE", visible)
        break
      // CAVITY WALL
      case "CAVITY_WALL":
        renderer.toggle("BINNENSPOUWBLAD", visible)
        renderer.toggle("ISOLATIE", visible)
        renderer.toggle("GEVELMETSELWERK", visible)
        renderer.toggle("PLAT_DAK", visible)
        renderer.toggle("HELLEND_DAK", visible)
        renderer.toggle("HELLEND_DAK_ISOLATIE", visible)
        renderer.toggle("HELLEND_DAK_DAMPSCHERM", visible)
        renderer.toggle("HELLEND_DAK_CONSTRUCTIE", visible)
        break
      // SLOPED ROOF
      case "SLOPED_ROOF":
        renderer.toggle("DAKBEDEKKING", visible)
        renderer.toggle("ISOLATIE", visible)
        renderer.toggle("ISOLATIE-BEVESTIGING", visible)
        renderer.toggle("ISOLATIE_ONDERDAKFOLIE", visible)
        renderer.toggle("DAKRAAM_GOOTELEMENT", visible)
        renderer.toggle("DAKRAAM_ONDERDAKKRAAG", visible)
        renderer.toggle("DAKRAAM", visible)
        renderer.toggle("SCHOUW_DOORVOERPAN", visible)
        renderer.toggle("SCHOUW_AFVOERKANAAL", visible)
        renderer.toggle("VOETBALK_GOOTELEMENT", visible)
        renderer.toggle("KILGOOT", visible)
        renderer.toggle("KILGOOT_DAKTIMMER", visible)
        renderer.toggle("KILGOOT_GOOTELEMENT", visible)
        renderer.toggle("NOK_PLAAT", visible)
        renderer.toggle("NOK_BALK", visible)
        renderer.toggle("DAMPSCHERM", visible)
        renderer.toggle("DAKCONSTRUCTIE", visible)
        renderer.toggle("DAKDETAILS", visible)
        break
      // VENTILATED WALL
      case "OUTER_WALL":
        renderer.toggle("BINNENSPOUWBLAD", visible)
        renderer.toggle("ISOLATIE", visible)
        renderer.toggle("ISOLATIE-BEVESTIGING", visible)
        renderer.toggle("GEVELBEKLEDING", visible)
        renderer.toggle("ACHTERCONSTRUCTIE", visible)
        renderer.toggle("AANSLUITINGSPROFIEL", visible)
        break
      // FLOOR
      case "FLOOR":
        renderer.toggle("VLOERBEKLEDING", visible)
        renderer.toggle("DEKVLOER", visible)
        renderer.toggle("VLOERVERWARMING", visible)
        renderer.toggle("ISOLATIE_SCHEIDINGSFOLIE", visible)
        renderer.toggle("ISOLATIE", visible)
        renderer.toggle("RANDISOLATIE", visible)
        renderer.toggle("ISOLATIE-BEVESTIGING", visible)
        renderer.toggle("DRAAGVLOER", visible)
        break
      // SOL
      case "SOL":
        renderer.toggle("ISOLATIE", visible)
        renderer.toggle("ONDERGROND", visible)
        renderer.toggle("TOPLAAG", visible)
        renderer.toggle("ONDERLAAG", visible)
        renderer.toggle("BINNENSPOUWBLAD", visible)
        renderer.toggle("DEKVLOER", visible)
        renderer.toggle("DRAAGVLOER", visible)

        renderer.toggle("VLOERVERWARMING", visible)
        renderer.toggle("AKOESTISCHE_LAAG", visible)
        renderer.toggle("RANDISOLATIE", visible)
        break
      default:
        break
    }
  }

  return {
    // INSTANCE
    instance: renderer,

    // GENERAL
    toggleAllLayers: toggleAllLayers,
    displayModel: (model: Models) => {
      switch (model) {
        // GENERAL
        case Models.DOLLHOUSE:
          return renderer.load(RendererModels.dollhouse)

        // FLAT ROOF
        case Models.FLAT_ROOF:
          return renderer.load(RendererModels["platdak-overview"])
        case Models.ANGLE_CONNECTION:
          return renderer.load(RendererModels["platdak-binnenhoek"])
        case Models.ANGLE_CONNECTION_OUTSIDE:
          return renderer.load(RendererModels["platdak-buitenhoek"])
        case Models.SETTLEMENT_JOINT:
          return renderer.load(RendererModels["platdak-zettingsvoeg"])
        case Models.ROOF_TERMINAL:
          return renderer.load(RendererModels["platdak-dakdoorvoer"])
        case Models.ROOF_UPSTAND:
          return renderer.load(RendererModels["platdak-dakopstand"])
        case Models.ROOF_UPSTAND_VERTICAL:
          return renderer.load(RendererModels["platdak-dakopstand-vertical"])
        case Models.ROOF_UPSTAND_HORIZONTAL:
          return renderer.load(RendererModels["platdak-dakopstand-horizontal"])
        case Models.ROOF_SURFACE:
          return renderer.load(RendererModels["platdak-dakvlak"])
        case Models.DOME:
          return renderer.load(RendererModels["platdak-koepel"])
        case Models.VERTICAL_WALL_BRICK:
          return renderer.load(RendererModels["platdak-opgaandeMuur-brick"])
        case Models.VERTICAL_WALL_CONCRETE:
          return renderer.load(RendererModels["platdak-opgaandeMuur-concrete"])
        case Models.DRAINAGE_PIPE_VERTICAL:
          return renderer.load(RendererModels["platdak-waterafvoer-vertical"])
        case Models.DRAINAGE_PIPE_HORIZONTAL:
          return renderer.load(RendererModels["platdak-waterafvoer-horizontal"])
        // FLAT ROOF - ATELIA
        case Models.FLAT_ROOF_ATELIA:
          return renderer.load(RendererModels["platdak-overview-atelia"])
        case Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION:
          return renderer.load(RendererModels["platdak-binnenhoek-atelia"])
        case Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE:
          return renderer.load(RendererModels["platdak-buitenhoek-atelia"])
        case Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT:
          return renderer.load(RendererModels["platdak-zettingsvoeg-atelia"])
        case Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL:
          return renderer.load(RendererModels["platdak-dakdoorvoer-atelia"])
        case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND:
          return renderer.load(RendererModels["platdak-dakopstand-atelia"])
        case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL:
          return renderer.load(
            RendererModels["platdak-dakopstand-vertical-atelia"]
          )
        case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL:
          return renderer.load(
            RendererModels["platdak-dakopstand-horizontal-atelia"]
          )
        case Models.FLAT_ROOF_ATELIA_ROOF_SURFACE:
          return renderer.load(RendererModels["platdak-dakvlak-atelia"])
        case Models.FLAT_ROOF_ATELIA_DOME:
          return renderer.load(RendererModels["platdak-koepel-atelia"])
        case Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK:
          return renderer.load(
            RendererModels["platdak-opgaandeMuur-brick-atelia"]
          )
        case Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE:
          return renderer.load(
            RendererModels["platdak-opgaandeMuur-concrete-atelia"]
          )
        case Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL:
          return renderer.load(
            RendererModels["platdak-waterafvoer-vertical-atelia"]
          )
        case Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL:
          return renderer.load(
            RendererModels["platdak-waterafvoer-horizontal-atelia"]
          )
        // FLAT ROOF - E CIRCULAIR
        case Models.FLAT_ROOF_E_CIRCULAIR:
          return renderer.load(RendererModels["platdak-overview_e-circulair"])
        case Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION:
          return renderer.load(RendererModels["platdak-binnenhoek-e-circulair"])
        case Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE:
          return renderer.load(RendererModels["platdak-buitenhoek_e-circulair"])
        case Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT:
          return renderer.load(
            RendererModels["platdak-zettingsvoeg_e-circulair"]
          )
        case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL:
          return renderer.load(
            RendererModels["platdak-dakdoorvoer_e-circulair"]
          )
        case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND:
          return renderer.load(RendererModels["platdak-dakopstand_e-circulair"])
        case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL:
          return renderer.load(
            RendererModels["platdak-dakopstand-vertical_e-circulair"]
          )
        case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL:
          return renderer.load(
            RendererModels["platdak-dakopstand-horizontal_e-circulair"]
          )
        case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE:
          return renderer.load(RendererModels["platdak-dakvlak_e-circulair"])
        case Models.FLAT_ROOF_E_CIRCULAIR_DOME:
          return renderer.load(RendererModels["platdak-koepel_e-circulair"])
        case Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK:
          return renderer.load(
            RendererModels["platdak-opgaandeMuur-brick_e-circulair"]
          )
        case Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE:
          return renderer.load(
            RendererModels["platdak-opgaandeMuur-concrete_e-circulair"]
          )
        case Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL:
          return renderer.load(
            RendererModels["platdak-waterafvoer-vertical_e-circulair"]
          )
        case Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL:
          return renderer.load(
            RendererModels["platdak-waterafvoer-horizontal_e-circulair"]
          )
        // FLAT ROOF - FLAME FREE
        case Models.FLAT_ROOF_FLAME_FREE:
          return renderer.load(RendererModels["platdak-overview_flamefree"])
        case Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION:
          return renderer.load(RendererModels["platdak-binnenhoek_flamefree"])
        case Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE:
          return renderer.load(RendererModels["platdak-buitenhoek_flamefree"])
        case Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT:
          return renderer.load(RendererModels["platdak-zettingsvoeg_flamefree"])
        case Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL:
          return renderer.load(RendererModels["platdak-dakdoorvoer_flamefree"])
        case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND:
          return renderer.load(RendererModels["platdak-dakopstand_flamefree"])
        case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL:
          return renderer.load(
            RendererModels["platdak-dakopstand-vertical_flamefree"]
          )
        case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL:
          return renderer.load(
            RendererModels["platdak-dakopstand-horizontal_flamefree"]
          )
        case Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE:
          return renderer.load(RendererModels["platdak-dakvlak_flamefree"])
        case Models.FLAT_ROOF_FLAME_FREE_DOME:
          return renderer.load(RendererModels["platdak-koepel_flamefree"])
        case Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK:
          return renderer.load(
            RendererModels["platdak-opgaandeMuur-brick_flamefree"]
          )
        case Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE:
          return renderer.load(
            RendererModels["platdak-opgaandeMuur-concrete_flamefree"]
          )
        case Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL:
          return renderer.load(
            RendererModels["platdak-waterafvoer-vertical_flamefree"]
          )
        case Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL:
          return renderer.load(
            RendererModels["platdak-waterafvoer-horizontal_flamefree"]
          )
        // FLAT ROOF - RETENTION
        case Models.FLAT_ROOF_RETENTION:
          return renderer.load(RendererModels["platdak-overview_retention"])
        case Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION:
          return renderer.load(RendererModels["platdak-binnenhoek_retention"])
        case Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE:
          return renderer.load(RendererModels["platdak-buitenhoek_retention"])
        case Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT:
          return renderer.load(RendererModels["platdak-zettingsvoeg_retention"])
        case Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL:
          return renderer.load(RendererModels["platdak-dakdoorvoer_retention"])
        case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND:
          return renderer.load(RendererModels["platdak-dakopstand_retention"])
        case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL:
          return renderer.load(
            RendererModels["platdak-dakopstand-vertical_retention"]
          )
        case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL:
          return renderer.load(
            RendererModels["platdak-dakopstand-horizontal_retention"]
          )
        case Models.FLAT_ROOF_RETENTION_ROOF_SURFACE:
          return renderer.load(RendererModels["platdak-dakvlak_retention"])
        case Models.FLAT_ROOF_RETENTION_DOME:
          return renderer.load(RendererModels["platdak-koepel_retention"])
        case Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK:
          return renderer.load(
            RendererModels["platdak-opgaandeMuur-brick_retention"]
          )
        case Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE:
          return renderer.load(
            RendererModels["platdak-opgaandeMuur-concrete_retention"]
          )
        case Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL:
          return renderer.load(
            RendererModels["platdak-waterafvoer-vertical_retention"]
          )
        case Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL:
          return renderer.load(
            RendererModels["platdak-waterafvoer-horizontal_retention"]
          )

        // CAVITY WALL
        case Models.CAVITY_WALL:
          return renderer.load(RendererModels["spouwmuur-overview"])
        case Models.CAVITY_WALL_FLAT_ROOF:
          return renderer.load(RendererModels["spouwmuur-plat-dak"])
        case Models.CAVITY_WALL_SLOPED_ROOF:
          return renderer.load(RendererModels["spouwmuur-hellend-dak"])
        case Models.CAVITY_WALL_WINDOW_CONNECTION:
          return renderer.load(RendererModels["spouwmuur-raamaansluiting"])
        case Models.CAVITY_WALL_FOOTING:
          return renderer.load(RendererModels["spouwmuur-muurvoet"])
        case Models.CAVITY_WALL_FACADE:
          return renderer.load(RendererModels["spouwmuur-gevelvlak"])

        // SLOPED ROOF
        case Models.SLOPED_ROOF:
          return renderer.load(RendererModels["hellend-dak-overview"])
        case Models.SLOPED_ROOF_ROOF_SURFACE:
          return renderer.load(RendererModels["hellend-dak-dakvlak"])
        case Models.SLOPED_ROOF_VALLEY_GUTTER:
          return renderer.load(RendererModels["hellend-dak-kilgoot"])
        case Models.SLOPED_ROOF_FOOTING_BEAM:
          return renderer.load(RendererModels["hellend-dak-voetbalk"])
        case Models.SLOPED_ROOF_CHIMNEY:
          return renderer.load(RendererModels["hellend-dak-schouw"])
        case Models.SLOPED_ROOF_SKYLIGHT:
          return renderer.load(RendererModels["hellend-dak-dakraam"])
        case Models.SLOPED_ROOF_RIDGE:
          return renderer.load(RendererModels["hellend-dak-nok"])

        // VENTILATED WALL
        case Models.VENTILATED_WALL:
          return renderer.load(RendererModels["ventgevel-overview"])
        case Models.VENTILATED_WALL_FACADE:
          return renderer.load(RendererModels["ventgevel-gevelvlak"])
        case Models.VENTILATED_WALL_WINDOW_CONNECTION:
          return renderer.load(RendererModels["ventgevel-raamaansluiting"])
        case Models.VENTILATED_WALL_FOOTING:
          return renderer.load(RendererModels["ventgevel-muurvoet"])
        case Models.VENTILATED_WALL_WALL_CONNECTION:
          return renderer.load(RendererModels["ventgevel-muuraansluiting"])

        // FLOOR
        case Models.FLOOR:
          return renderer.load(RendererModels["vloer-overview"])
        case Models.FLOOR_PLANE:
          return renderer.load(RendererModels["vloer-vloervlak"])
        case Models.FLOOR_WALL_CONNECTION:
          return renderer.load(RendererModels["vloer-muuraansluiting"])

        // SOL
        case Models.SOL_DALLEPORTEE:
          return renderer.load(RendererModels["vloer_overview_dalleportee_fr"])
        case Models.SOL_SOUSCHAPE:
          return renderer.load(RendererModels["vloer_overview_souschape_fr"])
        case Models.SOL_SOUSDALLAGE:
          return renderer.load(RendererModels["vloer_overview_sousdallage_fr"])
        case Models.SOL_PLANE_DALLEPORTEE:
          return renderer.load(RendererModels["vloer_vloervlak_dalleportee_fr"])
        case Models.SOL_PLANE_SOUSCHAPE:
          return renderer.load(RendererModels["vloer_vloervlak_souschape_fr"])
        case Models.SOL_PLANE_SOUSDALLAGE:
          return renderer.load(RendererModels["vloer_vloervlak_sousdallage_fr"])
        case Models.SOL_WALL_CONNECTION_DALLEPORTEE:
          return renderer.load(
            RendererModels["vloer_muuraansluiting_dalleportee_fr"]
          )
        case Models.SOL_WALL_CONNECTION_SOUSCHAPE:
          return renderer.load(
            RendererModels["vloer_muuraansluiting_souschape_fr"]
          )
        case Models.SOL_WALL_CONNECTION_SOUSDALLAGE:
          return renderer.load(
            RendererModels["vloer_muuraansluiting_sousdallage_fr"]
          )
      }
    },
    toggleLayers: function (layer: any, visible = "auto"): void {
      switch (layer) {
        // FLAT ROOF
        case "TOPLAAG":
          renderer.toggle("TOPLAAG", visible)
          break
        case "TOPLAAG-BEVESTIGING":
          renderer.toggle("TOPLAAG-BEVESTIGING", visible)
          break
        case "ONDERLAAG":
          renderer.toggle("ONDERLAAG", visible)
          break
        case "ONDERLAAG-BEVESTIGING":
          renderer.toggle("ONDERLAAG-BEVESTIGING", visible)
          break
        case "DAMPSCHERM":
          renderer.toggle("DAMPSCHERM", visible)
          break
        case "DAMPSCHERM-BEVESTIGING":
          renderer.toggle("DAMPSCHERM-BEVESTIGING", visible)
          break
        case "PROFIEL":
          renderer.toggle("PROFIEL", visible)
          break
        case "KOEPEL":
          renderer.toggle("KOEPEL", visible)
          break
        case "VENTILATIE":
          renderer.toggle("VENTILATIE", visible)
          break
        case "ONDERGROND":
          renderer.toggle("ONDERGROND", visible)
          break
        case "MUUR":
          renderer.toggle("MUUR", visible)
          break
        case "AFVOER":
          renderer.toggle("AFVOER", visible)
          break
        case "ZETTINGSVOEG":
          renderer.toggle("ZETTINGSVOEG", visible)
          break

        // CAVITY WALL
        case "BINNENSPOUWBLAD":
          renderer.toggle("BINNENSPOUWBLAD", visible)
          break
        case "ISOLATIE":
          renderer.toggle("ISOLATIE", visible)
          renderer.toggle("ISOLATIE-BEVESTIGING", visible)
          break
        case "GEVELMETSELWERK":
          renderer.toggle("GEVELMETSELWERK", visible)
          break
        case "PLAT_DAK":
          renderer.toggle("DAKSYSTEEM", visible)
          break
        case "HELLEND_DAK":
          renderer.toggle("HELLEND_DAK", visible)
          break
        case "HELLEND_DAK_ISOLATIE":
          renderer.toggle("HELLEND_DAK_ISOLATIE", visible)
          break
        case "HELLEND_DAK_DAMPSCHERM":
          renderer.toggle("HELLEND_DAK_DAMPSCHERM", visible)
          break
        case "HELLEND_DAK_CONSTRUCTIE":
          renderer.toggle("HELLEND_DAK_CONSTRUCTIE", visible)
          break

        // SLOPED ROOF
        case "DAKBEDEKKING":
          renderer.toggle("DAKBEDEKKING", visible)
          break
        case "ISOLATIE_ONDERDAKFOLIE":
          renderer.toggle("ISOLATIE_ONDERDAKFOLIE", visible)
          break
        case "DAKRAAM_GOOTELEMENT":
          renderer.toggle("DAKRAAM_GOOTELEMENT", visible)
          break
        case "DAKRAAM_ONDERDAKKRAAG":
          renderer.toggle("DAKRAAM_ONDERDAKKRAAG", visible)
          break
        case "DAKRAAM":
          renderer.toggle("DAKRAAM", visible)
          break
        case "SCHOUW_DOORVOERPAN":
          renderer.toggle("SCHOUW_DOORVOERPAN", visible)
          break
        case "SCHOUW_AFVOERKANAAL":
          renderer.toggle("SCHOUW_AFVOERKANAAL", visible)
          break
        case "VOETBALK_GOOTELEMENT":
          renderer.toggle("VOETBALK_GOOTELEMENT", visible)
          break
        case "KILGOOT":
          renderer.toggle("KILGOOT", visible)
          break
        case "KILGOOT_DAKTIMMER":
          renderer.toggle("KILGOOT_DAKTIMMER", visible)
          break
        case "KILGOOT_GOOTELEMENT":
          renderer.toggle("KILGOOT_GOOTELEMENT", visible)
          break
        case "NOK_PLAAT":
          renderer.toggle("NOK_PLAAT", visible)
          break
        case "NOK_BALK":
          renderer.toggle("NOK_BALK", visible)
          break
        case "DAKCONSTRUCTIE":
          renderer.toggle("DAKCONSTRUCTIE", visible)
          break
        case "DAKDETAILS":
          renderer.toggle("DAKDETAILS", visible)
          break

        // VENTILATED WALL
        // case "BINNENSPOUWBLAD":
        //   renderer.toggle("BINNENSPOUWBLAD", visible);
        //   break;
        case "GEVELBEKLEDING":
          renderer.toggle("GEVELBEKLEDING", visible)
          break
        case "AANSLUITINGSPROFIEL":
          renderer.toggle("AANSLUITINGSPROFIEL", visible)
          break
        case "ACHTERCONSTRUCTIE":
          renderer.toggle("ACHTERCONSTRUCTIE", visible)
          break

        // FLOOR
        case "VLOERBEKLEDING":
          renderer.toggle("VLOERBEKLEDING", visible)
          break
        case "DEKVLOER":
          renderer.toggle("DEKVLOER", visible)
          break
        case "VLOERVERWARMING":
          renderer.toggle("VLOERVERWARMING", visible)
          break
        case "ISOLATIE_SCHEIDINGSFOLIE":
          renderer.toggle("ISOLATIE_SCHEIDINGSFOLIE", visible)
          break
        // case "ISOLATIE":
        //   renderer.toggle("ISOLATIE", visible);
        //   break;
        case "RANDISOLATIE":
          renderer.toggle("RANDISOLATIE", visible)
          break
        case "DRAAGVLOER":
          renderer.toggle("DRAAGVLOER", visible)
          break

        // SOL
        case "AKOESTISCHE_LAAG":
          renderer.toggle("AKOESTISCHE_LAAG", visible)
          break
      }

      document.dispatchEvent(new CustomEvent("layerChange"))
    },
    toggleDetails: function (): void {
      //TODO: Hide details
      renderer.markers.toggle()
    },
    toggleMarkers: function (): void {
      renderer.markers.toggle()
    },
    reset: function (): void {
      renderer.reset()
    },
    displayMarkers: function (): void {
      renderer.markers.toggle(true)
    },
    hideMarkers: function (): void {
      renderer.markers.toggle(false)
    },
    createScreenshot: function (): string {
      return renderer.createScreenshot()
    },
    removeInsulationFixation: function (): void {
      renderer.setLayer("ISOLATIE-BEVESTIGING", "")
    },
    setLayer: function (layerType, value): void {
      renderer.setLayer(layerType, value)
    },
    toggleLayer: function (layerType, visible): void {
      renderer.toggle(layerType, visible)
    },

    // PREVIEW
    loadPreviewModel: function (id: string) {
      return renderer.load(id)
    },
    getPreviewModelMarkerInstances: function () {
      return renderer.markers.instances
    },
    getPreviewView: function () {
      return [renderer.camera, renderer.model]
    },
    togglePreviewTransform: function (visible = null) {
      if (renderer.controls.transform) {
        if (visible) renderer.controls.transform.visible = visible
        else
          renderer.controls.transform.visible = !renderer.controls.transform
            .visible
      }
    },

    // FLAT ROOF
    displayRoofType: function (type: RoofTypeTypes | null) {
      switch (type) {
        case RoofTypeTypes.STANDARD_ROOF:
          renderer.setLayer("DAKBEDEKKING", "")
          break
        case RoofTypeTypes.RETENTION_ROOF:
          renderer.setLayer("DAKBEDEKKING", "CASSETTES")
          break
        case RoofTypeTypes.GREEN_ROOF:
          renderer.setLayer("DAKBEDEKKING", "GRAS")
          break
        case RoofTypeTypes.LIVING_ROOF:
          renderer.setLayer("DAKBEDEKKING", "LEEFDAK")
          break
        case RoofTypeTypes.ENERGY_ROOF:
          renderer.setLayer("DAKBEDEKKING", "ZONNEPANELEN")
          break
      }
    },
    displayRoofFloor: function (type: RoofFloorTypes | null): void {
      switch (type) {
        case RoofFloorTypes.WOOD:
          renderer.setLayer("ONDERGROND", "HOUT")
          break
        case RoofFloorTypes.CONCRETE:
          renderer.setLayer("ONDERGROND", "BETON")
          break
        case RoofFloorTypes.STEEL_DECK:
          renderer.setLayer("ONDERGROND", "STEELDECK")
          break
      }
    },
    displayWaterproofing: function (type: WaterproofingTypes | null): void {
      switch (type) {
        // Double Bituminous
        case WaterproofingTypes.TWO_BITUMINOUS:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_FLAME_FREE:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR_FLAME_FREE:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case WaterproofingTypes.TWO_BITUMINOUS_CIRCULAIR:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case WaterproofingTypes.TWO_BITUMINOUS_FLAME_FREE:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        // Single Bituminous
        case WaterproofingTypes.ONE_BITUMINOUS_AIR_PURIFYING_CIRCULAIR:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case WaterproofingTypes.ONE_BITUMINOUS_FLAME_FREE:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        // Liquid
        case WaterproofingTypes.LIQUID:
          renderer.setLayer("TOPLAAG", "PMMA")
          break
      }
    },
    displayFlatRoofInsulation: (type: any) => {
      renderer.setLayer("ISOLATIE", type)
    },
    displaySecuringMethod: (
      layerType: string,
      type: SecuringMethodTypes | null
    ) => {
      renderer.setLayer(layerType, "NONE")
      renderer.setLayer(layerType, "")
      if (!layerType) return
      switch (type) {
        case SecuringMethodTypes.WELDING:
          renderer.setLayer(layerType, "")
          break
        case SecuringMethodTypes.LIQUID:
          renderer.setLayer(layerType, "")
          break
        case SecuringMethodTypes.SELF_ADHERING:
          renderer.setLayer(layerType, "LIJM")
          break
        case SecuringMethodTypes.MECHANICAL:
          renderer.setLayer(layerType, "SCHROEF")
          // renderer.setLayer(layerType, "SCHROEF-ATELIA")
          break
        case SecuringMethodTypes.MECHANICAL_BALLAST:
          renderer.setLayer(layerType, "SCHROEF-BALLAST")
          break
        case SecuringMethodTypes.LOOSE:
          renderer.setLayer(layerType, "NONE")
          break
        case SecuringMethodTypes.GLUE:
          renderer.setLayer(layerType, "LIJM")
          break
        case SecuringMethodTypes.LOOSE_BALLAST:
          renderer.setLayer(layerType, "")
          break
        case SecuringMethodTypes.WARM_BITUMEN:
          renderer.setLayer(layerType, "WARM_BITUMEN")
          break
      }
    },
    displayTopLayerMaterial: (id: TopLayerTypes | null) => {
      renderer.setLayer("TOPLAAG", "")
      switch (id) {
        // BE
        case TopLayerTypes.CARRARA:
          renderer.setLayer("TOPLAAG", "CARRARA")
          break
        case TopLayerTypes.CARBON_250:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.CARBON:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.PANTERA:
          renderer.setLayer("TOPLAAG", "CARBON-PANTERA")
          break
        case TopLayerTypes.IKO_ROOFGARDEN_PANTERA:
          renderer.setLayer("TOPLAAG", "CARBON-PANTERA")
          break
        case TopLayerTypes.IKO_ROOFGARDEN_4_APP_ADF:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.POWERGUM_470K14_ATELIA:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.POWERGUM_4AWF_ATELIA:
          renderer.setLayer("TOPLAAG", "CARRARA")
          break
        case TopLayerTypes.CARRARA_TECNO_SN:
          renderer.setLayer("TOPLAAG", "CARRARA")
          break
        case TopLayerTypes.SUMMA:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.POWERGUM_4_ADF:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.POWERFLEX_4_ADF:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.SECURA:
          renderer.setLayer("TOPLAAG", "CARRARA")
          break
        case TopLayerTypes.TANETECH:
          renderer.setLayer("TOPLAAG", "PMMA")
          break
        case TopLayerTypes.METATECH:
          renderer.setLayer("TOPLAAG", "PMMA")
          break
        case TopLayerTypes.HYBRITECH:
          renderer.setLayer("TOPLAAG", "PMMA")
          break
        // NL
        case TopLayerTypes.CARBON_HI_SPEED:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.IKO_ROOFGARDEN_470K14:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.POWERGUM_470K14:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.POWERGUM_470K24_DGRIJS:
          renderer.setLayer("TOPLAAG", "CARBON")
          break
        case TopLayerTypes.POWERGUM_470K24_WIT:
          renderer.setLayer("TOPLAAG", "CARRARA")
          break
      }
    },
    displayBottomLayerMaterial: (id: BottomLayerTypes | null) => {
      renderer.setLayer("ONDERLAAG", "")
      switch (id) {
        // BE
        case BottomLayerTypes.QUADRA_FSA:
          renderer.setLayer("ONDERLAAG", "QUADRA-FSA")
          break
        case BottomLayerTypes.BASE_P3_TF:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
        case BottomLayerTypes.BASE_STICK_TSA:
          renderer.setLayer("ONDERLAAG", "BASE-STICK-TSA")
          break
        case BottomLayerTypes.BASE_V3_TT:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
        case BottomLayerTypes.BASE_P3_SBS_TF_ATELIA:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
        case BottomLayerTypes.BASE_P3_TF_ATELIA:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
        // NL
        case BottomLayerTypes.BASE_460P60:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
        case BottomLayerTypes.BASE_360P60:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
        case BottomLayerTypes.BASE_460P60_ATELIA:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
        case BottomLayerTypes.BASE_360P60_ATELIA:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
        case BottomLayerTypes.BASE_P14:
          renderer.setLayer("ONDERLAAG", "BASE-P3-FT")
          break
      }
    },
    displayInsulationMaterial: (id: InsulationTypes | null) => {
      renderer.setLayer("ISOLATIE", "")
      switch (id) {
        // BE & NL
        case InsulationTypes.ENERTHERM_ATELIA:
          renderer.setLayer("ISOLATIE", "ISOLATIE-ATELIA")
          break
        case InsulationTypes.ENERTHERM_ALU:
          renderer.setLayer("ISOLATIE", "ISOLATIE-ALU")
          break
        case InsulationTypes.ENERTHERM_MG:
          renderer.setLayer("ISOLATIE", "ISOLATIE-MG")
          break
      }
    },
    displayVaporShieldMaterial: (id: VaporShieldTypes | null) => {
      renderer.setLayer("DAMPSCHERM", "")
      switch (id) {
        // BE
        case VaporShieldTypes.BASE_P3_TF:
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        case VaporShieldTypes.BASE_V3_TF:
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        case VaporShieldTypes.BASE_STICK_TSA:
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-STICK-TSA")
          break
        case VaporShieldTypes.P3_ATELIA:
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        case VaporShieldTypes.P3_SBS_ATELIA:
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        case VaporShieldTypes.SHIELD_ALU3_TF:
          // renderer.setLayer("DAMPSCHERM", "DAMP-SHIELD-ALU3")
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        case VaporShieldTypes.SHIELD_COMPACT_ALU3:
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        case VaporShieldTypes.SHIELD_PRO_ALU_SA:
          // renderer.setLayer("DAMPSCHERM", "DAMP-SHIELD-PRO-ALU")
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        // NL
        case VaporShieldTypes.BASE_P14:
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        case VaporShieldTypes.SHIELD_ALU4_TF:
          // renderer.setLayer("DAMPSCHERM", "DAMP-SHIELD-ALU3")
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
        case VaporShieldTypes.COMPACT_ALU3:
          renderer.setLayer("DAMPSCHERM", "DAMP-BASE-P3-TF")
          break
      }
    },
    togglePMMALayers(visible: boolean) {
      renderer.setLayerForProp("HIDE_ON_PMMA", "true", visible)
    },
    toggleTopLayerProfile(
      currentModel: Models,
      profileIndex: string,
      visible: boolean
    ) {
      switch (currentModel) {
        case Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION:
        case Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL:
        case Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION:
        case Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL:
        case Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION:
        case Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL:
          renderer.toggle(`PROFIEL_${profileIndex}`, visible)
          break
        default:
          if (profileIndex === "1") renderer.toggle("PROFIEL", visible)
          break
      }
    },

    // CAVITY WALL
    displayWallType: function (type: WallTypeTypes) {
      renderer.setLayer("BINNENSPOUWBLAD", "")
      switch (type) {
        case WallTypeTypes.CONCRETE_WALL:
          renderer.setLayer("BINNENSPOUWBLAD", "BETON")
          break
        case WallTypeTypes.LIME_SAND_WALL:
          renderer.setLayer("BINNENSPOUWBLAD", "KALKZANDSTEEN")
          break
        case WallTypeTypes.HOLLOW_STONE_WALL:
          renderer.setLayer("BINNENSPOUWBLAD", "HOLLE_STEEN")
          break
        case WallTypeTypes.CROSSWOOD_WALL:
          renderer.setLayer("BINNENSPOUWBLAD", "KRUISHOUTLAAG")
          break
      }
      renderer.toggle("BINNENSPOUWBLAD", true)
    },
    displayWallInsulation: function (type: WallInsulationTypes) {
      renderer.setLayer("ISOLATIE-BEVESTIGING", "SCHROEF")
      switch (type) {
        case WallInsulationTypes.ALU_50:
          renderer.setLayer("ISOLATIE", "ISOLATIE-ALU50")
          break
        case WallInsulationTypes.ALU_TG:
          renderer.setLayer("ISOLATIE", "ISOLATIE-ALUTG")
          break
      }
    },

    // SLOPED ROOF
    displaySlopedRoofType: function (type: SlopedRoofTypeTypes) {
      renderer.setLayer("NOK_PLAAT", "")
      renderer.setLayer("NOK_BALK", "")
      renderer.setLayer("DAKBEDEKKING", "")
      renderer.setLayer("SCHOUW_DOORVOERPAN", "")
      renderer.setLayer("DAKRAAM_GOOTELEMENT", "")
      renderer.setLayer("KILGOOT_DAKTIMMER", "")
      renderer.setLayer("KILGOOT_GOOTELEMENT", "")
      switch (type) {
        case SlopedRoofTypeTypes.TILED_ROOF:
          renderer.setLayer("NOK_PLAAT", "PANNENDAK")
          renderer.setLayer("NOK_BALK", "PANNENDAK")
          renderer.setLayer("DAKBEDEKKING", "PANNENDAK")
          renderer.setLayer("SCHOUW_DOORVOERPAN", "PANNENDAK")
          renderer.setLayer("DAKRAAM_GOOTELEMENT", "PANNENDAK")
          renderer.setLayer("KILGOOT_DAKTIMMER", "PANNENDAK")
          renderer.setLayer("KILGOOT_GOOTELEMENT", "PANNENDAK")
          break
        case SlopedRoofTypeTypes.SLATED_ROOF:
          renderer.setLayer("NOK_PLAAT", "LEIENDAK")
          renderer.setLayer("NOK_BALK", "LEIENDAK")
          renderer.setLayer("DAKBEDEKKING", "LEIENDAK")
          renderer.setLayer("SCHOUW_DOORVOERPAN", "LEIENDAK")
          renderer.setLayer("DAKRAAM_GOOTELEMENT", "LEIENDAK")
          renderer.setLayer("KILGOOT_DAKTIMMER", "LEIENDAK")
          renderer.setLayer("KILGOOT_GOOTELEMENT", "LEIENDAK")
          break
      }
    },
    displaySlopedRoofInsulation: function (
      type: SlopedRoofInsulationTypeTypes
    ) {
      renderer.setLayer("ISOLATIE_ONDERDAKFOLIE", "")
      renderer.setLayer("ISOLATIE-BEVESTIGING", "SCHROEF")
      switch (type) {
        case SlopedRoofInsulationTypeTypes.ALU_NF:
          renderer.setLayer("ISOLATIE", "ISOLATIE-ALUNF")
          renderer.setLayer("ISOLATIE_ONDERDAKFOLIE", "ISOLATIE-ALUNF")
          break
        case SlopedRoofInsulationTypeTypes.ALU_NF_PRO:
          renderer.setLayer("ISOLATIE", "ISOLATIE-ALUNFPRO")
          renderer.setLayer("ISOLATIE_ONDERDAKFOLIE", "TEST")
          break
      }
    },

    // VENTILATED WALL
    displayVentilatedWallWallType: function (
      type: VentilatedWallWallTypeTypes
    ) {
      renderer.setLayer("BINNENSPOUWBLAD", "")
      switch (type) {
        case VentilatedWallWallTypeTypes.CONCRETE_WALL:
          renderer.setLayer("BINNENSPOUWBLAD", "BETON")
          break
        case VentilatedWallWallTypeTypes.LIME_SAND_WALL:
          renderer.setLayer("BINNENSPOUWBLAD", "KALKZANDSTEEN")
          break
        case VentilatedWallWallTypeTypes.HOLLOW_STONE_WALL:
          renderer.setLayer("BINNENSPOUWBLAD", "HOLLE_STEEN")
          break
        case VentilatedWallWallTypeTypes.CROSSWOOD_WALL:
          renderer.setLayer("BINNENSPOUWBLAD", "KRUISHOUTLAAG")
          break
      }
      renderer.toggle("BINNENSPOUWBLAD", true)
    },
    displayVentilatedWallFacadeType: function (
      type: VentilatedWallFacadeCladdingTypes
    ) {
      renderer.setLayer("GEVELBEKLEDING", "")
      renderer.setLayer("AANSLUITINGSPROFIEL", "")
      switch (type) {
        case VentilatedWallFacadeCladdingTypes.ETERNIT_CEDRAL:
          renderer.setLayer("GEVELBEKLEDING", "CEDRAL")
          renderer.setLayer("AANSLUITINGSPROFIEL", "CEDRAL")
          break
        case VentilatedWallFacadeCladdingTypes.ETERNIT_EQUITONE:
          renderer.setLayer("GEVELBEKLEDING", "EQUITONE")
          renderer.setLayer("AANSLUITINGSPROFIEL", "EQUITONE")
          break
      }
      renderer.toggle("GEVELBEKLEDING", true)
      renderer.toggle("AANSLUITINGSPROFIEL", true)
    },
    displayVentilatedWallInsulation: function (
      type: VentilatedWallInsulationTypes
    ) {
      renderer.setLayer("ISOLATIE-BEVESTIGING", "SCHROEF")
      switch (type) {
        case VentilatedWallInsulationTypes.ENERTHERM_ALU_PURE:
          renderer.setLayer("ISOLATIE", "ISOLATIE_ALUPURE")
          break
      }
    },

    // FLOOR
    displayFloorHeatingType: function (type: FloorHeatingTypes) {
      renderer.setLayer("VLOERVERWARMING", "")
      switch (type) {
        case FloorHeatingTypes.FLOOR_HEATING:
          renderer.setLayer("VLOERVERWARMING", "MET_VLOERVERWARMING")
          renderer.toggle("VLOERVERWARMING", true)
          break
        case FloorHeatingTypes.NO_FLOOR_HEATING:
          renderer.setLayer("VLOERVERWARMING", "GEEN_VLOERVERWARMING")
          renderer.toggle("VLOERVERWARMING", false)
          break
      }
    },
    displayFloorInsulationType: function (type: FloorInsulationTypes) {
      renderer.setLayer("ISOLATIE", "")
      renderer.setLayer("ISOLATIE_SCHEIDINGSFOLIE", "")
      renderer.setLayer("ISOLATIE-BEVESTIGING", "")
      switch (type) {
        case FloorInsulationTypes.ENERTHERM_ALU:
          renderer.setLayer("ISOLATIE", "ISOLATIE_ALU")
          renderer.setLayer("ISOLATIE_SCHEIDINGSFOLIE", "ISOLATIE_ALU")
          break
        case FloorInsulationTypes.ENERTHERM_KR_ALU:
          renderer.setLayer("ISOLATIE", "ISOLATIE_KRALU")
          renderer.setLayer("ISOLATIE_SCHEIDINGSFOLIE", "TEST")
          break
      }
    },
    displayFloorScreedType: function (type: FloorScreedTypes) {
      renderer.setLayer("DEKVLOER", "")
      switch (type) {
        case FloorScreedTypes.ANHYDRITE_FLOOR:
          renderer.setLayer("DEKVLOER", "ANHYDRIET")
          break
        case FloorScreedTypes.CHAPE_FLOOR:
          renderer.setLayer("DEKVLOER", "CHAPE")
          break
      }
      renderer.toggle("DEKVLOER", true)
    },

    // SOL
    displaySolInsulationType: function (type: SolInsulationTypes) {
      renderer.setLayer("ISOLATIE", "")
      switch (type) {
        case SolInsulationTypes.ENERTHERM_CHAPE_TG:
          renderer.setLayer("ISOLATIE", "ISOLATIE_CHAPETG")
          renderer.setLayer("ONDERGROND", "BETON")
          renderer.setLayer("DEKVLOER", "CHAPE")
          renderer.load(RendererModels["vloer_overview_souschape_fr"])
          break
        case SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE:
          renderer.setLayer("ISOLATIE", "ISOLATIE_KRALU")
          renderer.setLayer("DEKVLOER", "GEWAPEND")
          renderer.setLayer("ONDERGROND", "ONDERGROND")
          renderer.load(RendererModels["vloer_overview_sousdallage_fr"])
          break
        case SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE:
          renderer.setLayer("ISOLATIE", "ISOLATIE_KRALU")
          renderer.setLayer("DEKVLOER", "GEWAPEND")
          renderer.setLayer("ONDERGROND", "ONDERGROND")
          renderer.load(RendererModels["vloer_overview_dalleportee_fr"])
          break
      }
    },
    displaySolAcousticLayerType: function (type: SolAcousticLayerTypes) {
      renderer.setLayer("AKOESTISCHE_LAAG", "TEST")
      switch (type) {
        case SolAcousticLayerTypes.TRAMICHAPE_ECO_PRO:
          renderer.setLayer("AKOESTISCHE_LAAG", "TRAMICHAPE")
          renderer.toggle("AKOESTISCHE_LAAG", true)
          break
        case SolAcousticLayerTypes.ASSOURCHAPE_20:
          renderer.setLayer("AKOESTISCHE_LAAG", "ASSOURCHAPE")
          renderer.toggle("AKOESTISCHE_LAAG", true)
          break
        case SolAcousticLayerTypes.AUCUNE:
          renderer.setLayer("AKOESTISCHE_LAAG", "")
          renderer.toggle("AKOESTISCHE_LAAG", false)
          break
      }
    },
  }
}

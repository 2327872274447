import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { useRoutes } from "../../../store/routes/hooks"
import { getSecuringMethodTranslation } from "./securingMethodTranslations"
import { useSecuringMethodsVaporShield } from "./securingMethodsVaporShield.hooks"
import { useSecuringMethodsInsulation } from "./securingMethodsInsulation.hooks"
import { useSecuringMethodsBottomLayer } from "./securingMethodsBottomLayer.hooks"
import { useSecuringMethodsTopLayer } from "./securingMethodsTopLayer.hooks"
import { useSecuringMethodsFinishing } from "./securingMethodsFinishing.hooks"
import { useCallback, useEffect, useState } from "react"
import { parseMaterial } from "../../../services/helpers.service"
import {
  RoofFloorTypes,
  RoofTypeTypes,
  SecuringMethodTypes,
} from "@iko-design-center/shared"
import { useMatrix } from "./matrix.hook"
import { renderer } from "../../viewer/Renderer"

export function useSecuringMethods() {
  const { getRemainingCombinations } = useMatrix()
  const [lastUpdatedOption, setLastUpdatedOption] = useState<string | null>()
  const [updatedOptions, setUpdatedOptions] = useState<any>([])
  const [displayResetButton] = useState<boolean>(false)
  const [combinationValid, setCombinationValid] = useState<boolean>(false)
  const [remainingCombinations, setRemainingCombinations] = useState<any>([])
  const {
    configurationState,
    resetConfigurationToSecuringMethods,
    setRoofFloorMaterial,
    setTopLayerMaterial,
    setBottomLayerMaterial,
    setInsulationMaterial,
    setVaporShieldMaterial,
  } = useFlatRoof()
  const { Routes } = useRoutes() as any
  const { t } = useTranslation()
  const history = useHistory()
  const nextButtonDisabled = !combinationValid
  const {
    roofType: stateRoofType,
    roofFloor: stateRoofFloor,
    waterproofing: stateWaterproofing,
  } = configurationState

  useEffect(() => {
    // MATRIX FILTERING
    if (!remainingCombinations.length) {
      const remainingCombinations = getRemainingCombinations(
        stateRoofType.type,
        stateRoofType.climateClass,
        stateRoofFloor.type,
        stateWaterproofing.type
      )
      if (remainingCombinations.length) {
        setRemainingCombinations(remainingCombinations)
      } else {
        history.push(Routes.HOME as any)
      }
    }
  }, [
    getRemainingCombinations,
    stateRoofType,
    stateRoofFloor,
    stateWaterproofing,
    remainingCombinations,
    history,
    Routes,
  ])

  // Vapor shield
  const {
    stateVaporShield,
    vaporShieldOptions,
    setVaporShieldType: setStateVaporShieldType,
  } = useSecuringMethodsVaporShield()
  const [vaporShield, vaporShieldDropdownOptions] = parseMethod(
    stateVaporShield,
    vaporShieldOptions
  )

  // Insulation
  const {
    stateInsulation,
    insulationOptions,
    setInsulationType: setStateInsulationType,
  } = useSecuringMethodsInsulation()
  const [insulation, insulationDropdownOptions] = parseMethod(
    stateInsulation,
    insulationOptions
  )

  // Bottom layer
  const {
    stateBottomLayer,
    bottomLayerOptions,
    setBottomLayerType: setStateBottomLayerType,
  } = useSecuringMethodsBottomLayer()
  const [bottomLayer, bottomLayerDropdownOptions] = parseMethod(
    stateBottomLayer,
    bottomLayerOptions
  )

  // Top layer
  const {
    stateTopLayer,
    topLayerOptions,
    setTopLayerType: setStateTopLayerType,
  } = useSecuringMethodsTopLayer()
  const [topLayer, topLayerDropdownOptions] = parseMethod(
    stateTopLayer,
    topLayerOptions
  )

  // Finishing
  const {
    stateFinishing,
    setFinishingType: setStateFinishingType,
  } = useSecuringMethodsFinishing()

  useEffect(() => {
    if (updatedOptions.length > 0) {
      setTimeout(() => {
        setUpdatedOptions([])
      }, 300)
    }
  }, [updatedOptions])

  // useEffect(() => {
  //   setDisplayResetButton(
  //     vaporShieldOptions.length > 1 ||
  //       insulationOptions.length > 1 ||
  //       bottomLayerOptions.length > 1 ||
  //       topLayerOptions.length > 1
  //   )
  // }, [
  //   vaporShieldOptions,
  //   insulationOptions,
  //   bottomLayerOptions,
  //   topLayerOptions,
  // ])

  function handleNextButtonClick() {
    // Update thermal layers
    const vaporShieldMaterial = parseMaterial(stateVaporShield?.type)
    if (vaporShieldMaterial) setVaporShieldMaterial(vaporShieldMaterial)

    const insulationMaterial = parseMaterial(stateInsulation?.type)
    if (insulationMaterial) setInsulationMaterial(insulationMaterial)

    const bottomLayerMaterial = parseMaterial(stateBottomLayer?.type)
    if (bottomLayerMaterial) setBottomLayerMaterial(bottomLayerMaterial)

    const topLayerMaterial = parseMaterial(stateTopLayer?.type)
    if (topLayerMaterial) setTopLayerMaterial(topLayerMaterial)

    let roofFloorMaterial = null
    switch (stateRoofFloor.type) {
      case RoofFloorTypes.WOOD:
        roofFloorMaterial = parseMaterial("ROOF_FLOOR_WOOD")
        break
      case RoofFloorTypes.CONCRETE:
        roofFloorMaterial = parseMaterial("ROOF_FLOOR_CONCRETE")
        break
      case RoofFloorTypes.STEEL_DECK:
        roofFloorMaterial = parseMaterial("ROOF_FLOOR_STEEL_DECK")
        break
    }
    if (roofFloorMaterial) setRoofFloorMaterial(roofFloorMaterial)

    history.push(Routes.FLAT_ROOF_5_OVERVIEW)
  }

  function reset() {
    setUpdatedOptions([])
    setLastUpdatedOption(null)
    resetConfigurationToSecuringMethods()
  }

  function parseMethod(stateValue: any, options: any) {
    let value = null
    if (stateValue) value = `${stateValue.type} / ${stateValue.method}`
    const dropdownOptions = options
      .filter((o: any) => o !== null)
      .map((o: any) => parseDropdownOption(o))
    return [value, dropdownOptions]
  }

  function parseDropdownOption(option: any) {
    if (!option) return
    const typeLabel = t("material.".concat(option.type) as any)
    const methodLabel = getSecuringMethodTranslation(t, option.method)
    return {
      label: `${typeLabel} - ${methodLabel}`,
      value: `${option.type} / ${option.method}`,
    }
  }

  const findClosestCombination = useCallback(
    (validCombinations: [any], currentCombination: any) => {
      let closestCombination = null
      let highestScore = 0
      if (lastUpdatedOption && currentCombination[lastUpdatedOption]) {
        for (const index in validCombinations) {
          let score = 0
          let validCombination = validCombinations[index]

          if (
            validCombination[lastUpdatedOption] ===
            currentCombination[lastUpdatedOption]
          ) {
            if (
              validCombination.vaporShield === currentCombination.vaporShield ||
              validCombination.insulation === currentCombination.insulation ||
              validCombination.bottomLayer === currentCombination.bottomLayer ||
              validCombination.topLayer === currentCombination.topLayer ||
              validCombination.finishing === currentCombination.finishing
            )
              score++
          }

          if (score > highestScore) closestCombination = validCombination
        }
      }
      return closestCombination
    },
    [lastUpdatedOption]
  )

  useEffect(() => {
    if (stateBottomLayer && stateBottomLayer.method) {
      renderer.displaySecuringMethod(
        "ONDERLAAG-BEVESTIGING",
        stateBottomLayer.method
      ) 
    }

    if (stateFinishing) {
      // Show ballast finishing on top
      renderer.displayRoofType(RoofTypeTypes.LIVING_ROOF)

      // Add KIMFIXATIE for the ONDERLAAG layer
      if (
        stateBottomLayer &&
        stateBottomLayer.method &&
        stateBottomLayer.method === SecuringMethodTypes.LOOSE
      ) {
        renderer.displaySecuringMethod(
          "ONDERLAAG-BEVESTIGING",
          SecuringMethodTypes.MECHANICAL_BALLAST
        )
      }
    } else {
      const roofType = stateRoofType.type
      renderer.displayRoofType(roofType)
      if (roofType === RoofTypeTypes.STANDARD_ROOF) {
        renderer.toggleLayers("DAKBEDEKKING", false)
      }
    }
  }, [stateFinishing, stateRoofType, stateBottomLayer])

  useEffect(() => {
    // Check if selected combination is valid
    if (remainingCombinations.length) {
      const currentCombination = {
        vaporShield: stateVaporShield
          ? `${stateVaporShield.type} / ${stateVaporShield.method}`
          : "",
        insulation: stateInsulation
          ? `${stateInsulation.type} / ${stateInsulation.method}`
          : "",
        bottomLayer: stateBottomLayer
          ? `${stateBottomLayer.type} / ${stateBottomLayer.method}`
          : "",
        topLayer: stateTopLayer
          ? `${stateTopLayer.type} / ${stateTopLayer.method}`
          : "",
        finishing: stateFinishing ? stateFinishing : "",
      }

      const validCombinations = remainingCombinations.map((x: any) => {
        return {
          vaporShield: x.vaporShield,
          insulation: x.insulation,
          bottomLayer: x.bottomLayer,
          topLayer: x.topLayer,
          finishing: x.finishing,
        }
      })

      const currentCombinationIsValid = validCombinations.some(
        (x: any) => JSON.stringify(x) === JSON.stringify(currentCombination)
      )

      if (currentCombinationIsValid) {
        setCombinationValid(true)
      } else {
        setCombinationValid(false)
        const closestCombination = findClosestCombination(
          validCombinations,
          currentCombination
        )
        if (closestCombination) {
          const updatedOptions: any = []
          const equalVaporShield =
            closestCombination.vaporShield !== currentCombination.vaporShield
          const equalInsulation =
            closestCombination.insulation !== currentCombination.insulation
          const equalBottomLayer =
            closestCombination.bottomLayer !== currentCombination.bottomLayer
          const equalTopLayer =
            closestCombination.topLayer !== currentCombination.topLayer
          const equalFinishing =
            closestCombination.finishing !== currentCombination.finishing

          if (equalVaporShield) {
            updatedOptions.push("vaporShield")
            setStateVaporShieldType(closestCombination.vaporShield)
          }

          if (equalInsulation) {
            updatedOptions.push("insulation")
            setStateInsulationType(closestCombination.insulation)
          }

          if (equalBottomLayer) {
            updatedOptions.push("bottomLayer")
            setStateBottomLayerType(closestCombination.bottomLayer)
          }

          if (equalTopLayer) {
            updatedOptions.push("topLayer")
            setStateTopLayerType(closestCombination.topLayer)
          }

          if (equalFinishing) {
            updatedOptions.push("finishing")
            setStateFinishingType(closestCombination.finishing)
          }

          setUpdatedOptions(
            updatedOptions.filter((key: any) => key !== lastUpdatedOption)
          )
        }
      }
    }
  }, [
    findClosestCombination,
    setStateVaporShieldType,
    setStateInsulationType,
    setStateBottomLayerType,
    setStateTopLayerType,
    setStateFinishingType,
    lastUpdatedOption,
    stateVaporShield,
    stateInsulation,
    stateBottomLayer,
    stateTopLayer,
    stateFinishing,
    remainingCombinations,
  ])

  function setVaporShieldType(value: any) {
    setLastUpdatedOption("vaporShield")
    setStateVaporShieldType(value)
  }
  function setInsulationType(value: any) {
    setLastUpdatedOption("insulation")
    setStateInsulationType(value)
  }
  function setBottomLayerType(value: any) {
    setLastUpdatedOption("bottomLayer")
    setStateBottomLayerType(value)
  }
  function setTopLayerType(value: any) {
    setLastUpdatedOption("topLayer")
    setStateTopLayerType(value)
  }

  return {
    t,
    reset,
    displayResetButton,
    handleNextButtonClick,
    nextButtonDisabled,
    lastUpdatedOption,
    updatedOptions,

    // Vapor shield
    vaporShieldDropdownOptions,
    setVaporShieldType,
    stateVaporShield,
    vaporShield,

    // Insulation
    insulationDropdownOptions,
    setInsulationType,
    stateInsulation,
    insulation,

    // Bottom layer
    bottomLayerDropdownOptions,
    setBottomLayerType,
    stateBottomLayer,
    bottomLayer,

    // Top layer
    topLayerDropdownOptions,
    setTopLayerType,
    stateTopLayer,
    topLayer,

    // Finishing
    stateFinishing,
  }
}

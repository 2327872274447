import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styled from "styled-components";
import { useModal } from "../../store/modal/hooks";
import { styles } from "../../styles";
import { P } from "../common/Typography";
import { UnstyledButton } from "../common/UnstyledButton";
import { Tabs } from "./Tabs";
import { LanguagePicker } from "./LanguagePicker";
import { SwiperToggle } from "./SwiperToggle";
import { useTabs } from "./tabs.hook";
import { isMobile } from "../../services/helpers.service";
import { useApplication } from "../../store/application/hooks";
import { useRoutes } from "../../store/routes/hooks";

const Nav = styled.nav`
  z-index: 10;
  width: 100%;
  min-height: ${(props) => props.theme.designHeight(64)}px;
  background: ${styles.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: ${(props) => props.theme.designWidth(24)}px;
  padding-right: ${(props) => props.theme.designWidth(24)}px;

  @media screen and (pointer: coarse) {
    padding: 0;
    height: auto;
    min-height: unset;

    .header {
      padding: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid ${styles.colors.darkBlue5};

      height: calc(48px + 16px + 16px);

      button {
        height: fit-content;
      }
    }

    &.has-application {
      width: 100%;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (pointer: coarse) {
    min-height: 32px;

    img {
      height: 100%;
    }
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;

  @media screen and (pointer: coarse) {
    * {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const Logo = styled.img`
  height: ${(props) => props.theme.designHeight(32)}px;
`;

const Navigation = () => {
  const { Routes } = useRoutes() as any;
  const { t } = useTranslation();
  const { displaySupportModal } = useModal();
  const { modelView, setCurrentView } = useApplication();
  const { tabs } = useTabs();
  const hasApplicationSelected = tabs.length > 0;
  const location = useLocation();
  const isPreviewPage = location.pathname === Routes.PREVIEW
  const { i18n } = useTranslation();

  function handleSupportClick() {
    displaySupportModal();
  }

  function navigateToHome() {
    if (i18n.language) window.location.href = `/${i18n.language}`
    else window.location.href = '/'
  }

  useEffect(() => {
    if (isMobile()) {
      const activeTab = tabs.find((t) => t.active);
      if (activeTab) {
        const tabsEl = document.querySelector(".tabs");
        if (tabsEl) {
          const activeTabEl = tabsEl.children[activeTab.step - 1];
          const elLeft = activeTabEl?.getBoundingClientRect().left;
          if (elLeft) {
            tabsEl.scrollBy({
              left:
                elLeft -
                (activeTab.step !== tabs[tabs.length - 1].step ? 16 : 0),
              behavior: "smooth",
            });
          }
        }
      }
    }
  }, [tabs]);

  return (
    <>
      {!isMobile() ? (
        <Nav>
          <UnstyledButton onClick={navigateToHome}>
            <LogoContainer>
              <Logo
                src={"/./assets/images/iko-logo.svg"}
                alt={"IKO Design center logo"}
              />
            </LogoContainer>
          </UnstyledButton>
          <Tabs />
          <RightSide>
            {!isPreviewPage &&
              <UnstyledButton onClick={handleSupportClick}>
                <P>{t("navigation.support")}</P>
              </UnstyledButton>
            }
            <LanguagePicker />
          </RightSide>
        </Nav>
      ) : (
        <Nav className={hasApplicationSelected ? "has-application" : ""}>
          {!hasApplicationSelected ? (
            <div className={"header"}>
              <UnstyledButton onClick={navigateToHome}>
                <LogoContainer>
                  <Logo
                    src={"/./assets/images/iko-logo.svg"}
                    alt={"IKO Design center logo"}
                  />
                </LogoContainer>
              </UnstyledButton>
              <RightSide>
                {!isPreviewPage &&
                  <UnstyledButton onClick={handleSupportClick}>
                    <P>{t("navigation.support")}</P>
                  </UnstyledButton>
                }
                <LanguagePicker />
              </RightSide>
            </div>
          ) : (
            <>
              <div className={"header"}>
                <UnstyledButton onClick={navigateToHome}>
                  <LogoContainer>
                    <Logo
                      src={"/./assets/images/iko-logo.svg"}
                      alt={"IKO Design center logo"}
                    />
                  </LogoContainer>
                </UnstyledButton>
                <SwiperToggle onClick={() => setCurrentView(!modelView)} />
              </div>
              <Tabs />
            </>
          )}
        </Nav>
      )}
    </>
  );
};

export { Navigation };

import { Models } from "../../viewer/renderer.api"

export function useFlatRoofModels() {
  const flatRoofOverviewModels: Models[] = [
    // FLAT ROOF
    Models.FLAT_ROOF,
    // FLAT ROOF - ATELIA
    Models.FLAT_ROOF_ATELIA,
    // FLAT ROOF - E CIRCULAIR
    Models.FLAT_ROOF_E_CIRCULAIR,
    // FLAT ROOF - FLAME FREE
    Models.FLAT_ROOF_FLAME_FREE,
    // FLAT ROOF - RETENTION
    Models.FLAT_ROOF_RETENTION,
  ]

  const angleConnectionModelSiblings = [
    Models.ANGLE_CONNECTION,
    Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION,
    Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION,
    Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION,
    Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION,
  ]

  const angleConnectionOutsideModelSiblings = [
    Models.ANGLE_CONNECTION_OUTSIDE,
    Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE,
    Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE,
    Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE,
    Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE,
  ]

  const settlementJointModelSiblings = [
    Models.SETTLEMENT_JOINT,
    Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT,
    Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT,
    Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT,
    Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT,
  ]

  const roofTerminalModelSiblings = [
    Models.ROOF_TERMINAL,
    Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL,
    Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL,
  ]

  const roofUpstandModelSiblings = [
    Models.ROOF_UPSTAND,
    Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND,
    Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND,
  ]

  const roofUpstandHorizontalModelSiblings = [
    Models.ROOF_UPSTAND_HORIZONTAL,
    Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL,
    Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL,
  ]

  const roofUpstandVerticalModelSiblings = [
    Models.ROOF_UPSTAND_VERTICAL,
    Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL,
    Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL,
  ]

  const roofSurfaceModelSiblings = [
    Models.ROOF_SURFACE,
    Models.FLAT_ROOF_ATELIA_ROOF_SURFACE,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE,
    Models.FLAT_ROOF_RETENTION_ROOF_SURFACE,
  ]

  const domeModelSiblings = [
    Models.DOME,
    Models.FLAT_ROOF_ATELIA_DOME,
    Models.FLAT_ROOF_E_CIRCULAIR_DOME,
    Models.FLAT_ROOF_FLAME_FREE_DOME,
    Models.FLAT_ROOF_RETENTION_DOME,
  ]

  const verticalWallBrickModelSiblings = [
    Models.VERTICAL_WALL_BRICK,
    Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK,
    Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK,
    Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK,
    Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK,
  ]

  const verticalWallConcreteModelSiblings = [
    Models.VERTICAL_WALL_CONCRETE,
    Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE,
    Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE,
    Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE,
    Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE,
  ]

  const drainagePipeHorizontalModelSiblings = [
    Models.DRAINAGE_PIPE_HORIZONTAL,
    Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL,
    Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL,
    Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL,
    Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL,
  ]

  const drainagePipeVerticalModelSiblings = [
    Models.DRAINAGE_PIPE_VERTICAL,
    Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL,
    Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL,
    Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL,
    Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL,
  ]

  const flatRoofDetailModels: Models[] = [
    // FLAT ROOF
    Models.ANGLE_CONNECTION,
    Models.ANGLE_CONNECTION_OUTSIDE,
    Models.SETTLEMENT_JOINT,
    Models.ROOF_TERMINAL,
    Models.ROOF_UPSTAND,
    Models.ROOF_UPSTAND_HORIZONTAL,
    Models.ROOF_UPSTAND_VERTICAL,
    Models.ROOF_SURFACE,
    Models.DOME,
    Models.VERTICAL_WALL_BRICK,
    Models.VERTICAL_WALL_CONCRETE,
    Models.DRAINAGE_PIPE_HORIZONTAL,
    Models.DRAINAGE_PIPE_VERTICAL,
    // FLAT ROOF - ATELIA
    Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION,
    Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE,
    Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT,
    Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL,
    Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND,
    Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL,
    Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL,
    Models.FLAT_ROOF_ATELIA_ROOF_SURFACE,
    Models.FLAT_ROOF_ATELIA_DOME,
    Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK,
    Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE,
    Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL,
    Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL,
    // FLAT ROOF - E CIRCULAIR
    Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION,
    Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE,
    Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL,
    Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE,
    Models.FLAT_ROOF_E_CIRCULAIR_DOME,
    Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK,
    Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE,
    Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL,
    Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL,
    // FLAT ROOF - FLAME FREE
    Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION,
    Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE,
    Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL,
    Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE,
    Models.FLAT_ROOF_FLAME_FREE_DOME,
    Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK,
    Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE,
    Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL,
    Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL,
    // FLAT ROOF - RETENTION
    Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION,
    Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE,
    Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT,
    Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL,
    Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND,
    Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL,
    Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL,
    Models.FLAT_ROOF_RETENTION_ROOF_SURFACE,
    Models.FLAT_ROOF_RETENTION_DOME,
    Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK,
    Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE,
    Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL,
    Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL,
  ]

  const isFlatRoofOverviewModel = (model: Models) => {
    return flatRoofOverviewModels.includes(model)
  }
  const isFlatRoofDetailModel = (model: Models) => {
    return flatRoofDetailModels.includes(model)
  }
  const isFlatRoofECirculairModel = (model: Models) => {
    const isECirculairModel =
      model === Models.FLAT_ROOF_E_CIRCULAIR ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_DOME ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL ||
      model === Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL

    return isECirculairModel
  }

  const getFlatRoofDetailInfoKey = (model: Models, keyType = "Title") => {
    if (angleConnectionModelSiblings.includes(model)) {
      return `modal.roofDetails.angleConnectionInside${keyType}`
    }
    if (angleConnectionOutsideModelSiblings.includes(model)) {
      return `modal.roofDetails.angleConnectionOutside${keyType}`
    }
    if (settlementJointModelSiblings.includes(model)) {
      return `modal.roofDetails.settlementJoint${keyType}`
    }
    if (roofTerminalModelSiblings.includes(model)) {
      return `modal.roofDetails.roofTerminal${keyType}`
    }
    if (roofUpstandModelSiblings.includes(model)) {
      return `modal.roofDetails.roofUpstand${keyType}`
    }
    if (roofUpstandHorizontalModelSiblings.includes(model)) {
      return `modal.roofDetails.roofUpstandHorizontal${keyType}`
    }
    if (roofUpstandVerticalModelSiblings.includes(model)) {
      return `modal.roofDetails.roofUpstandVertical${keyType}`
    }
    if (roofSurfaceModelSiblings.includes(model)) {
      return `modal.roofDetails.roofSurface${keyType}`
    }
    if (domeModelSiblings.includes(model)) {
      return `modal.roofDetails.dome${keyType}`
    }
    if (verticalWallBrickModelSiblings.includes(model)) {
      return `modal.roofDetails.verticalWallBrick${keyType}`
    }
    if (verticalWallConcreteModelSiblings.includes(model)) {
      return `modal.roofDetails.verticalWallConcrete${keyType}`
    }
    if (drainagePipeHorizontalModelSiblings.includes(model)) {
      return `modal.roofDetails.drainagePipeHorizontal${keyType}`
    }
    if (drainagePipeVerticalModelSiblings.includes(model)) {
      return `modal.roofDetails.drainagePipeVertical${keyType}`
    }

    return null
  }

  return {
    isFlatRoofOverviewModel,
    isFlatRoofDetailModel,
    isFlatRoofECirculairModel,
    getFlatRoofDetailInfoKey,
  }
}

import { WallInsulationTypes } from "@iko-design-center/shared";
import React from "react";
import styled from "styled-components";
import { styles } from "../../../styles";
import { Spacer } from "../../common/Spacer";
import { H1, H2, P } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useWallInsulation } from "./wallInsulation.hooks";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const WallInsulation = () => {
  const {
    t,
    wallInsulationType,
    setWallInsulationType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useWallInsulation();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("cavityWall.wallInsulation.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("cavityWall.wallInsulation.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("cavityWall.wallInsulation.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={WallInsulationTypes.ALU_TG}
          checked={wallInsulationType === WallInsulationTypes.ALU_TG}
          onClick={() => setWallInsulationType(WallInsulationTypes.ALU_TG)}
          text={t("cavityWall.wallInsulation.ALUTG")}
          disabled={false}
        />
        {!isMobile() && <Spacer heightPx={10} />}
        <RadioButton
          value={WallInsulationTypes.ALU_50}
          checked={wallInsulationType === WallInsulationTypes.ALU_50}
          onClick={() => setWallInsulationType(WallInsulationTypes.ALU_50)}
          text={t("cavityWall.wallInsulation.ALU50")}
          disabled={false}
        />
        {wallInsulationType === WallInsulationTypes.ALU_50 && (
          <Info
            title={t("cavityWall.wallInsulation.ALU50Title")}
            info={t("cavityWall.wallInsulation.ALU50Info")}
          />
        )}
        {wallInsulationType === WallInsulationTypes.ALU_TG && (
          <Info
            title={t("cavityWall.wallInsulation.ALUTGTitle")}
            info={t("cavityWall.wallInsulation.ALUTGInfo")}
          />
        )}
      </RadioButtonList>
    </Sidebar>
  );
};

type InfoProps = {
  title: string;
  info: string;
};

const Text = styled.div`
  font-size: ${13 / 16}rem;
  color: ${styles.colors.darkBlue};
  line-height: 1.2rem;

  @media screen and (pointer: coarse) {
    padding: 0;
    font-size: 15px;
    line-height: 22px;
    color: #2e3341;
  }
`;

const Info = ({ title, info }: InfoProps) => {
  return (
    <>
      <Spacer heightPx={!isMobile() ? 40 : 16} />
      <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
        {title}
      </H2>
      {!isMobile() && <Spacer heightPx={16} />}
      <Text dangerouslySetInnerHTML={{ __html: info }}></Text>
    </>
  );
};

import { ReactNode } from "react";
import styled from "styled-components";
import { styles } from "../../styles";

export enum FontFamilies {
  ROBOTO = `'Roboto', sans-serif`,
  LATO = `'Lato', sans-serif`,
  MONTSERRAT = `'Montserrat', sans-serif`,
}

type FontWeights = 400 | 500 | 700;

export type TypographyProps = {
  fontFamily?: FontFamilies;
  fontWeight?: FontWeights;
  fontSizePx?: number;
  color?: string;
  lineHeightPx?: number;
  innerHTML?: string | null;
  children: ReactNode;
};

const StyledP = styled.p<TypographyProps>`
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSizePx! / 16}rem;
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeightPx! / 19}rem;
  line-height: 1.2rem;
  margin-top: 3px;
`;

const P = ({
  fontFamily = FontFamilies.ROBOTO,
  fontWeight = 400,
  fontSizePx = 14,
  lineHeightPx = 20,
  color = styles.colors.dark2,
  innerHTML = null,
  children,
}: TypographyProps) => {
  return (
    <StyledP
      color={color}
      fontFamily={fontFamily}
      fontSizePx={fontSizePx}
      fontWeight={fontWeight}
      lineHeightPx={lineHeightPx}
      dangerouslySetInnerHTML={innerHTML ? { __html: innerHTML } : undefined}
    >
      {!innerHTML ? children : null}
    </StyledP>
  );
};

const StyledH1 = styled.h1<TypographyProps>`
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSizePx! / 16}rem;
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeightPx! / 16}rem;
`;

const H1 = ({
  fontFamily = FontFamilies.ROBOTO,
  fontWeight = 400,
  fontSizePx = 22,
  lineHeightPx = 20,
  color = styles.colors.dark2,
  children,
}: TypographyProps) => {
  return (
    <StyledH1
      color={color}
      fontFamily={fontFamily}
      fontSizePx={fontSizePx}
      fontWeight={fontWeight}
      lineHeightPx={lineHeightPx}
    >
      {children}
    </StyledH1>
  );
};

const StyledH2 = styled.h2<TypographyProps>`
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSizePx! / 16}rem;
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeightPx! / 16}rem;
`;

const H2 = ({
  fontFamily = FontFamilies.ROBOTO,
  fontWeight = 400,
  fontSizePx = 17,
  lineHeightPx = 20,
  color = styles.colors.dark2,
  children,
}: TypographyProps) => {
  return (
    <StyledH2
      color={color}
      fontFamily={fontFamily}
      fontSizePx={fontSizePx}
      fontWeight={fontWeight}
      lineHeightPx={lineHeightPx}
    >
      {children}
    </StyledH2>
  );
};

export { P, H1, H2 };

import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { styles } from "../../../styles";
import { Dropdown } from "../../common/Dropdown";
import { InfoIcon, ResetIcon } from "../../common/Icons";
import { InfoList } from "../../common/InfoList";
import { Spacer } from "../../common/Spacer";
import { H1, H2, P } from "../../common/Typography";
import { UnstyledButton } from "../../common/UnstyledButton";
import { useSecuringMethods } from "./securingMethods.hooks";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";
// import { RadioButton } from "../../common/RadioButton";
// import { getSingleSecuringMethodTranslations } from "./translations";
// import { SecuringMethodTypes } from "@iko-design-center/shared";

const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(110, 113, 136, 0.05);
  padding: 8px;
  border-radius: 6px;
  border: solid 1px rgba(110, 113, 136, 0.1);

  svg {
    min-width: 20px;
    max-width: 20px;
  }
`;

export const SecuringMethods = () => {
  const {
    t,
    reset,
    displayResetButton,
    handleNextButtonClick,
    nextButtonDisabled,
    updatedOptions,

    // Vapor shield
    vaporShieldDropdownOptions,
    setVaporShieldType,
    // stateVaporShield,
    vaporShield,

    // Insulation
    insulationDropdownOptions,
    setInsulationType,
    // stateInsulation,
    insulation,

    // Bottom layer
    bottomLayerDropdownOptions,
    setBottomLayerType,
    // stateBottomLayer,
    bottomLayer,

    // Top layer
    topLayerDropdownOptions,
    setTopLayerType,
    // stateTopLayer,
    topLayer,

    // Finishing
    stateFinishing,
  } = useSecuringMethods();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonDisabled={nextButtonDisabled}
      nextButtonText={`${t("flatRoof.securingMethods.nextButton")} (${currentStep + 1}/${totalSteps})`}
    >
      <Intro>
        <H1>{t("flatRoof.securingMethods.title")}</H1>
        <Spacer heightPx={24} />
        <SubTitleContainer>
          <InfoIcon />
          <P lineHeightPx={22}>
            {t("flatRoof.securingMethods.subtitle")}
          </P>
        </SubTitleContainer>
        <Spacer heightPx={32} />
      </Intro>
      <RadioButtonList>
        {/* {(stateTopLayer && stateBottomLayer && stateInsulation && stateVaporShield) && (
          <>
            <RadioButton
              value={null}
              checked={true}
              onClick={() => console.log()}
              text={`
                <div>
                  <h4>Top layer</h4>
                  <p>${t('material.'.concat(stateTopLayer.type) as any)}</p>
                  <p class="sub">${getSingleSecuringMethodTranslations(t, stateTopLayer.method)}</p>
                </div>
                <div>
                  <h4>Bottom layer</h4>
                  <p>${t('material.'.concat(stateBottomLayer.type) as any)}</p>
                  <p class="sub">${getSingleSecuringMethodTranslations(t, stateBottomLayer.method)}</p>
                </div>
                <div>
                  <h4>Insulation</h4>
                  <p>${t('material.'.concat(stateInsulation.type) as any)}</p>
                  <p class="sub">${getSingleSecuringMethodTranslations(t, stateInsulation.method)}</p>
                </div>
                <div>
                  <h4>Vapor shield</h4>
                  <p>${t('material.'.concat(stateVaporShield.type) as any)}</p>
                  <p class="sub">${getSingleSecuringMethodTranslations(t, stateVaporShield.method)}</p>
                </div>
              `}
              disabled={false}
              card={true}
              small={true}
            />
            {!isMobile() && <Spacer heightPx={12} />}
          </>)
        }
        <RadioButton
          value={null}
          checked={false}
          onClick={() => console.log()}
          text={`
            <div>
              <h4>Top layer</h4>
              <p>${"OtherType"}</p>
              <p class="sub">${"OtherMethod"}</p>
            </div>
            <div>
              <h4>Bottom layer</h4>
              <p>${"OtherType"}</p>
              <p class="sub">${"OtherMethod"}</p>
            </div>
            <div>
              <h4>Insulation</h4>
              <p>${"OtherType"}</p>
              <p class="sub">${"OtherMethod"}</p>
            </div>
            <div>
              <h4>Vapor shield</h4>
              <p>${"OtherType"}</p>
              <p class="sub">${"OtherMethod"}</p>
            </div>
          `}
          disabled={false}
          card={true}
          small={true}
        />
        {!isMobile() && <Spacer heightPx={40} />} */}

        {topLayerDropdownOptions.length > 0 &&
          <>
            <Dropdown
              updated={updatedOptions?.includes('topLayer')}
              options={topLayerDropdownOptions}
              selectedValue={topLayer}
              setSelectedValue={setTopLayerType}
              label={t("flatRoof.securingMethods.topLayer")}
              helperLabel={t("flatRoof.securingMethods.topLayerDropdownHelper")}
            />
            <Spacer heightPx={!isMobile() ? 24 : 0} />
          </>
        }
        {bottomLayerDropdownOptions.length > 0 &&
          <>
            <Dropdown
              updated={updatedOptions?.includes('bottomLayer')}
              options={bottomLayerDropdownOptions}
              selectedValue={bottomLayer}
              setSelectedValue={setBottomLayerType}
              label={t("flatRoof.securingMethods.bottomLayer")}
              helperLabel={t("flatRoof.securingMethods.bottomLayerDropdownHelper")}
            />
            <Spacer heightPx={!isMobile() ? 24 : 0} />
          </>
        }
        {insulationDropdownOptions.length > 0 &&
          <>
            <Dropdown
              updated={updatedOptions?.includes('insulation')}
              options={insulationDropdownOptions}
              selectedValue={insulation}
              setSelectedValue={setInsulationType}
              label={t("flatRoof.securingMethods.insulation")}
              helperLabel={t("flatRoof.securingMethods.insulationDropdownHelper")}
            />
            <Spacer heightPx={!isMobile() ? 24 : 0} />
          </>
        }
        {vaporShieldDropdownOptions.length > 0 &&
          <Dropdown
            updated={updatedOptions?.includes('vaporShield')}
            options={vaporShieldDropdownOptions}
            selectedValue={vaporShield}
            setSelectedValue={setVaporShieldType}
            label={t("flatRoof.securingMethods.vaporShield")}
            helperLabel={t("flatRoof.securingMethods.vaporShieldDropdownHelper")}
          />
        }
        {!isMobile() && <Spacer heightPx={16} />}
        {displayResetButton && <ResetButton onClick={reset} />}
        <Spacer heightPx={!isMobile() ? 40 : 24} />
        {!stateFinishing ? (
          <InfoList
            title={t("flatRoof.securingMethods.moreInfo")}
            info={[
              t("flatRoof.securingMethods.info0"),
              t("flatRoof.securingMethods.info1"),
              t("flatRoof.securingMethods.info2"),
            ]}
          />) :
          (<>
            <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
              {t("flatRoof.securingMethods.moreInfo")}
            </H2>
            {!isMobile() && <Spacer heightPx={12} />}
            <P>{t("flatRoof.securingMethods.ballastFinishingInfo")}</P>
          </>)
        }
      </RadioButtonList>
    </Sidebar>
  );
};

const ResetContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;

  @media screen and (pointer: coarse) {
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const Button = styled(UnstyledButton)`
  display: flex;
  align-items: center;

  > p {
    text-decoration: underline;
  }
`;

const ResetButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <ResetContainer>
      <Button onClick={onClick}>
        <P fontSizePx={14} lineHeightPx={22} color={styles.colors.darkBlue}>
          {t("flatRoof.securingMethods.resetButton")}
        </P>
        <Spacer widthPx={!isMobile() ? 10 : 24} />
        <ResetIcon />
      </Button>
    </ResetContainer>
  );
};

import { useCallback, useEffect, useState } from "react"
import { useApplication } from "../../store/application/hooks"
import { ApplicationTypes } from "../../store/application/store"
import { Models } from "./renderer.api"
import { renderer } from "./Renderer"
import { useFlatRoof } from "../../store/flat-roof/hooks"
import {
  FloorInsulationTypes,
  SlopedRoofInsulationTypeTypes,
  VentilatedWallInsulationTypes,
  WaterproofingTypes,
} from "@iko-design-center/shared"
import { isMobile } from "../../services/helpers.service"

export function useViewer() {
  const [loaderPercentage, setLoaderPercentage] = useState(0)
  const [displayLoader, setDisplayLoader] = useState(false)
  const [displayInstructions, setDisplayInstructions] = useState(false)
  const [displayAppBlocker, setDisplayAppBlocker] = useState(false)
  const [displayCountryAndLanguage, setDisplayCountryAndLanguage] = useState(
    true
  )
  const {
    applicationType,
    setApplicationType,
    setCurrentModel,
  } = useApplication()
  const { configurationState } = useFlatRoof()

  function hideInstructions() {
    setDisplayInstructions(false)
  }

  function handleCountryAndLanguageChanged() {
    setDisplayCountryAndLanguage(false)
    setDisplayAppBlocker(true)
    setDisplayLoader(true)
    setLoaderPercentage(0)
  }

  function handleProgressChange(percentage: number) {
    // setDisplayLoader(true);
    setLoaderPercentage(percentage)
  }

  function handleLoadStart() {
    setDisplayLoader(true)
  }

  function handleLoaded(id: string) {
    setDisplayLoader(false)
    setLoaderPercentage(0)
    setDisplayAppBlocker(false)

    switch (id) {
      case "dollhouse":
        setDisplayInstructions(true)
        break
      // FLAT ROOF
      case "platdak-overview":
        break
      // CAVITY WALL
      case "spouwmuur-overview":
        break
      // SLOPED ROOF
      case "hellend-dak-overview":
        renderer.displaySlopedRoofInsulation(
          SlopedRoofInsulationTypeTypes.ALU_NF
        )
        break
      // VENTILATED WALL
      case "ventgevel-overview":
        renderer.displayVentilatedWallInsulation(
          VentilatedWallInsulationTypes.ENERTHERM_ALU_PURE
        )
        break
      // FLOOR
      case "vloer-overview":
        renderer.displayFloorInsulationType(FloorInsulationTypes.ENERTHERM_ALU)
        break
      // SOL
      case "vloer_overview_sousdallage_fr":
        break
    }
  }

  const handleModelChange = useCallback(
    (model: Models) => {
      setCurrentModel(model)
      switch (model) {
        case Models.DOLLHOUSE:
          setApplicationType(null)
          if (!isMobile()) renderer.displayMarkers()
          break
        // FLAT ROOF
        case Models.FLAT_ROOF:
          setApplicationType(ApplicationTypes.FLAT_ROOF)
          break
        // CAVITY WALL
        case Models.CAVITY_WALL:
          setApplicationType(ApplicationTypes.CAVITY_WALL)
          break
        // SLOPED ROOF
        case Models.SLOPED_ROOF:
          setApplicationType(ApplicationTypes.SLOPED_ROOF)
          break
        // VENTILATED WALL
        case Models.VENTILATED_WALL:
          setApplicationType(ApplicationTypes.OUTER_WALL)
          break
        // FLOOR
        case Models.FLOOR:
          setApplicationType(ApplicationTypes.FLOOR)
          break
        // SOL
        case Models.SOL_DALLEPORTEE:
        case Models.SOL_SOUSCHAPE:
        case Models.SOL_SOUSDALLAGE:
          setApplicationType(ApplicationTypes.SOL)
          break
        default:
          break
      }

      renderer.removeInsulationFixation()
      renderer.toggleAllLayers(applicationType, true)

      if (applicationType === ApplicationTypes.SOL) {
        renderer.toggleLayers("AKOESTISCHE_LAAG", false)
      }

      if (isMobile()) {
        renderer.hideMarkers()
      }
    },
    [applicationType, setApplicationType, setCurrentModel]
  )

  useEffect(() => {
    if (!renderer) {
      return
    }

    const onLayerChange = () => {
      renderer.togglePMMALayers(false)
    }
    if (configurationState.waterproofing.type === WaterproofingTypes.LIQUID) {
      onLayerChange()
      document.addEventListener("layerChange", onLayerChange)
    } else {
      renderer.togglePMMALayers(true)
      document.removeEventListener("layerChange", onLayerChange)
    }
  }, [configurationState])

  return {
    handleLoadStart,
    handleProgressChange,
    handleLoaded,
    displayLoader,
    loaderPercentage,
    displayInstructions,
    displayAppBlocker,
    displayCountryAndLanguage,
    handleCountryAndLanguageChanged,
    hideInstructions,
    handleModelChange,
  }
}

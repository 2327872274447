import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { RadioCheckedIcon, RadioUncheckedIcon } from "./Icons";
import { UnstyledButton } from "./UnstyledButton";

const RadioContainer = styled.div<{ checked: boolean; disabled: boolean, card: boolean }>`
  cursor: ${(props) => (props.disabled ? `unset` : `pointer`)};
  position: relative;
  width: 100%;
  // height: ${(props) => props.theme.designHeight(52)}px;
  min-height: 45px;
  border: ${(props) =>
    props.checked
      ? `2px solid ${styles.colors.primary}`
      : `2px solid ${styles.colors.darkBlue30}`};
  border-radius: 8px;
  padding-left: ${(props) => props.theme.designWidth(24)}px;
  padding-right: ${(props) => props.theme.designWidth(24)}px;
  padding-top: ${(props) => props.theme.designHeight(15)}px;
  padding-bottom: ${(props) => props.theme.designHeight(15)}px;
  display: flex;
  transition: border 0.1s ease-out;
  
  ${(props) => {
    if (!props.checked) {
      return `
        &:hover {
          border: 2px solid ${styles.colors.darkBlue75};
      `;
    }
  }}

  ${(props) => {
    if (props.card) {
      return `
        height: auto;
        padding: ${props.checked
          ? props.theme.designHeight(15)
          : props.theme.designHeight(16)}px;
      `;
    }
  }}
`;

const DisabledOverlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${styles.colors.darkBlue5};
  border-radius: 8px;
`;

const Radio = styled(UnstyledButton) <{ card: boolean }>`
  display: flex;
  align-items: center;

  svg {
    margin-top: 4px;
    margin-right: 12px;
    width: 100%;
    max-width: 20px;
    min-width: 20px;
  }

  ${(props) => {
    if (props.card) {
      return `
        width: 100%;
        max-width: 100%;

        svg {
          align-self: flex-start;
        }
      `;
    }
  }}
`;

const Text = styled.div<{ small: boolean; card: boolean; }>`
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  font-weight: 400;
  font-size: ${(props) => props.small ? 13 / 16 : 14 / 16}rem;
  color: ${styles.colors.dark2};
  line-height: 1.2rem;
  margin-top: 3px;

  ${(props) => {
    if (props.card) {
      return `
        overflow: hidden;
        text-overflow: ellipsis;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        // grid-template-columns: repeat(1, 100%);
        width: 100%;
        max-width: 100%;
        grid-gap: ${10 / 16}rem;

        div {
          align-self: flex-start;

          h4 {
            margin-bottom: 4px;
          }

          h4, p {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .sub {
            margin-top: 2px;
            opacity: 0.7;
            color: ${styles.colors.dark2};
            font-size: ${props.small ? 11 / 16 : 12 / 16}rem;
            line-height: ${props.small ? 13 / 19 : 19 / 19}rem;
          }
        }
      `;
    }
  }}
`;

type RadioButtonProps = {
  checked: boolean;
  value: any;
  text: string;
  onClick: (value: any) => void;
  disabled: boolean;
  small?: boolean;
  card?: boolean;
};

export const RadioButton = ({
  checked,
  value,
  onClick,
  text,
  disabled,
  small = false,
  card = false,
}: RadioButtonProps) => {
  function handleClick() {
    if (!disabled) {
      onClick(value);
    }
  }

  return (
    <>
      <RadioContainer
        onClick={handleClick}
        checked={checked}
        disabled={disabled}
        card={card}
        className={"radio"}
      >
        {disabled && <DisabledOverlay />}
        <Radio card={card}>
          {checked && <RadioCheckedIcon />}
          {!checked && <RadioUncheckedIcon />}
          <Text small={small} card={card} dangerouslySetInnerHTML={{ __html: text }}></Text>
        </Radio>
      </RadioContainer>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { P } from "../common/Typography";
import { UnstyledButton } from "../common/UnstyledButton";
import { TabProps } from "./Tabs";
import { isMobile } from "../../services/helpers.service";
import useLongPress from "../common/useLongPress";

const Tab = styled.div<{ active: boolean }>`
  height: 100%;
  padding-left: ${(props) => props.theme.designWidth(30)}px;
  padding-right: ${(props) => props.theme.designWidth(30)}px;
  border-bottom: ${(props) =>
    props.active
      ? `4px solid ${styles.colors.primary}`
      : `4px solid ${styles.colors.transparent}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Text = styled.span`
  margin-left: 5px;
  user-select: none;
`;

const TitleContaier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const Button = styled(UnstyledButton) <{ active: boolean; visited: boolean }>`
  cursor: ${(props) => (props.visited ? "pointer" : "unset")};

  @media screen and (pointer: coarse) {
    z-index: ${(props) => (props.active ? "10" : "unset")};
  }
`;

const SelectedOption = styled.p`
  max-width: 128px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 2px;
  color: ${styles.colors.dark2};
  user-select: none;

  @media screen and (pointer: coarse) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const Tooltip = styled.div`
  background-color: ${styles.colors.dark2};
  position: absolute;
  top: calc(100% + 15px);
  z-index: 1000;
  text-align: left;
  padding: 8px 12px !important;
  border-radius: 5px;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transform: translateY(10px);

  @media screen and (pointer: coarse) {
    position: fixed;
    max-width: 75%;
  }

  &.-open {
    opacity: 1;
    transform: translateY(0);
  }

  .tooltip-content {
    color: white;
    font-size: 12px;
    line-height: 16px;
    padding: 0 !important;
    pointer-events: none;
    user-select: none;

    span.title {
      font-weight: bold;
      pointer-events: none;
      user-select: none;
    }

    .selected {
      font-weight: normal;
      pointer-events: none;
      user-select: none;
    }

    ul.selected {
      margin: 0;
      padding: 0 0 0 20px;

      li {
        white-space: nowrap;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${styles.colors.dark2};
    top: -5px;
    transform: translateX(-50%) rotate(45deg);
    left: 50%;
    z-index: -1;
  }
`;

export const RegularTab = ({
  step,
  text,
  selected,
  active,
  visited,
  onClick,
}: TabProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const buttonRef = useRef<any>(null);
  const tooltipRef = useRef<any>(null);

  const handleLongPress = (event: any) => {
    if (!isMobile() || !selected) return
    if (!buttonRef.current || !tooltipRef.current) return

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    const left = buttonRect.left + buttonRect.width / 2 - tooltipRect.width / 2;

    tooltipRef.current.style.left = `${left}px`;
    tooltipRef.current.style.top = `${buttonRect.top + buttonRect.height + 20}px`;

    setTooltipOpen(true)
  };

  useEffect(() => {
    if (!isMobile()) return
    function handleClickOutside() {
      if (tooltipRef.current) setTooltipOpen(false)
    }

    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("touchstart", handleClickOutside)
    document.addEventListener("touchmove", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("touchstart", handleClickOutside)
      document.removeEventListener("touchmove", handleClickOutside)
    }
  }, [])

  return (
    <Button ref={buttonRef} visited={visited} active={active} >
      <Tab active={active} {...useLongPress({ onLongPress: handleLongPress, onClick: onClick })} onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)}>
        <TitleContaier>
          <P
            fontWeight={500}
            color={visited ? styles.colors.dark2 : styles.colors.darkBlue50}
          >{`${step}.`}</P>
          <Text>
            <P
              fontWeight={700}
              color={visited ? styles.colors.dark2 : styles.colors.darkBlue50}
            >
              {text}
            </P>
          </Text>
        </TitleContaier>
        {selected &&
          <>
            <SelectedOption>{selected.replaceAll('<br/>', ", ")}</SelectedOption>
            <Tooltip className={`tooltip ${tooltipOpen ? "-open" : ""}`} ref={tooltipRef}>
              <div className="tooltip-content">
                <span className="title">
                  {text}:
                </span>
                <br />
                {selected.split('<br/>').filter((item) => item !== "").length > 1 ?
                  <ul className="selected">
                    {selected.split('<br/>').filter((item) => item !== "").map((item, index) => (
                      <li key={index}>
                        {item}
                        <br />
                      </li>
                    ))}
                  </ul> :
                  <span className="selected">
                    {selected}
                  </span>
                }
              </div>
            </Tooltip>
          </>
        }
      </Tab>
    </Button >
  );
};
import { Models } from "./renderer.api"
import { useApplication } from "../../store/application/hooks"
import { useTranslation } from "react-i18next"
import { useFlatRoofModels } from "../sidebar/flat-roof/models.hook"

export function useViewerDetail() {
  const { currentModel } = useApplication()
  const { i18n } = useTranslation()
  const {
    isFlatRoofDetailModel,
    getFlatRoofDetailInfoKey,
  } = useFlatRoofModels()

  function detailTitle(): string | undefined {
    let key = null

    // FLAT ROOF
    if (isFlatRoofDetailModel(currentModel)) {
      key = getFlatRoofDetailInfoKey(currentModel, "Title")
    }

    // SOL
    if (
      currentModel === Models.SOL_SOUSCHAPE ||
      currentModel === Models.SOL_PLANE_SOUSCHAPE ||
      currentModel === Models.SOL_WALL_CONNECTION_SOUSCHAPE
    ) {
      key = "sol.modal.details.souschape.title"
    }

    if (
      currentModel === Models.SOL_DALLEPORTEE ||
      currentModel === Models.SOL_PLANE_DALLEPORTEE ||
      currentModel === Models.SOL_WALL_CONNECTION_DALLEPORTEE
    ) {
      key = "sol.modal.details.dalleportee.title"
    }

    return translateOrUndefined(key)
  }

  function detailDescription(): string | undefined {
    let key = null

    // FLAT ROOF
    if (isFlatRoofDetailModel(currentModel)) {
      // key = getFlatRoofDetailInfoKey(currentModel, "Description")
      if (
        currentModel === Models.SETTLEMENT_JOINT ||
        currentModel === Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT ||
        currentModel === Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT ||
        currentModel === Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT ||
        currentModel === Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT
      ) {
        key = "modal.roofDetails.exceptionalDescription"
      } else {
        key = "modal.roofDetails.generalDescription"
      }
    }

    if (
      currentModel === Models.SOL_SOUSCHAPE ||
      currentModel === Models.SOL_PLANE_SOUSCHAPE ||
      currentModel === Models.SOL_WALL_CONNECTION_SOUSCHAPE
    ) {
      key = "sol.modal.details.souschape.description"
    }

    if (
      currentModel === Models.SOL_DALLEPORTEE ||
      currentModel === Models.SOL_PLANE_DALLEPORTEE ||
      currentModel === Models.SOL_WALL_CONNECTION_DALLEPORTEE
    ) {
      key = "sol.modal.details.dalleportee.description"
    }

    return translateOrUndefined(key)
  }

  function translateOrUndefined(key: string | null): string | undefined {
    if (key === null) {
      return undefined
    }
    if (!i18n.exists(key)) {
      return undefined
    }

    return i18n.t(key)
  }

  return {
    detailTitle,
    detailDescription,
  }
}

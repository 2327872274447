import {
  RoofTypeTypes,
  RoofFloorTypes,
  WaterproofingTypes,
  SecuringMethodTypes,
  RoofTypeClimateClasses,
} from "@iko-design-center/shared"

export function getRoofTypeAndClassTranslations(
  t: any,
  type: RoofTypeTypes | null,
  climateClass: RoofTypeClimateClasses | null
) {
  let translation = ""
  switch (type) {
    case RoofTypeTypes.ENERGY_ROOF:
      translation += t("flatRoof.roofType.energyRoof")
      break
    case RoofTypeTypes.LIVING_ROOF:
      translation += t("flatRoof.roofType.livingRoof")
      break
    case RoofTypeTypes.GREEN_ROOF:
      translation += t("flatRoof.roofType.greenRoof")
      break
    case RoofTypeTypes.RETENTION_ROOF:
      translation += t("flatRoof.roofType.retentionRoof")
      break
    case RoofTypeTypes.STANDARD_ROOF:
      translation += t("flatRoof.roofType.standardRoof")
      break
  }

  if (climateClass) translation += ` • ${climateClass}`
  if (type) return translation
  else return null
}

export function getRoofTypeTranslations(t: any, type: RoofTypeTypes | null) {
  switch (type) {
    case RoofTypeTypes.ENERGY_ROOF:
      return t("flatRoof.roofType.energyRoof")
    case RoofTypeTypes.LIVING_ROOF:
      return t("flatRoof.roofType.livingRoof")
    case RoofTypeTypes.GREEN_ROOF:
      return t("flatRoof.roofType.greenRoof")
    case RoofTypeTypes.RETENTION_ROOF:
      return t("flatRoof.roofType.retentionRoof")
    case RoofTypeTypes.STANDARD_ROOF:
      return t("flatRoof.roofType.standardRoof")
  }
}
export function getRoofClimateClassTranslations(
  t: any,
  climateClass: RoofTypeClimateClasses | null
) {
  switch (climateClass) {
    case RoofTypeClimateClasses.CLIMATE_CLASS_3:
      return t("flatRoof.roofType.climateClass3")
    case RoofTypeClimateClasses.CLIMATE_CLASS_4:
      return t("flatRoof.roofType.climateClass4")
  }
}

export function getRoofFloorTranslations(t: any, type: RoofFloorTypes | null) {
  switch (type) {
    case RoofFloorTypes.WOOD:
      return t("flatRoof.types.roofFloor.wood")
    case RoofFloorTypes.CONCRETE:
      return t("flatRoof.types.roofFloor.concrete")
    case RoofFloorTypes.STEEL_DECK:
      return t("flatRoof.types.roofFloor.steelDeck")
  }
}

export function getWaterproofingTranslations(
  t: any,
  type: WaterproofingTypes | null
) {
  switch (type) {
    case WaterproofingTypes.TWO_BITUMINOUS:
      return t("flatRoof.waterproofing.twoBituminous")
    case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING:
      return t("flatRoof.waterproofing.twoBituminousAirPurifying")
    case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR:
      return t("flatRoof.waterproofing.twoBituminousAirPurifyingCirculair")
    case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_FLAME_FREE:
      return t("flatRoof.waterproofing.twoBituminousAirPurifyingFlameFree")
    case WaterproofingTypes.TWO_BITUMINOUS_CIRCULAIR:
      return t("flatRoof.waterproofing.twoBituminousCirculair")
    case WaterproofingTypes.TWO_BITUMINOUS_FLAME_FREE:
      return t("flatRoof.waterproofing.twoBituminousFlameFree")
    case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR_FLAME_FREE:
      return t(
        "flatRoof.waterproofing.twoBituminousAirPurifyingCirculairFlameFree"
      )
    case WaterproofingTypes.ONE_BITUMINOUS_AIR_PURIFYING_CIRCULAIR:
      return t("flatRoof.waterproofing.oneBituminousAirPurifyingCirculair")
    case WaterproofingTypes.ONE_BITUMINOUS_FLAME_FREE:
      return t("flatRoof.waterproofing.oneBituminousFlameFree")
    case WaterproofingTypes.LIQUID:
      return t("flatRoof.waterproofing.liquid")
  }
}

export function getSingleSecuringMethodTranslations(
  t: any,
  method: SecuringMethodTypes
) {
  if (!method) return
  switch (method) {
    case SecuringMethodTypes.WELDING:
      return t("flatRoof.types.securingMethods.welding")
    case SecuringMethodTypes.LIQUID:
      return t("flatRoof.types.securingMethods.liquid")
    case SecuringMethodTypes.SELF_ADHERING:
      return t("flatRoof.types.securingMethods.selfAdhering")
    case SecuringMethodTypes.MECHANICAL:
      return t("flatRoof.types.securingMethods.mechanical")
    case SecuringMethodTypes.LOOSE:
      return t("flatRoof.types.securingMethods.loose")
    case SecuringMethodTypes.GLUE:
      return t("flatRoof.types.securingMethods.glue")
    case SecuringMethodTypes.LOOSE_BALLAST:
      return t("flatRoof.types.securingMethods.looseBallast")
    case SecuringMethodTypes.WARM_BITUMEN:
      return t("flatRoof.types.securingMethods.warmBitumen")
  }
}

export function getSecuringMethodTranslations(t: any, type: any) {
  let translation = ""
  const keys = Object.keys(type)
  keys.forEach((item, index) => {
    const layer = type[item]
    if (!layer) return
    const method = layer.method
    if (!method) return
    const material = layer.type
    if (!material) return

    translation += `${t("material.".concat(material) as any)} • `

    switch (method) {
      case SecuringMethodTypes.WELDING:
        translation += t("flatRoof.types.securingMethods.welding")
        break
      case SecuringMethodTypes.LIQUID:
        translation += t("flatRoof.types.securingMethods.liquid")
        break
      case SecuringMethodTypes.SELF_ADHERING:
        translation += t("flatRoof.types.securingMethods.selfAdhering")
        break
      case SecuringMethodTypes.MECHANICAL:
        translation += t("flatRoof.types.securingMethods.mechanical")
        break
      case SecuringMethodTypes.LOOSE:
        translation += t("flatRoof.types.securingMethods.loose")
        break
      case SecuringMethodTypes.GLUE:
        translation += t("flatRoof.types.securingMethods.glue")
        break
      case SecuringMethodTypes.LOOSE_BALLAST:
        translation += t("flatRoof.types.securingMethods.looseBallast")
        break
      case SecuringMethodTypes.WARM_BITUMEN:
        translation += t("flatRoof.types.securingMethods.warmBitumen")
        break
    }

    if (index !== keys.length - 1) translation += "<br/>"
  })

  return translation
}

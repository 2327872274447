import {
  // BottomLayerSecuringMethodTypes,
  FEMaterial,
  FlatRoofConfigurationState,
  // InsulationSecuringMethodTypes,
  RoofFloorTypes,
  RoofTypeClimateClasses,
  RoofTypeTypes,
  // TopLayerSecuringMethodTypes,
  // VaporShieldSecuringMethodTypes,
  WaterproofingTypes,
} from "@iko-design-center/shared"
import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../hooks"
import { DownloadState } from "./download"
import { flatRoofSlice } from "./store"

export function useFlatRoof() {
  const dispatch = useAppDispatch()
  const flatRoofState = useAppSelector((state) => state.flatRoof)
  const configurationState = useAppSelector(
    (state) => state.flatRoof.configuration
  )
  const downloadState = useAppSelector((state) => state.flatRoof.download)
  const possibleConfigurations = useAppSelector(
    (state) => state.flatRoof.possibleConfigurations
  )
  const thermalPerformance = useAppSelector(
    (state) => state.flatRoof.thermalPerformance
  )
  const flatRoofScreenshots = useAppSelector(
    (state) => state.flatRoof.screenshots
  )

  const setRoofTypeType = useCallback(
    (roofTypeType: RoofTypeTypes) =>
      dispatch(flatRoofSlice.actions.setRoofTypeType(roofTypeType)),
    [dispatch]
  )

  const setRoofTypeClimateClass = useCallback(
    (roofTypeClimateClass: RoofTypeClimateClasses) =>
      dispatch(
        flatRoofSlice.actions.setRoofTypeClimateClass(roofTypeClimateClass)
      ),
    [dispatch]
  )

  const setRoofFloorType = useCallback(
    (roofFloorType: RoofFloorTypes) =>
      dispatch(flatRoofSlice.actions.setRoofFloorType(roofFloorType)),
    [dispatch]
  )

  const setRoofFloorMaterial = useCallback(
    (material: FEMaterial) =>
      dispatch(flatRoofSlice.actions.setRoofFloorMaterial(material)),
    [dispatch]
  )

  const setWaterproofingType = useCallback(
    (waterproofingType: WaterproofingTypes) =>
      dispatch(flatRoofSlice.actions.setWaterproofingType(waterproofingType)),
    [dispatch]
  )

  const setSecuringMethodFinishing = useCallback(
    (finishing: any) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodFinishing(finishing)),
    [dispatch]
  )

  const setSecuringMethodTopLayer = useCallback(
    (topLayer: any) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodTopLayer(topLayer)),
    [dispatch]
  )

  const setSecuringMethodBottomLayer = useCallback(
    (bottomLayer: any) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodBottomLayer(bottomLayer)),
    [dispatch]
  )

  const setSecuringMethodInsulation = useCallback(
    (insulation: any) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodInsulation(insulation)),
    [dispatch]
  )

  const setSecuringMethodVaporShield = useCallback(
    (vaporShield: any) =>
      dispatch(flatRoofSlice.actions.setSecuringMethodVaporShield(vaporShield)),
    [dispatch]
  )

  const setTopLayerMaterial = useCallback(
    (material: FEMaterial) =>
      dispatch(flatRoofSlice.actions.setTopLayerMaterial(material)),
    [dispatch]
  )

  const setBottomLayerMaterial = useCallback(
    (material: FEMaterial) =>
      dispatch(flatRoofSlice.actions.setBottomLayerMaterial(material)),
    [dispatch]
  )

  const setInsulationMaterial = useCallback(
    (material: FEMaterial) =>
      dispatch(flatRoofSlice.actions.setInsulationMaterial(material)),
    [dispatch]
  )

  const setVaporShieldMaterial = useCallback(
    (material: FEMaterial) =>
      dispatch(flatRoofSlice.actions.setVaporShieldMaterial(material)),
    [dispatch]
  )

  const resetConfigurationToRoofType = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfigurationToRoofType()),
    [dispatch]
  )

  const resetConfigurationToRoofFloor = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfigurationToRoofFloor()),
    [dispatch]
  )

  const resetConfigurationToWaterproofing = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfigurationToWaterproofing()),
    [dispatch]
  )

  const resetConfigurationToSecuringMethods = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfigurationToSecuringMethods()),
    [dispatch]
  )

  const setConfiguration = useCallback(
    (configuration: FlatRoofConfigurationState) =>
      dispatch(flatRoofSlice.actions.setConfiguration(configuration)),
    [dispatch]
  )

  const setHumanReadableName = useCallback(
    (name: string) =>
      dispatch(flatRoofSlice.actions.setHumanReadableName(name)),
    [dispatch]
  )

  const resetConfiguration = useCallback(
    () => dispatch(flatRoofSlice.actions.resetConfiguration()),
    [dispatch]
  )

  const setDownloadState = useCallback(
    (downloadState: DownloadState) =>
      dispatch(flatRoofSlice.actions.setDownloadState(downloadState)),
    [dispatch]
  )

  const addScreenshot = useCallback(
    (dataURL: string) => dispatch(flatRoofSlice.actions.addScreenshot(dataURL)),
    [dispatch]
  )

  const setOverviewScreenshot = useCallback(
    (dataURL: string) =>
      dispatch(flatRoofSlice.actions.setOverviewScreenshot(dataURL)),
    [dispatch]
  )

  return {
    flatRoofState,
    addScreenshot,
    setOverviewScreenshot,
    flatRoofScreenshots,
    thermalPerformance,
    downloadState,
    possibleConfigurations,
    configurationState,
    setRoofFloorType,
    setRoofFloorMaterial,
    setRoofTypeClimateClass,
    setRoofTypeType,
    setWaterproofingType,
    setSecuringMethodFinishing,
    setSecuringMethodTopLayer,
    setSecuringMethodBottomLayer,
    setSecuringMethodInsulation,
    setSecuringMethodVaporShield,
    setTopLayerMaterial,
    setBottomLayerMaterial,
    setInsulationMaterial,
    setVaporShieldMaterial,
    setConfiguration,
    setHumanReadableName,
    setDownloadState,
    resetConfigurationToRoofType,
    resetConfigurationToRoofFloor,
    resetConfigurationToWaterproofing,
    resetConfigurationToSecuringMethods,
    resetConfiguration,
  }
}

import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { renderer } from "../../viewer/Renderer"
import { useEffect, useState } from "react"
import { parseMatrixStringValue } from "../../../services/helpers.service"
import { useMatrix } from "./matrix.hook"

export function useSecuringMethodsTopLayer() {
  const { getRemainingCombinations } = useMatrix()
  const [topLayerOptions, setTopLayerOptions] = useState<any>([])
  const { configurationState, setSecuringMethodTopLayer } = useFlatRoof()
  const { topLayer: stateTopLayer } = configurationState.securingMethods
  const {
    roofType: stateRoofType,
    roofFloor: stateRoofFloor,
    waterproofing: stateWaterproofing,
  } = configurationState

  useEffect(() => {
    if (topLayerOptions.length && !stateTopLayer) {
      const configTopLayer = topLayerOptions[0]
      if (stateTopLayer !== configTopLayer) {
        setSecuringMethodTopLayer(configTopLayer)
        renderer.displayTopLayerMaterial(configTopLayer.type)
        renderer.displaySecuringMethod(
          "TOPLAAG-BEVESTIGING",
          configTopLayer.method
        )
      }
    }
  }, [stateTopLayer, setSecuringMethodTopLayer, topLayerOptions])

  useEffect(() => {
    // MATRIX FILTERING
    if (!topLayerOptions.length) {
      const topLayerOptions = getRemainingCombinations(
        stateRoofType.type,
        stateRoofType.climateClass,
        stateRoofFloor.type,
        stateWaterproofing.type
      ).map((x: any) => x.topLayer)
      const uniqueTopLayerOptions = topLayerOptions
        .filter((item, index) => topLayerOptions.indexOf(item) === index)
        .map((x: any) => parseMatrixStringValue(x))
      setTopLayerOptions(uniqueTopLayerOptions)
    }
  }, [
    getRemainingCombinations,
    stateRoofType,
    stateRoofFloor,
    stateWaterproofing,
    topLayerOptions,
  ])

  function setTopLayerType(value: string) {
    const option = parseMatrixStringValue(value)
    setSecuringMethodTopLayer(option)
    if (option) {
      renderer.displayTopLayerMaterial(option.type)
      renderer.displaySecuringMethod("TOPLAAG-BEVESTIGING", option.method)
    }
  }

  return {
    stateTopLayer,
    topLayerOptions,
    setTopLayerType,
  }
}

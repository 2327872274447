import { SlopedRoofInsulationTypeTypes } from "@iko-design-center/shared";
import React from "react";
import styled from "styled-components";
import { styles } from "../../../styles";
import { Spacer } from "../../common/Spacer";
import { H1, H2, P } from "../../common/Typography";
import { RadioButton } from "../../common/RadioButton";
import { useRoofInsulation } from "./roofInsulation.hooks";
import { Sidebar } from "../Sidebar";
import { Intro, RadioButtonList } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { useTabs } from "../../navigation/tabs.hook";

export const RoofInsulation = () => {
  const {
    t,
    slopedRoofInsulationType,
    setSlopedRoofInsulationType,
    nextButtonDisabled,
    handleNextButtonClick,
  } = useRoofInsulation();

  const { currentStep, tabs } = useTabs();
  const totalSteps = tabs[tabs.length - 1].step

  return (
    <Sidebar
      handleNextButtonClick={handleNextButtonClick}
      nextButtonText={`${t("slopedRoof.roofInsulation.nextButton")} (${currentStep + 1}/${totalSteps})`}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Intro>
        <H1>{t("slopedRoof.roofInsulation.title")}</H1>
        <Spacer heightPx={12} />
        <P lineHeightPx={22}>{t("slopedRoof.roofInsulation.subtitle")}</P>
        {!isMobile() && <Spacer heightPx={32} />}
      </Intro>
      <RadioButtonList>
        <RadioButton
          value={SlopedRoofInsulationTypeTypes.ALU_NF}
          checked={
            slopedRoofInsulationType === SlopedRoofInsulationTypeTypes.ALU_NF
          }
          onClick={() =>
            setSlopedRoofInsulationType(SlopedRoofInsulationTypeTypes.ALU_NF)
          }
          text={t("slopedRoof.roofInsulation.ALUNF")}
          disabled={false}
        />
        {!isMobile() && <Spacer heightPx={10} />}
        <RadioButton
          value={SlopedRoofInsulationTypeTypes.ALU_NF_PRO}
          checked={
            slopedRoofInsulationType ===
            SlopedRoofInsulationTypeTypes.ALU_NF_PRO
          }
          onClick={() =>
            setSlopedRoofInsulationType(
              SlopedRoofInsulationTypeTypes.ALU_NF_PRO
            )
          }
          text={t("slopedRoof.roofInsulation.ALUNFPRO")}
          disabled={false}
        />
        {slopedRoofInsulationType === SlopedRoofInsulationTypeTypes.ALU_NF && (
          <Info
            title={t("slopedRoof.roofInsulation.ALUNFTitle")}
            info={t("slopedRoof.roofInsulation.ALUNFInfo")}
          />
        )}
        {slopedRoofInsulationType ===
          SlopedRoofInsulationTypeTypes.ALU_NF_PRO && (
          <Info
            title={t("slopedRoof.roofInsulation.ALUNFPROTitle")}
            info={t("slopedRoof.roofInsulation.ALUNFPROInfo")}
          />
        )}
      </RadioButtonList>
    </Sidebar>
  );
};

type InfoProps = {
  title: string;
  info: string;
};

const Text = styled.div`
  font-size: ${13 / 16}rem;
  color: ${styles.colors.darkBlue};
  line-height: 1.2rem;

  @media screen and (pointer: coarse) {
    padding: 0;
    font-size: 15px;
    line-height: 22px;
    color: #2e3341;
  }
`;

const Info = ({ title, info }: InfoProps) => {
  return (
    <>
      <Spacer heightPx={!isMobile() ? 40 : 16} />
      <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
        {title}
      </H2>
      {!isMobile() && <Spacer heightPx={16} />}
      <Text dangerouslySetInnerHTML={{ __html: info }}></Text>
    </>
  );
};

import {
  BottomLayerSecuringMethodTypes,
  FEMaterial,
  FlatRoofConfigurationState,
  InsulationSecuringMethodTypes,
  PossibleConfiguration,
  RoofFloorTypes,
  RoofTypeClimateClasses,
  RoofTypeTypes,
  TopLayerSecuringMethodTypes,
  VaporShieldSecuringMethodTypes,
  WaterproofingTypes,
} from "@iko-design-center/shared"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DownloadState } from "./download"
import { FlatRoofState, initialFlatRoofState } from "./state"
import { getThermalPerformance, updateThermalLayer } from "./thermalPerformance"

export const flatRoofSlice = createSlice({
  name: "flatRoof",
  initialState: initialFlatRoofState,
  reducers: {
    resetConfiguration: (state) => {
      return {
        ...initialFlatRoofState,
        download: state.download,
        screenshots: state.screenshots,
      }
    },
    resetConfigurationToRoofType: (state) => {
      const draft = {
        ...initialFlatRoofState,
        download: state.download,
        configuration: {
          ...initialFlatRoofState.configuration,
          roofType: state.configuration.roofType,
        },
        screenshots: state.screenshots,
      }
      return updatePossibleConfigurations(draft)
    },
    resetConfigurationToRoofFloor: (state) => {
      const draft = {
        ...initialFlatRoofState,
        download: state.download,
        configuration: {
          ...initialFlatRoofState.configuration,
          roofType: state.configuration.roofType,
          roofFloor: state.configuration.roofFloor,
        },
        screenshots: state.screenshots,
      }
      return updatePossibleConfigurations(draft)
    },
    resetConfigurationToWaterproofing: (state) => {
      const draft = {
        ...initialFlatRoofState,
        download: state.download,
        configuration: {
          ...initialFlatRoofState.configuration,
          roofType: state.configuration.roofType,
          roofFloor: state.configuration.roofFloor,
          waterproofing: state.configuration.waterproofing,
        },
        screenshots: state.screenshots,
      }
      return updatePossibleConfigurations(draft)
    },
    resetConfigurationToSecuringMethods: (state) => {
      const draft = {
        ...initialFlatRoofState,
        download: state.download,
        configuration: {
          ...initialFlatRoofState.configuration,
          roofType: state.configuration.roofType,
          roofFloor: state.configuration.roofFloor,
          waterproofing: state.configuration.waterproofing,
          securingMethods: state.configuration.securingMethods,
        },
        screenshots: state.screenshots,
      }
      return updatePossibleConfigurations(draft)
    },

    setRoofTypeType: (state, action: PayloadAction<RoofTypeTypes>) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          roofType: {
            ...state.configuration.roofType,
            type: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setRoofTypeClimateClass: (
      state,
      action: PayloadAction<RoofTypeClimateClasses>
    ) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          roofType: {
            ...state.configuration.roofType,
            climateClass: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setRoofFloorType: (state, action: PayloadAction<RoofFloorTypes>) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          roofFloor: {
            ...state.configuration.roofFloor,
            type: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setRoofFloorMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload
      state.configuration.roofStructure.roofFloorMaterial = material
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "roofFloor"
      )
    },

    setWaterproofingType: (
      state,
      action: PayloadAction<WaterproofingTypes>
    ) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          waterproofing: {
            ...state.configuration.waterproofing,
            type: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setSecuringMethodFinishing: (state, action: PayloadAction<any>) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          securingMethods: {
            ...state.configuration.securingMethods,
            finishing: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setSecuringMethodTopLayer: (
      state,
      action: PayloadAction<TopLayerSecuringMethodTypes>
    ) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          securingMethods: {
            ...state.configuration.securingMethods,
            topLayer: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setSecuringMethodBottomLayer: (
      state,
      action: PayloadAction<BottomLayerSecuringMethodTypes>
    ) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          securingMethods: {
            ...state.configuration.securingMethods,
            bottomLayer: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setSecuringMethodInsulation: (
      state,
      action: PayloadAction<InsulationSecuringMethodTypes>
    ) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          securingMethods: {
            ...state.configuration.securingMethods,
            insulation: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setSecuringMethodVaporShield: (
      state,
      action: PayloadAction<VaporShieldSecuringMethodTypes>
    ) => {
      const draft = {
        ...state,
        configuration: {
          ...state.configuration,
          securingMethods: {
            ...state.configuration.securingMethods,
            vaporShield: action.payload,
          },
        },
      }
      return updatePossibleConfigurations(draft)
    },

    setTopLayerMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload
      state.configuration.roofStructure.topLayerMaterial = material
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "topLayer"
      )
    },

    setBottomLayerMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload
      state.configuration.roofStructure.bottomLayerMaterial = material
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "bottomLayer"
      )
    },

    setInsulationMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload
      state.configuration.roofStructure.insulationMaterial = material
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "insulation"
      )
    },

    setVaporShieldMaterial: (state, action: PayloadAction<FEMaterial>) => {
      const material = action.payload
      state.configuration.roofStructure.vaporShieldMaterial = material
      state.thermalPerformance = updateThermalLayer(
        state.thermalPerformance,
        material,
        "vaporShield"
      )
    },

    setConfiguration(state, action: PayloadAction<FlatRoofConfigurationState>) {
      state.configuration = action.payload
      state.thermalPerformance = getThermalPerformance(action.payload)
    },
    setHumanReadableName: (state, action: PayloadAction<string>) => {
      state.configuration.humanReadableName = action.payload
      state.thermalPerformance = getThermalPerformance(state.configuration)
    },
    setDownloadState: (state, action: PayloadAction<DownloadState>) => {
      state.download = action.payload
    },
    addScreenshot: (state, action: PayloadAction<string>) => {
      state.screenshots = [...state.screenshots, action.payload]
    },
    setOverviewScreenshot: (state, action: PayloadAction<string>) => {
      state.overviewScreenshot = action.payload
    },
  },
})

function updatePossibleConfigurations(state: FlatRoofState): FlatRoofState {
  const draft: FlatRoofState = JSON.parse(JSON.stringify(state))
  const curr = draft.configuration
  draft.possibleConfigurations = draft.allPossibleConfigurations.filter(
    (conf: PossibleConfiguration) => {
      const matches = []
      for (const key in curr) {
        //@ts-ignore
        for (const subKey in curr[key]) {
          //@ts-ignore
          const currValue = curr[key][subKey]
          //@ts-ignore
          const configValue = conf[key][subKey]

          // Do not compare the materials
          if (currValue !== null && typeof currValue !== "object") {
            if (configValue === currValue) {
              matches.push(true)
            } else {
              matches.push(false)
            }
          }
        }
      }
      if (matches.length > 0) {
        return matches.reduce((res, cur) => res && cur, true)
      } else {
        return false
      }
    }
  )
  return draft
}

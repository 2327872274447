import React from "react";

export const EnergyRoofIcon = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.75 16.6667C18.75 15.0091 19.4085 13.4194 20.5806 12.2473C21.7528 11.0751 23.3425 10.4167 25.0002 10.4167C26.6578 10.4167 28.2476 11.0751 29.4197 12.2473C30.5918 13.4194 31.2503 15.0091 31.2503 16.6667M25.0002 22.9167V39.5833V45.8333M39.5839 31.25H10.4165M25.0002 45.8333H29.1669M25.0002 45.8333H20.8334M25.0002 5.20833V4.16667M36.4588 16.6667H37.5005M12.4998 16.6667H13.5415M33.1025 8.5625L33.8379 7.82708M16.1624 7.82917L16.8979 8.56458M32.9024 22.9167H17.0979C15.0707 22.9167 14.0561 22.9167 13.279 23.4729C12.504 24.0292 12.1477 25.0104 11.4352 26.9729L9.92478 31.1396C8.53307 34.975 7.83722 36.8938 8.73724 38.2375C9.63935 39.5833 11.6207 39.5833 15.5853 39.5833H34.415C38.3797 39.5833 40.361 39.5833 41.2631 38.2375C42.1631 36.8938 41.4673 34.975 40.0755 31.1396L38.5651 26.9729C37.8526 25.0104 37.4963 24.0292 36.7192 23.4729C35.9463 22.9167 34.9317 22.9167 32.9024 22.9167Z" stroke="#2E3341" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export const LivingRoofIcon = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8166 38.0478L10.4812 12.7082C10.2291 10.8082 10.6583 10.4165 12.7624 10.4165H35.1562C37.2603 10.4165 37.6853 10.8082 37.4353 12.7082L34.0999 38.0478C33.6458 41.4957 33.4187 43.2207 32.1124 44.2561C29.4103 46.404 18.3895 46.3123 15.8041 44.2561C14.4978 43.2207 14.2708 41.4957 13.8166 38.0478Z" stroke="#2E3341" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.125 39.5832L29.0542 6.12692C29.0855 5.59668 29.3182 5.09844 29.7047 4.73405C30.0911 4.36966 30.6022 4.16664 31.1333 4.1665H39.5854" stroke="#2E3341" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5 20.8335C12.8792 20.1397 14.1292 18.7502 16.8542 18.7502C20.2646 18.7502 19.3646 20.8335 21.6375 20.8335C23.9104 20.8335 27.3208 18.7502 30.7292 18.7502C34.1375 18.7502 36.4583 20.8335 36.4583 20.8335M20.8521 33.3335H20.8333" stroke="#2E3341" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export const GreenRoofIcon = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.4603 24.4625C35.4645 24.9542 31.8103 21.9854 31.2999 17.8333C30.7895 13.6813 35.5416 9.67917 33.8166 4.16667C42.3645 5.40417 45.3103 12.2938 45.7749 16.0542C46.2853 20.2063 43.4562 23.9708 39.4603 24.4625Z" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M35.4166 41.6667C39.427 31.925 40.0624 23.3208 39.3061 16.6667M30.1915 36.8083C27.7436 41.7917 21.8311 43.1667 16.9874 39.8792C12.1436 36.5917 11.3791 27.4792 4.16655 24.3021C11.8645 15.775 21.4645 18.8542 25.8499 21.8313C30.6957 25.1188 32.6395 31.825 30.1915 36.8104" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M37.5 45.8333C32.5458 37.3 26.5333 32.1458 20.8333 29.1667" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export const RetentionRoofIcon = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.0415 31.25L23.9582 35.4167M35.4165 31.25L33.3332 35.4167M28.1248 39.5833L26.0415 43.75M16.6665 31.25L14.5832 35.4167M18.7498 39.5833L16.6665 43.75M36.4123 18.5375H36.4582C41.6353 18.5375 45.8332 22.6625 45.8332 27.7521C45.8301 29.2752 45.4476 30.7736 44.7201 32.1118C43.9926 33.45 42.9431 34.5859 41.6665 35.4167M36.4123 18.5375C36.4415 18.2 36.4568 17.8583 36.4582 17.5125C36.4582 11.2938 31.329 6.25 24.9998 6.25C19.0061 6.25 14.0873 10.7729 13.5832 16.5354M36.4123 18.5375C36.2036 20.8005 35.3055 22.9449 33.8394 24.6812M13.5832 16.5354C8.29984 17.0312 4.1665 21.4042 4.1665 26.7292C4.16897 28.3259 4.54748 29.8995 5.27137 31.3227C5.99527 32.7459 7.04418 33.9785 8.33317 34.9208M13.5832 16.5354C13.9123 16.5049 14.2457 16.4896 14.5832 16.4896C16.929 16.4896 19.0936 17.2521 20.8332 18.5375" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export const StandardRoofIcon = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.7498 36.4583C43.7498 38.3922 42.9816 40.2469 41.6142 41.6143C40.2467 42.9818 38.392 43.75 36.4582 43.75C34.5243 43.75 32.6696 42.9818 31.3022 41.6143C29.9347 40.2469 29.1665 38.3922 29.1665 36.4583C29.1665 34.5245 29.9347 32.6698 31.3022 31.3023C32.6696 29.9349 34.5243 29.1667 36.4582 29.1667C38.392 29.1667 40.2467 29.9349 41.6142 31.3023C42.9816 32.6698 43.7498 34.5245 43.7498 36.4583Z" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M36.4583 29.1667H13.5417C11.6078 29.1667 9.75313 29.9349 8.38568 31.3023C7.01823 32.6698 6.25 34.5245 6.25 36.4583C6.25 38.3922 7.01823 40.2469 8.38568 41.6143C9.75313 42.9818 11.6078 43.75 13.5417 43.75H36.4583C38.3922 43.75 40.2469 42.9818 41.6143 41.6143C42.9818 40.2469 43.75 38.3922 43.75 36.4583C43.75 34.5245 42.9818 32.6698 41.6143 31.3023C40.2469 29.9349 38.3922 29.1667 36.4583 29.1667ZM6.25 13.5417C6.25 14.4992 6.4386 15.4474 6.80504 16.3321C7.17149 17.2167 7.70859 18.0206 8.38568 18.6977C9.06277 19.3747 9.8666 19.9118 10.7513 20.2783C11.6359 20.6447 12.5841 20.8333 13.5417 20.8333C14.4992 20.8333 15.4474 20.6447 16.3321 20.2783C17.2167 19.9118 18.0206 19.3747 18.6977 18.6977C19.3747 18.0206 19.9118 17.2167 20.2783 16.3321C20.6447 15.4474 20.8333 14.4992 20.8333 13.5417C20.8333 11.6078 20.0651 9.75313 18.6977 8.38568C17.3302 7.01823 15.4755 6.25 13.5417 6.25C11.6078 6.25 9.75313 7.01823 8.38568 8.38568C7.01823 9.75313 6.25 11.6078 6.25 13.5417Z" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5417 6.25H36.4583C37.4159 6.25 38.3641 6.4386 39.2487 6.80504C40.1334 7.17149 40.9372 7.70859 41.6143 8.38568C42.2914 9.06277 42.8285 9.8666 43.195 10.7513C43.5614 11.6359 43.75 12.5841 43.75 13.5417C43.75 14.4992 43.5614 15.4474 43.195 16.3321C42.8285 17.2167 42.2914 18.0206 41.6143 18.6977C40.9372 19.3747 40.1334 19.9118 39.2487 20.2783C38.3641 20.6447 37.4159 20.8333 36.4583 20.8333H13.5417C12.5841 20.8333 11.6359 20.6447 10.7513 20.2783C9.8666 19.9118 9.06277 19.3747 8.38568 18.6977C7.70859 18.0206 7.17149 17.2167 6.80504 16.3321C6.4386 15.4474 6.25 14.4992 6.25 13.5417C6.25 12.5841 6.4386 11.6359 6.80504 10.7513C7.17149 9.8666 7.70859 9.06277 8.38568 8.38568C9.06277 7.70859 9.8666 7.17149 10.7513 6.80504C11.6359 6.4386 12.5841 6.25 13.5417 6.25Z" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

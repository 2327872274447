import { FEMaterial } from "@iko-design-center/shared"
import { FE_MATERIALS } from "@iko-design-center/shared"
import {
  BottomLayerTypes,
  InsulationTypes,
  SecuringMethodTypes,
  TopLayerTypes,
  VaporShieldTypes,
  MaterialIDs,
} from "@iko-design-center/shared"

export const isMobile = () => {
  const touch = window.matchMedia("(pointer:coarse)")
  return (touch && touch.matches) || window.innerWidth < 1024
}

export const parseMatrixStringValue = (value: string) => {
  if (value) {
    const [type, method] = value.split(" / ")
    if (!type || !method) return null
    const object = {
      type:
        // @ts-ignore
        TopLayerTypes[type] ||
        // @ts-ignore
        BottomLayerTypes[type] ||
        // @ts-ignore
        InsulationTypes[type] ||
        // @ts-ignore
        VaporShieldTypes[type],
      // @ts-ignore
      method: SecuringMethodTypes[method.toUpperCase().replace(" ", "_")],
    }
    return object
  }
  return null
}

export const parseMaterial = (id: string) => {
  const materialId = id as MaterialIDs
  const material = FE_MATERIALS.find(
    (material: FEMaterial) => material.id === materialId
  )
  if (!material) return null
  else return material
}

export const getEnumKey = (enumValue: string | null, EnumTypes: any) => {
  if (!enumValue) return null
  return Object.keys(EnumTypes)[Object.values(EnumTypes).indexOf(enumValue)]
}

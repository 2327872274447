import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { P } from "../common/Typography";
import { useTranslation } from "react-i18next";

type IconTileProps = {
  children: ReactNode;
  selected: boolean;
  count?: number;
  text: string;
  onClick: () => void;
  disabled?: boolean;
  colorCode?: string;
};

const Tile = styled.div<{ disabled?: boolean }>`
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  height: 100%;
  background-color: unset;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  overflow: visible;
  min-width: 100%;

  p {
    max-width: 100%;
    font-size: 13px;
    line-height: 16px;
    hyphens: auto;
  }

  @media (min-width: 800px) {
    p {
      font-size: 11px;
      line-height: 14px;
    }
  }

  @media (min-width: 1024px) {
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media (min-width: 1440px) {
    p {
      font-size: 13px;
      line-height: 16px;
    }
  }

  @media screen and (pointer: coarse) {
    max-width: 100%;
    justify-self: center;

    p {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

const IconContainer = styled.div<{ selected: boolean, disabled?: boolean, colorCode?: string }>`
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  border: ${(props) =>
    props.selected
      ? `2px solid ${styles.colors.primary}`
      : `2px solid ${styles.colors.transparent}`};
  transition: border 0.1s ease-out;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  &:hover {
    border: ${(props) => (!props.selected && !props.disabled) && `2px solid ${styles.colors.darkBlue30}`};
  }

  .icon-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::after {
    content: '';
    width: 85%;
    height: 85%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    position: absolute;
    box-shadow: 0px 2px 4px 0px #00000026;
  }
`;

const IconListWrapper = styled.div<{ colorCode?: string, selected: boolean, isHovering: boolean }>`
    width: 85%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 20px;
    gap: 8px;
    position: relative;
    border-radius: 5px;

    svg {
      max-width: 50px;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;

      @media (min-width: 2049px) {
        max-width: 75px;
      }

      @media (min-width: 3400px) {
        max-width: 100px;
      }
    }

    &::before {
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 999px;
      transform: translate(50%, -50%);
      top: 0;
      right: 0;
      background-color: ${(props) => props.colorCode};
      display: ${(props) => (props.colorCode ? "block" : "none")};
      position: absolute;
      opacity: ${(props) => (props.selected || props.isHovering ? "1" : "0")};
      transition: opacity 0.1s ease-out;

      @media (min-width: 2049px) {
        width: 2.0rem;
        height: 2.0rem;
      }

      @media (min-width: 3400px) {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    
    &.items-2 {
      padding: 14px;
      gap: 4px;
    }

    &.items-3 {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 14px;
      gap: 2px;

      div {
        &:first-child {
          width: 100% !important;
          height: 45% !important;
        }

        &:nth-child(2) {
          height: 45% !important;
          width: 45% !important;
        }

        &:nth-child(3) {          
          height: 45% !important;
          width: 45% !important;
        }
      }
      
      svg {
        width: 90%;
        height: 90%;
      }
    }

`;

const TextSpacer = styled.div`
  margin-top: 8px;
`;

const DisabeldLabel = styled.div`
    position: absolute;
    top: 5%;
    left: 8.1%;
    right: 8.1%;
    padding: 10px 0;
    border-radius: 5px 5px 0 0;
    background: #d4d4db;
    text-align: center;
    color: #fff;
    font-size: ${12 / 16}rem;
`;

export const IconTile = ({
  children,
  selected,
  count,
  text,
  disabled,
  onClick,
  colorCode,
}: IconTileProps) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Tile onClick={onClick} disabled={disabled}>
      {disabled &&
        <DisabeldLabel>{t("home.comingSoon" as any)}</DisabeldLabel>
      }
      <IconContainer selected={selected} disabled={disabled} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <IconListWrapper className={count ? `items-${count}` : ''} colorCode={!disabled ? colorCode : ''} selected={selected} isHovering={isHovering}>
          {children}
        </IconListWrapper>
      </IconContainer>
      <TextSpacer />
      <P>{text}</P>
    </Tile>
  );
};

import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/montserrat_400.woff2");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/montserrat_500.woff2");
  }

  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/roboto_400.woff2");
  }

  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/roboto_500.woff2");
  }

  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/roboto_700.woff2");
  }


  @font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/lato_700.woff2");
  }

  html {
    box-sizing: border-box;
    font-size: 100%;
  }

  html, body, main, #root {
    height: 100%;
  }

  @media only screen and (max-width: 813px) {
    html {
      font-size: 70%
    }
  }

  @media only screen and (min-width: 813px) {
    html {
      font-size: 85%
    }
  }

  @media only screen and (min-width: 1113px) {
    html {
      font-size: 100%
    }
  }

  // @media only screen and (min-width: 1441px) {
  //   html {
  //     font-size: 110%
  //   }
  // }

  // @media only screen and (min-width: 1921px) {
  //   html {
  //     font-size: 120%
  //   }
  // }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6, p, a {
    margin: 0;
  }
  
  button, a, button > div, #app-blocker {
    transition: 0.4s all;
  }

  a[href], input[type='submit'], input[type='image'], label[for], select, button, .pointer {
    cursor: pointer;
  }
`

export { GlobalStyle }

import React, { useRef } from "react";
import { usePreview } from "./preview.hooks";
import { EyeClosedIcon, EyeOpenIcon, } from "../common/Icons";
import { Intro } from "../viewer/DesignCenter";
import { H1 } from "../common/Typography";
import { Spacer } from "../common/Spacer";
import styled from "styled-components";
import { styles } from "../../styles";
import { OverviewItem } from "../common/OverviewItem";

const Preview = () => {
    const selectElement = useRef<any>(null);

    const {
        hasMarkers,
        markersVisible,
        activeModel,
        models,
        toggles,
        globalRenderer,
        handleLoadModel,
        handleToggle,
        handleToggleMaterial,
        handleToggleMarkers,
        handleToggleTransform,
        handleExportAllCombinations,
        // handleExportAllCombinationsAllModels,
        handleCameraSettings,
    } = usePreview();

    return (
        <SideSection>
            <script lang="javascript" src="dist/xlsx.full.min.js"></script>
            <Container>
                <Intro>
                    <H1>Previewing model:</H1>
                    <Spacer heightPx={12} />
                    <select name="layerType" id="layerType" value={activeModel} ref={selectElement} onChange={() => handleLoadModel(selectElement.current?.value)}>
                        {models.map((model: string) => (
                            <option value={model} key={model}>{model}</option>
                        ))}
                    </select>
                    <Spacer heightPx={12} />
                    <Spacer heightPx={40} />
                </Intro>
            </Container>


            <Container>
                <H1>Actions</H1>
                <Spacer heightPx={12} />
                <button onClick={() => handleCameraSettings()}>Log current camera settings</button>
                <Spacer heightPx={12} />
                <button onClick={() => handleToggleTransform()}>Toggle transform element</button>
                <Spacer heightPx={12} />
                <button onClick={() => handleExportAllCombinations()}>Export all model combinations (.xlsx)</button>
                {/* <Spacer heightPx={12} />
                <button onClick={() => handleExportAllCombinationsAllModels()}>Export combinations for all new models (.xlsx)</button> */}
                <Spacer heightPx={40} />
            </Container>

            {toggles.length > 0 && (
                <>
                    <Container>
                        <H1>Toggles</H1>
                        <Spacer heightPx={12} />
                    </Container>

                    {globalRenderer && toggles.map((toggle: any, index: number) => (
                        <div key={toggle.name}>
                            <Line />
                            <ToggleItem>
                                <InteractiveToggle
                                    onClick={() => handleToggle(index)}
                                    visible={toggle.visible}
                                    title={toggle.name}
                                    text={toggle.active ?? '/'}
                                />
                                {(toggle.options && toggle.options.length > 1) &&
                                    <select name="layerType" id="layerType" value={toggle.active} onChange={({ target }) => handleToggleMaterial(toggle.name, target.value)}>
                                        {toggle.options.map((option: any) => (
                                            <option value={option} key={option}>{option}</option>
                                        ))}
                                    </select>
                                }
                            </ToggleItem>
                            <Line />
                        </div>
                    ))}
                </>
            )}

            {hasMarkers &&
                <>
                    <Line />
                    <ToggleItem>
                        <InteractiveToggle
                            onClick={() => handleToggleMarkers()}
                            visible={markersVisible}
                            title={"Toggle markers"}
                            text={"MARKERS"}
                        />
                    </ToggleItem>
                    <Line />
                </>
            }
        </SideSection>
    );
};

export const SideSection = styled.section`
  z-index: 1;
  width: ${(props) => props.theme.designWidth(448)}px;
  min-width: 340px;
  height: 100%;
  background: ${styles.colors.white};
  border-top: 1px solid ${styles.colors.darkBlue5};
  padding-bottom: ${(props) => props.theme.designWidth(96)}px;
  overflow-y: auto;
  overflow-x: hidden;

  select {
    padding: 6px;
    border: none;
    background: ${styles.colors.darkBlue5};
    border-radius: ${(props) => props.theme.designWidth(2)}px;
    border: 1px solid ${styles.colors.formBorder};
  }

  @media screen and (pointer: coarse) {
    min-width: 100%;
  }
`;

const Container = styled.div`
  display: block;
  flex-direction: column;
  overflow-y: auto;
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${styles.colors.darkBlue10};
`;

const ToggleItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding-right: ${(props) => props.theme.designHeight(16)}px;
`;

const InteractiveToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  opacity: 1;

  @media screen and (pointer: coarse) {
    pointer-events: none;
  }
`;


const WidthContainer = styled.div`
  width: 85%;
  }
`;

const InteractiveToggle = ({ visible, onClick, title, text }: any) => {
    return (
        <InteractiveToggleContainer onClick={onClick}>
            {!visible ? <EyeClosedIcon /> : <EyeOpenIcon />}
            <Spacer widthPx={16} />
            <WidthContainer >
                <OverviewItem title={title} text={text} />
            </WidthContainer>
        </InteractiveToggleContainer >
    );
};

export { Preview };

import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { RadioCheckedIcon, RadioUncheckedIcon } from "./Icons";

const IconRadioContainer = styled.div<{ checked: boolean }>`
  cursor: pointer;
  position: relative;
  width: 100%;
  min-height: 45px;
  border: ${(props) =>
    props.checked
      ? `2px solid ${styles.colors.primary}`
      : `2px solid ${styles.colors.darkBlue30}`};
  border-radius: 8px;
  padding-left: ${(props) => props.theme.designWidth(15)}px;
  padding-right: ${(props) => props.theme.designWidth(15 + 32)}px;
  padding-top: ${(props) => props.theme.designHeight(15)}px;
  padding-bottom: ${(props) => props.theme.designHeight(15)}px;
  display: flex;
  transition: border 0.1s ease-out;

  @media screen and (pointer: coarse) {
    padding-left: 15px;
    padding-right: ${15 + 32}px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  ${(props) => {
    if (!props.checked) {
      return `
        &:hover {
          border: 2px solid ${styles.colors.darkBlue75};
      `;
    }
  }}
`;

const IconRadioContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 8px;
`;

export const IconRadioButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (pointer: coarse) {
    gap: 12px;
  }
  
  .icon {
    width: 100%;
    max-width: 28px;
    height: 28px;
    
    svg {
      width: 100%;
      height: 100%;
    }
  }
      
  .label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 400;
    font-size: ${14 / 16}rem;
    line-height: 1.2rem;
    color: ${styles.colors.dark2};

    @media screen and (pointer: coarse) {
      font-size: ${1.3}rem;
      line-height: 1.8rem;
    }
  }
`;

const IconRadioIcon = styled.div<{ count?: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: ${(props) => props.theme.designWidth(15)}px;
  top: ${(props) => props.count === 1 ? '50%' : props.theme.designWidth(15) + 'px'};
  transform: ${(props) => props.count === 1 ? 'translateY(-50%)' : 'none'};
  
  @media screen and (pointer: coarse) {
    right: 15px;
    top: ${(props) => props.count === 1 ? '50%' : '15px'};
    transform: ${(props) => props.count === 1 ? 'translateY(-50%)' : 'none'};
  }
`;

type IconRadioButtonProps = {
  checked: boolean;
  onClick: (value: any) => void;
  children?: React.ReactNode;
  count?: number;
};

export const IconRadioButton = ({
  checked,
  onClick,
  children,
  count
}: IconRadioButtonProps) => {
  return (
    <IconRadioContainer checked={checked} onClick={onClick}>
      <IconRadioContent>
        {children}
      </IconRadioContent>
      <IconRadioIcon count={count}>
        {checked && <RadioCheckedIcon />}
        {!checked && <RadioUncheckedIcon />}
      </IconRadioIcon>
    </IconRadioContainer>
  );
};

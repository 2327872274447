import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";

const CreditContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 23px;
  z-index: 6;

  a {
    font-size: ${11 / 16}rem;
    line-height: ${22 / 16}rem;
    color: ${styles.colors.darkBlue75};
    text-decoration: none;

    strong {
      transition: color 0.2s ease;
    }
  }

  a:hover strong {
    color: ${styles.colors.darkBlue};
  }

  @media screen and (pointer: coarse) {
    bottom: 10px;
    left: 16px;

    a {
      font-size: ${14 / 16}rem;
      line-height: ${22 / 16}rem;
    }
  }
`;

export const ViewerCredit = () => {
  return (
    <CreditContainer>
      <a href="https://poppr.be/en" target="_blank" rel="noreferrer noopener">
        {"Powered by "}
        <strong>Poppr</strong>
      </a>
    </CreditContainer>
  );
};

import React, { ReactNode, SyntheticEvent } from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { P } from "../common/Typography";
import { useApplication } from "../../store/application/hooks";

type SidebarProps = {
  children: ReactNode;
  handleNextButtonClick: () => void;
  nextButtonText: string;
  nextButtonDisabled: boolean;
  nextButtonHidden?: boolean;
};

export const Section = styled.section`
  z-index: 1;
  width: ${(props) => props.theme.designWidth(448)}px;
  min-width: 340px;
  height: 100%;
  background: ${styles.colors.white};
  border-top: 1px solid ${styles.colors.darkBlue5};

  @media screen and (pointer: coarse) {
    min-width: 100%;
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Padding = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: ${(props) => props.theme.designHeight(40)}px;
  padding-bottom: ${(props) => props.theme.designHeight(40)}px;
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;

  @media screen and (pointer: coarse) {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
`;

export const Sidebar = ({
  children,
  handleNextButtonClick,
  nextButtonDisabled,
  nextButtonText,
  nextButtonHidden = false
}: SidebarProps) => {
  return (
    <Section>
      <Container>
        <Padding>{children}</Padding>
        {!nextButtonHidden &&
          <NextButton
            handleClick={handleNextButtonClick}
            text={nextButtonText}
            disabled={nextButtonDisabled}
          />}
      </Container>
    </Section>
  );
};

type NextButtonProps = {
  text: string;
  disabled: boolean;
  handleClick: () => void;
};

const Button = styled.button<{ disabled: boolean }>`
  &:hover {
    background: ${(props) => !props.disabled && styles.colors.primaryNonHover};
    background-position: center center;
    //background: ${styles.colors.buttonHover};
  }

  &.clicked {
    background-image: url("/assets/images/pulse.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 1000%;
  }

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 100%;
  padding-top: ${(props) => props.theme.designHeight(16)}px;
  padding-bottom: ${(props) => props.theme.designHeight(16)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.disabled ? styles.colors.dark2Opacity50 : styles.colors.primary};
  transition: background-color 0.1s ease-out;
  border: none;

  @media screen and (pointer: coarse) {
    position: fixed;
    // position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: 24px;

    p {
      font-size: 20px;
      line-height: 23px;
    }
  }
`;

const NextButton = ({ text, disabled, handleClick }: NextButtonProps) => {
  // eslint-disable-next-line
  const { modelView, setCurrentView } = useApplication();

  function onClick(e: SyntheticEvent) {
    if (!disabled) {
      setCurrentView(false)
      handleClick();
    }
  }

  return (
    <Button disabled={disabled} onClick={onClick}>
      <P
        fontWeight={500}
        fontSizePx={14}
        lineHeightPx={16}
        color={styles.colors.white}
      >
        {text}
      </P>
    </Button>
  );
};

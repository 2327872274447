import React from "react";
import styled from "styled-components";
import { styles } from "../../../styles";
import {
  CalculatorEuroIcon,
  ClipboardIcon,
  DownloadIcon,
  EyeClosedIcon,
  EyeOpenIcon,
} from "../../common/Icons";
import { OverviewItem, OverviewItemProps } from "../../common/OverviewItem";
import { Spacer } from "../../common/Spacer";
import { ThermalPerformance } from "../../common/ThermalPerformance";
import { H1, H2, P } from "../../common/Typography";
import { UnstyledButton } from "../../common/UnstyledButton";
import { useOverview } from "./overview.hooks";
import {
  getSingleSecuringMethodTranslations,
  getRoofClimateClassTranslations,
  getRoofFloorTranslations,
  getRoofTypeTranslations,
} from "./translations";
import { Section } from ".././Sidebar";
import { Models } from "../../viewer/renderer.api";
import { useApplication } from "../../../store/application/hooks";
import { Intro } from "../../viewer/DesignCenter";
import { isMobile } from "../../../services/helpers.service";
import { RoofTypeTypes, SecuringMethodTypes } from "@iko-design-center/shared";

const Container = styled.div`
  height: 100%;
  display: block;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (pointer: coarse) {
    padding-bottom: 128px;
  }
`;

const Padding = styled.div`
  padding-left: ${(props) => props.theme.designWidth(40)}px;
  padding-right: ${(props) => props.theme.designWidth(40)}px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${styles.colors.darkBlue10};
`;

const AdjustSpecificationsContainer = styled.div`
  height: ${(props) => props.theme.designHeight(48)}px;
  width: 100%;
  background: ${styles.colors.darkBlue10};
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    text-decoration: underline;
  }
`;

const ButtonIcon = styled.div`
  width: 18px;
`;

const ThermalPerformaceBlock = styled.div`
  @media screen and (pointer: coarse) {
    h2 {
      font-size: 14px;
      line-height: 20px;
    }

    button p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const Overview = () => {
  const {
    t,
    linkCopied,
    thermalPerformance,
    visibleState,

    finishing,
    topLayer,
    bottomLayer,
    insulation,
    vaporShield,
    roofFloor,
    roofType,

    handleToggleClick,
    handleDetailsClick,

    copyShareLink,
    displayRequestAdviceModal,
    displayFlatRoofDownloadModal,
    displayFlatRoofThermalCalculationModal,
  } = useOverview();
  const { currentModel } = useApplication();
  let shouldShow =
    currentModel !== Models.DOLLHOUSE &&
    currentModel !== Models.FLAT_ROOF &&
    currentModel !== Models.FLAT_ROOF_ATELIA &&
    currentModel !== Models.FLAT_ROOF_E_CIRCULAIR &&
    currentModel !== Models.FLAT_ROOF_FLAME_FREE &&
    currentModel !== Models.FLAT_ROOF_RETENTION;

  const topLayerToggles = ["TOPLAAG", "TOPLAAG-BEVESTIGING", "PROFIEL"]
  if ((roofType.type === RoofTypeTypes.ENERGY_ROOF || roofType.type === RoofTypeTypes.GREEN_ROOF || roofType.type === RoofTypeTypes.LIVING_ROOF || roofType.type === RoofTypeTypes.RETENTION_ROOF) && !finishing) {
    topLayerToggles.push("DAKBEDEKKING")
  }

  // @ts-ignore
  return (
    <Section>
      <Container>
        {!isMobile() ? (
          <Intro>
            <Spacer heightPx={40} />
            <Padding>
              <H1>{t("flatRoof.overview.title")}</H1>
            </Padding>
            <Spacer heightPx={12} />
            <Padding>
              <P fontSizePx={14} lineHeightPx={20} color={styles.colors.dark2}>
                {t("flatRoof.overview.subtitle")}
              </P>
            </Padding>
            <Spacer heightPx={20} />
          </Intro>
        ) : (
          <Intro>
            <H1>{t("flatRoof.overview.title")}</H1>
            <Spacer heightPx={12} />
            <P fontSizePx={14} lineHeightPx={20} color={styles.colors.dark2}>
              {t("flatRoof.overview.mobile.subtitle" as any)}
            </P>
          </Intro>
        )}
        <Line />

        {/* Roof type */}
        {(roofType && !shouldShow) && <>
          <StaticOverviewItem
            title={t("navigation.flatRoof.roofType").toUpperCase()}
            text={getRoofTypeTranslations(t, roofType.type)}
            suffix={getRoofClimateClassTranslations(t, roofType.climateClass)}
            disabled={false}
          />
          <Line />
        </>}

        {/* Finishing */}
        {(finishing && !shouldShow) && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("finishing", ["DAKBEDEKKING"])}
            visible={visibleState.finishing}
            title={t("flatRoof.types.securingMethods.looseBallast").toUpperCase()}
            text={t("flatRoof.types.securingMethods.looseBallast")}
            suffix={getSingleSecuringMethodTranslations(t, SecuringMethodTypes.LOOSE)}
            disabled={false}
          />
          <Line />
        </>}

        {/* Top layer */}
        {topLayer && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("topLayer", topLayerToggles)}
            visible={visibleState.topLayer}
            title={t("flatRoof.overview.topLayer").toUpperCase()}
            text={t('material.'.concat(topLayer.type) as any)}
            suffix={getSingleSecuringMethodTranslations(t, topLayer.method)}
            disabled={false}
          />
          <Line />
        </>}

        {/* Bottom layer */}
        {bottomLayer && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("bottomLayer", ["ONDERLAAG", "ONDERLAAG-BEVESTIGING"])}
            visible={visibleState.bottomLayer}
            title={t("flatRoof.overview.bottomLayer").toUpperCase()}
            text={t('material.'.concat(bottomLayer.type) as any)}
            suffix={getSingleSecuringMethodTranslations(t, bottomLayer.method)}
            disabled={false}
          />
          <Line />
        </>}

        {/* Insulation */}
        {insulation && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("insulation", ["ISOLATIE"])}
            visible={visibleState.insulation}
            title={t("flatRoof.overview.insulation").toUpperCase()}
            text={t('material.'.concat(insulation.type) as any)}
            suffix={getSingleSecuringMethodTranslations(t, insulation.method)}
            disabled={false}
          />
          <Line />
        </>}

        {/* Vapor shield */}
        {vaporShield && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("vaporShield", ["DAMPSCHERM", "DAMPSCHERM-BEVESTIGING"])}
            visible={visibleState.vaporShield}
            title={t("flatRoof.overview.vaporShield").toUpperCase()}
            text={t('material.'.concat(vaporShield.type) as any)}
            suffix={getSingleSecuringMethodTranslations(t, vaporShield.method)}
            disabled={false}
          />
          <Line />
        </>}

        {/* Roof floor */}
        {roofFloor && <>
          <InteractiveOverviewItem
            onClick={() => handleToggleClick("roofFloor", ["ONDERGROND", "MUUR", "VENTILATIE", "AFVOER", "KOEPEL", "ZETTINGSVOEG"])}
            visible={visibleState.roofFloor}
            title={t("flatRoof.overview.roofFloor").toUpperCase()}
            text={getRoofFloorTranslations(t, roofFloor.type)}
            disabled={false}
          />
          <Line />
        </>}

        {/* Details */}
        {!shouldShow && (
          <InteractiveOverviewItem
            onClick={handleDetailsClick}
            visible={visibleState.details}
            title={t("flatRoof.overview.roofDetailsTitle").toUpperCase()}
            text={t("flatRoof.overview.roofDetailsText")}
            disabled={false}
          />
        )}

        <Line />
        <Spacer heightPx={24} />
        <Padding>
          <ThermalPerformaceBlock>
            <H2 fontWeight={500} fontSizePx={14} lineHeightPx={20}>
              {t("flatRoof.overview.thermalPerformance")}
            </H2>
            <Spacer heightPx={8} />
            <ThermalPerformance
              insulationThickness={thermalPerformance.insulation.thickness!}
              UCValue={thermalPerformance.UCValue!}
              RTValue={thermalPerformance.RTValue!}
              background={true}
            />
            <AdjustSpecificationsContainer
              onClick={() => displayFlatRoofThermalCalculationModal()}
            >
              <UnstyledButton>
                <P fontWeight={500} fontSizePx={13}>
                  {t("flatRoof.overview.adjustSpecifications")}
                </P>
              </UnstyledButton>
            </AdjustSpecificationsContainer>
          </ThermalPerformaceBlock>
          <Spacer heightPx={24} />
          <DownloadButton
            onClick={() => displayFlatRoofDownloadModal()}
            className={"download-button"}
          >
            <P fontSizePx={14} color={styles.colors.white}>
              {t("flatRoof.overview.downloadFiles")}
            </P>
            <ButtonIcon>
              <DownloadIcon />
            </ButtonIcon>
          </DownloadButton>
          <Spacer heightPx={8} />
          <AdviceButton
            onClick={() => displayRequestAdviceModal()}
            className={"advice-button"}
          >
            <P fontSizePx={14} color={styles.colors.primary}>
              {t("flatRoof.overview.requestAdvice")}
            </P>
            <ButtonIcon>
              <CalculatorEuroIcon />
            </ButtonIcon>
          </AdviceButton>
          <Spacer heightPx={8} />
          <ShareButton
            onClick={() => copyShareLink()}
            className={"advice-button"}
          >
            {
              !linkCopied
                ?
                <P fontSizePx={14} color={styles.colors.primary}>
                  {t("flatRoof.overview.shareConfiguration")}
                </P>
                :
                <P fontSizePx={14} color={styles.colors.primary}>
                  {t("flatRoof.overview.linkCopied")}
                </P>
            }
            <ButtonIcon>
              <ClipboardIcon checked={linkCopied} />
            </ButtonIcon>
          </ShareButton>
          <Spacer heightPx={40} />
        </Padding>
      </Container>
    </Section >
  );
};

const DownloadButton = styled.button`
  &:hover {
    background: ${styles.colors.primaryNonHover};
  }

  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  padding-left: ${(props) => props.theme.designWidth(16)}px;
  padding-right: ${(props) => props.theme.designWidth(29.2)}px;
  width: 100%;
  background: ${styles.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border-width: 0;

  @media screen and (pointer: coarse) {
    &.download-button {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${styles.colors.primary};
      border: none;
      border-radius: unset;
      padding: 24px 0;
      gap: 32px;

      * {
        font-size: 20px;
        line-height: 23px;
      }

      svg {
        margin-top: 6px;
      }
    }

    &.advice-button {
      padding: 16px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      * {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`;

const AdviceButton = styled(DownloadButton)`
  &:hover {
    border: 1px solid ${styles.colors.primaryNonHover};
    background: ${styles.colors.primaryNonHover};

    p {
      color: ${styles.colors.white};
    }

    svg > path {
      fill: ${styles.colors.white};
    }
  }

  border: 1px solid ${styles.colors.primaryNonHover};
  background: ${styles.colors.white};
`;

const ShareButton = styled(DownloadButton)`
  &:hover {
    border: 1px solid ${styles.colors.primaryNonHover};
    background: ${styles.colors.primaryNonHover};

    p {
      color: ${styles.colors.white};
    }

    svg > path {
      stroke: ${styles.colors.white};
    }
  }

  border: 1px solid ${styles.colors.primaryNonHover};
  background: ${styles.colors.white};
`;

type OverviewMaterialItemProps = {
  visible: boolean;
  onClick: () => void;
  disabled: boolean;
} & OverviewItemProps;

const OverviewItemContainer = styled.div<{ disabled: boolean, static: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.static ? 'default' : props.disabled ? `not-allowed` : `pointer`)};
  width: 100%;
  padding-top: ${(props) => props.theme.designHeight(14)}px;
  padding-bottom: ${(props) => props.theme.designHeight(14)}px;
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};

  @media screen and (pointer: coarse) {
    pointer-events: none;
  }
`;

const WidthContainer = styled.div<{ suffix: string | undefined }>`
  width: 85%;

  @media screen and (pointer: coarse) {
    padding: 0 16px;
    width: 100%;

    * {
      font-size: 16px;
      line-height: 24px;

      p {
        margin-top: 0;

        &:first-of-type {
          padding-right: 6px;
          margin-right: 6px;
          border-right: ${(props) => props.suffix ? `1px solid ${styles.colors.primary}` : ""};
        }
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    h2 {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const InteractiveOverviewItem = ({
  visible,
  onClick,
  title,
  text,
  suffix,
  disabled,
}: OverviewMaterialItemProps) => {
  return (
    <OverviewItemContainer
      onClick={() => (!disabled ? onClick() : null)}
      disabled={disabled}
      static={false}
    >
      {!isMobile() && (!visible ? <EyeClosedIcon /> : <EyeOpenIcon />)}
      {!isMobile() && <Spacer widthPx={16} />}
      <WidthContainer suffix={suffix}>
        <OverviewItem title={title} text={text} suffix={suffix} />
      </WidthContainer>
    </OverviewItemContainer>
  );
};

const StaticOverviewItem = ({
  title,
  text,
  suffix,
  disabled,
}: any) => {
  return (
    <OverviewItemContainer
      disabled={disabled}
      static={true}
    >
      {!isMobile() && <EyeClosedIcon hidden={true} />}
      {!isMobile() && <Spacer widthPx={16} />}
      <WidthContainer suffix={suffix}>
        <OverviewItem title={title} text={text} suffix={suffix} />
      </WidthContainer>
    </OverviewItemContainer>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { resizeImage } from "../../../services/screenshot.service"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { useModal } from "../../../store/modal/hooks"
import { renderer } from "../../viewer/Renderer"
import { useApplication } from "../../../store/application/hooks"
import { isMobile } from "../../../services/helpers.service"
import { useMatrix } from "./matrix.hook"
import { RoofFloorTypes } from "@iko-design-center/shared"

type VisibleState = {
  finishing: boolean
  topLayer: boolean
  bottomLayer: boolean
  insulation: boolean
  vaporShield: boolean
  roofFloor: boolean
  roofType: boolean
  details: boolean
}

const modelLayers: any = [
  {
    key: "topLayer",
    type: "TOPLAAG",
    method: "TOPLAAG-BEVESTIGING",
  },
  {
    key: "bottomLayer",
    type: "ONDERLAAG",
    method: "ONDERLAAG-BEVESTIGING",
  },
  {
    key: "insulation",
    type: "ISOLATIE",
    method: "ISOLATIE-BEVESTIGING",
  },
  {
    key: "vaporShield",
    type: "DAMPSCHERM",
    method: "DAMPSCHERM-BEVESTIGING",
  },
  {
    key: "roofFloor",
    type: "ONDERGROND",
    method: null,
  },
  {
    key: "roofType",
    type: "DAKBEDEKKING",
    method: null,
  },
]

export function useOverview() {
  const {
    displayDefaultFlatRoofCombination,
    appendFlatRoofStateToUrl,
  } = useMatrix()
  const { t } = useTranslation() as any
  const [linkCopied, setLinkCopied] = useState<boolean>(false)
  const {
    configurationState,
    configurationState: { roofFloor, roofType, waterproofing },
    thermalPerformance,
    setOverviewScreenshot,
    setHumanReadableName,
  } = useFlatRoof()

  const {
    finishing,
    topLayer,
    bottomLayer,
    insulation,
    vaporShield,
  } = configurationState.securingMethods

  const {
    displayFlatRoofDownloadModal,
    displayFlatRoofThermalCalculationModal,
    displayRequestAdviceModal,
  } = useModal()
  const { currentModel, applicationType } = useApplication()

  const initialVisibleState = useMemo(
    () => ({
      finishing: true,
      topLayer: true,
      bottomLayer: true,
      insulation: true,
      vaporShield: true,
      roofFloor: true,
      roofType: true,
      details: true,
    }),
    []
  )
  const [visibleState, setVisibleState] = useState<VisibleState>(
    initialVisibleState
  )

  const humanReadableName = `${roofType.type} / ${roofType.climateClass} / ${roofFloor.type} / ${waterproofing.type}`
  useEffect(() => {
    setHumanReadableName(humanReadableName)
  }, [humanReadableName, setHumanReadableName])

  useEffect(
    () => {
      if (!isMobile()) renderer.displayMarkers()
      const dataURL = renderer.createScreenshot()
      resizeImage(dataURL, 520, 520).then((resizedDataURL) => {
        setOverviewScreenshot(resizedDataURL)
      })

      return () => {
        renderer.hideMarkers()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOverviewScreenshot]
  )

  useEffect(() => {
    setVisibleState(initialVisibleState)
    if (!isMobile()) renderer.displayMarkers()

    displayDefaultFlatRoofCombination({
      displayModel: currentModel,
      displayMarkers: true,
    })
    appendFlatRoofStateToUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    roofType,
    roofFloor,
    applicationType,
    currentModel,
    setVisibleState,
    initialVisibleState,
  ])

  function toggleVisibleState(key: keyof VisibleState) {
    setVisibleState({
      ...visibleState,
      [key]: !visibleState[key],
    })
  }

  function handleToggleClick(toggle: string = "topLayer", layers: any = []) {
    const layerKey = toggle as keyof VisibleState
    const newState = !visibleState[layerKey]
    toggleVisibleState(layerKey)
    layers.forEach((layer: string) => renderer.toggleLayers(layer, newState))

    if (
      newState &&
      toggle === "roofFloor" &&
      layers.includes("ONDERGROND") &&
      (roofFloor.type === RoofFloorTypes.WOOD ||
        roofFloor.type === RoofFloorTypes.STEEL_DECK)
    ) {
      renderer.displayRoofFloor(roofFloor.type)
    }

    // Toggle visible states in marker
    const modelLayer = modelLayers.find((x: any) => x.key === toggle)
    if (modelLayer && modelLayer.type) {
      // Layers markers
      document
        .querySelectorAll(
          `.marker-type-layers[data-layertype="${modelLayer.type}"] [data-toggle]`
        )
        .forEach((toggleEl) =>
          toggleEl.classList[newState ? "add" : "remove"]("is-active")
        )
      // Toggle markers
      document
        .querySelectorAll(
          `.marker-type-toggle[data-layertype="${modelLayer.type}"]`
        )
        .forEach((toggleEl) =>
          toggleEl.classList[!newState ? "add" : "remove"]("-closed")
        )
    }
  }

  function handleDetailsClick() {
    toggleVisibleState("details")
    renderer.toggleDetails()
  }

  const handleMarkerToggle = useCallback(
    ({ toggle, layerType, marker }: any) => {
      const layerElements = marker.markerElement.querySelectorAll(
        "[data-toggle]"
      )
      const clickedLayer = marker.markerElement.querySelector(
        `[data-toggle="${toggle}"]`
      )
      const markerTogglevisibleState = clickedLayer.classList.contains(
        "is-active"
      )
      clickedLayer.classList[!markerTogglevisibleState ? "add" : "remove"](
        "is-active"
      )
      const modelLayer = modelLayers.find((x: any) => x.type === layerType)

      // Type
      renderer.instance.toggle(toggle, !markerTogglevisibleState, layerType)

      // Securing method
      if (modelLayer.method) {
        const index = toggle.split("_")[1]
        const methodToggle = `${modelLayer.method}_${index}`
        renderer.instance.toggle(
          methodToggle,
          !markerTogglevisibleState,
          modelLayer.method
        )
      }

      // Toplayer profile
      if (layerType === "TOPLAAG" && toggle.split("_")[1]) {
        const index = toggle.split("_")[1]
        const state = !markerTogglevisibleState
        renderer.toggleTopLayerProfile(currentModel, index, state)
      }

      // Toggle visible state in sidebar
      const visibleStateToggle = modelLayer.key as keyof VisibleState
      const activeToggleLength = Array.from(layerElements).filter((x: any) =>
        x.classList.contains("is-active")
      ).length
      if (visibleStateToggle) {
        setVisibleState({
          ...visibleState,
          [visibleStateToggle]: activeToggleLength !== 0,
        })
      }
    },
    [setVisibleState, visibleState, currentModel]
  )

  const handleToggleMarkerClick = useCallback(
    (marker: any) => {
      if (!marker.slug) return
      if (!marker.layerType) return
      handleToggleClick(
        marker.slug,
        Array.isArray(marker.layerType) ? marker.layerType : [marker.layerType]
      )
      const oldVisibilityState = visibleState[marker.slug as keyof VisibleState]
      marker.markerNode.classList[oldVisibilityState ? "add" : "remove"](
        "-closed"
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleState]
  )

  useEffect(() => {
    if (renderer.instance) {
      renderer.instance.events.on("MARKER_TOGGLE", handleMarkerToggle)
      renderer.instance.events.on(
        "TOGGLE_MARKER_CLICK",
        handleToggleMarkerClick
      )
    }
    return () => {
      renderer.instance.events.off("MARKER_TOGGLE", handleMarkerToggle)
      renderer.instance.events.off(
        "TOGGLE_MARKER_CLICK",
        handleToggleMarkerClick
      )
    }
  }, [handleMarkerToggle, handleToggleMarkerClick])

  const copyShareLink = () => {
    const link = window.location.href
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(link).then(() => setLinkCopied(true))
    } else {
      window.prompt(t("flatRoof.overview.shareConfiguration"), link)
      setLinkCopied(true)
    }
  }

  return {
    t,
    linkCopied,
    thermalPerformance,
    visibleState,

    finishing,
    topLayer,
    bottomLayer,
    insulation,
    vaporShield,
    roofFloor,
    roofType,

    handleToggleClick,
    handleDetailsClick,

    copyShareLink,
    displayRequestAdviceModal,
    displayFlatRoofDownloadModal,
    displayFlatRoofThermalCalculationModal,
  }
}

import { useMemo, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import * as XLSX from "xlsx"
import { renderer } from "../viewer/Renderer"
import { previewModels } from "../../services/preview.service"

const exportModels = [
  // "platdak-binnenhoek",
  "platdak-binnenhoek_flamefree",
  "platdak-binnenhoek_retention",
  "platdak-binnenhoek-atelia",
  "platdak-binnenhoek-e-circulair",
  // "platdak-buitenhoek",
  "platdak-buitenhoek_e-circulair",
  "platdak-buitenhoek_flamefree",
  "platdak-buitenhoek_retention",
  "platdak-buitenhoek-atelia",
  // "platdak-dakdoorvoer",
  "platdak-dakdoorvoer_e-circulair",
  "platdak-dakdoorvoer_flamefree",
  "platdak-dakdoorvoer_retention",
  "platdak-dakdoorvoer-atelia",
  // "platdak-dakopstand",
  "platdak-dakopstand_e-circulair",
  "platdak-dakopstand_flamefree",
  "platdak-dakopstand_retention",
  "platdak-dakopstand-atelia",
  // "platdak-dakopstand-horizontal",
  "platdak-dakopstand-horizontal_e-circulair",
  "platdak-dakopstand-horizontal_flamefree",
  "platdak-dakopstand-horizontal_retention",
  "platdak-dakopstand-horizontal-atelia",
  // "platdak-dakopstand-vertical",
  "platdak-dakopstand-vertical_e-circulair",
  "platdak-dakopstand-vertical_flamefree",
  "platdak-dakopstand-vertical_retention",
  "platdak-dakopstand-vertical-atelia",
  // "platdak-dakvlak",
  "platdak-dakvlak_e-circulair",
  "platdak-dakvlak_flamefree",
  "platdak-dakvlak_retention",
  "platdak-dakvlak-atelia",
  // "platdak-koepel",
  "platdak-koepel_e-circulair",
  "platdak-koepel_flamefree",
  "platdak-koepel_retention",
  "platdak-koepel-atelia",
  // "platdak-opgaandeMuur-brick",
  "platdak-opgaandeMuur-brick_e-circulair",
  "platdak-opgaandeMuur-brick_flamefree",
  "platdak-opgaandeMuur-brick_retention",
  "platdak-opgaandeMuur-brick-atelia",
  // "platdak-opgaandeMuur-concrete",
  "platdak-opgaandeMuur-concrete_e-circulair",
  "platdak-opgaandeMuur-concrete_flamefree",
  "platdak-opgaandeMuur-concrete_retention",
  "platdak-opgaandeMuur-concrete-atelia",
  // "platdak-overview",
  "platdak-overview_e-circulair",
  "platdak-overview_flamefree",
  "platdak-overview_retention",
  "platdak-overview-atelia",
  // "platdak-waterafvoer-horizontal",
  "platdak-waterafvoer-horizontal_e-circulair",
  "platdak-waterafvoer-horizontal_flamefree",
  "platdak-waterafvoer-horizontal_retention",
  "platdak-waterafvoer-horizontal-atelia",
  // "platdak-waterafvoer-vertical",
  "platdak-waterafvoer-vertical_e-circulair",
  "platdak-waterafvoer-vertical_flamefree",
  "platdak-waterafvoer-vertical_retention",
  "platdak-waterafvoer-vertical-atelia",
  // "platdak-zettingsvoeg",
  "platdak-zettingsvoeg_e-circulair",
  "platdak-zettingsvoeg_flamefree",
  "platdak-zettingsvoeg_retention",
  "platdak-zettingsvoeg-atelia",
]

export function usePreview() {
  const { i18n } = useTranslation()
  const country = i18n.language.split("_")[1]
  const [activeModel, setActiveModel] = useState("dollhouse")
  const [hasMarkers, setHasMarkers] = useState(false)
  const [markersVisible, setMarkersVisible] = useState(false)
  const [models, setModels] = useState(previewModels[country])
  const [toggles, setToggles] = useState([]) as any

  const global = window as any
  const globalRenderer = useMemo(() => global.renderer, [global.renderer])

  const handleLoadModel = (id: string) => {
    renderer.displayMarkers()
    renderer?.loadPreviewModel(id).then(() => {
      setActiveModel(() => id)
      const modelHasMarkers =
        renderer.getPreviewModelMarkerInstances().length > 0
      setHasMarkers(() => modelHasMarkers)
      setMarkersVisible(() => true)
      renderer.displayMarkers()
    })
  }

  useEffect(() => {
    if (!globalRenderer || !globalRenderer.toggles) return
    const toggles = parseRendererToggles(globalRenderer)
    setToggles(toggles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModel, globalRenderer])

  function handleToggle(index: number) {
    const toggle = toggles[index]
    let newToggles = [...toggles]
    newToggles[index] = { ...toggle, visible: !toggle.visible }
    setToggles(newToggles)
    renderer.toggleLayer(toggle.name, !toggle.visible)
  }

  function handleToggleMaterial(layerType: string, value: string) {
    renderer.setLayer(layerType, value)

    const toggleIndex = toggles.findIndex((t: any) => t.name === layerType)
    if (toggleIndex !== -1) {
      let newToggles = [...toggles]
      newToggles[toggleIndex] = {
        ...newToggles[toggleIndex],
        active: value,
        visible: true,
      }
      renderer.toggleLayers(layerType, true)
      setToggles(newToggles)
    }
  }

  function handleToggleMarkers() {
    setMarkersVisible(() => !markersVisible)
    renderer.toggleMarkers()
  }

  function handleToggleTransform() {
    setMarkersVisible(() => !markersVisible)
    renderer.togglePreviewTransform()
  }

  function handleCameraSettings() {
    const [camera, model] = renderer.getPreviewView()

    const cameraSettings = {
      position: {
        x: camera?.position.x,
        y: camera?.position.y,
        z: camera?.position.z,
      },
      rotation: {
        x: camera?.rotation.x,
        y: camera?.rotation.y,
        z: camera?.rotation.z,
      },
    }

    const modelSettings = {
      position: {
        x: model?.position.x,
        y: model?.position.y,
        z: model?.position.z,
      },
    }

    console.log("# Current view:", cameraSettings)
    console.log("# Current model:", modelSettings)
  }

  function handleExportAllCombinations() {
    if (!toggles.length) return
    const fileName = `${activeModel}.xlsx`
    const headerRow = toggles.map((toggle: any) => toggle.name)
    const combinationRows = generateCombinations(toggles)
    combinationRows.unshift(headerRow)

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.aoa_to_sheet(combinationRows)
    XLSX.utils.book_append_sheet(workBook, workSheet, "Combinations")
    XLSX.writeFile(workBook, fileName)

    console.log(
      `Successfully exported ${combinationRows.length - 1} combinations to`,
      fileName
    )
  }

  async function handleExportAllCombinationsAllModels() {
    const currentModal = activeModel

    const fileName = `models-combinations.xlsx`
    const exportWorkBook = XLSX.utils.book_new()
    for (const model of exportModels) {
      handleLoadModel(model)
      await new Promise((resolve) => setTimeout(resolve, 2500))

      const worksheetName = model
        .replace("platdak-", "")
        .replace("horizontal", "h")
        .replace("vertical", "v")
        .replace("opgaandeMuur", "muur")

      const exportToggles = parseRendererToggles(global.renderer)
      if (!exportToggles.length) return
      const headerRow = exportToggles.map((toggle: any) => toggle.name)
      const combinationRows = generateCombinations(exportToggles)
      combinationRows.unshift(headerRow)
      const workSheet = XLSX.utils.aoa_to_sheet(combinationRows)
      XLSX.utils.book_append_sheet(exportWorkBook, workSheet, worksheetName)
    }

    XLSX.writeFile(exportWorkBook, fileName)
    handleLoadModel(currentModal)
  }

  function generateCombinations(
    layers: any,
    index = 0,
    currentCombo: any = [],
    allCombos: any = []
  ) {
    if (index === layers.length) {
      allCombos.push([...currentCombo])
      return
    }

    const layer = layers[index]
    let options = ["/"]
    if (layer.options && layer.options.length) options = layer.options

    options.forEach((option) => {
      currentCombo[index] = option
      generateCombinations(layers, index + 1, currentCombo, allCombos)
    })

    if (index < currentCombo.length) currentCombo[index] = undefined
    if (index === 0) return allCombos
  }

  function parseRendererToggles(renderer: any) {
    return renderer.toggles.map((toggle: any) => {
      const toggleName = toggle

      const toggleLayerOptions = renderer.layersTypes[toggleName]
      const singleLayerOption = toggleLayerOptions?.length <= 1

      let toggleLayerVisible = singleLayerOption
        ? true
        : renderer.settings.toggles.find((t: any) => t.name === toggle)?.default

      let toggleActiveLayer = renderer.settings.layers.find(
        (l: any) => l.name === toggle
      )?.default

      if (toggleActiveLayer && toggleLayerOptions.length > 0) {
        if (!toggleLayerOptions.includes(toggleActiveLayer)) {
          renderer.setLayer(toggleName, toggleLayerOptions[0])
          toggleActiveLayer = toggleLayerOptions[0]
          toggleLayerVisible = true
        }
      }

      return {
        name: toggleName,
        visible: toggleLayerVisible,
        active: toggleActiveLayer,
        options: toggleLayerOptions,
      }
    })
  }

  return {
    hasMarkers,
    markersVisible,
    activeModel,
    models,
    toggles,
    globalRenderer,
    setModels,
    handleLoadModel,
    handleToggle,
    handleToggleMaterial,
    handleToggleMarkers,
    handleToggleTransform,
    handleCameraSettings,
    handleExportAllCombinations,
    handleExportAllCombinationsAllModels,
  }
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isMobile } from "../../services/helpers.service";
import { ApplicationTypes } from "../../store/application/store";
import { styles } from "../../styles";
import { Spacer } from "../common/Spacer";
import { P } from "../common/Typography";
import { ApplicationOption, useHome } from "../sidebar/home.hooks";
import { renderer } from "./Renderer";
import { Models } from "./renderer.api";
import { useRoutes } from "../../store/routes/hooks";
import { useLocation } from "react-router";
import { FloorIcon } from "../common/Icons";
import { useFlatRoof } from "../../store/flat-roof/hooks";
import { RoofTypeTypes } from "@iko-design-center/shared";

type detailOption = {
  model: Models;
  translationKey: string;
  rendererCall?: () => void;
};

// FLAT ROOF
const flatRoofDetailOptions: detailOption[] = [
  {
    model: Models.ANGLE_CONNECTION,
    translationKey: "roofDetails.angleConnectionInside",
    rendererCall: () => renderer.displayModel(Models.ANGLE_CONNECTION),
  },
  {
    model: Models.ANGLE_CONNECTION_OUTSIDE,
    translationKey: "roofDetails.angleConnectionOutside",
    rendererCall: () => renderer.displayModel(Models.ANGLE_CONNECTION_OUTSIDE),
  },
  {
    model: Models.SETTLEMENT_JOINT,
    translationKey: "roofDetails.settlementJoint",
    rendererCall: () => renderer.displayModel(Models.SETTLEMENT_JOINT),
  },
  {
    model: Models.ROOF_TERMINAL,
    translationKey: "roofDetails.roofTerminal",
    rendererCall: () => renderer.displayModel(Models.ROOF_TERMINAL),
  },
  {
    model: Models.ROOF_UPSTAND,
    translationKey: "roofDetails.roofUpstandBoth",
    rendererCall: () => renderer.displayModel(Models.ROOF_UPSTAND),
  },
  {
    model: Models.ROOF_UPSTAND_VERTICAL,
    translationKey: "roofDetails.roofUpstandVertical",
    rendererCall: () => renderer.displayModel(Models.ROOF_UPSTAND_VERTICAL),
  },
  {
    model: Models.ROOF_UPSTAND_HORIZONTAL,
    translationKey: "roofDetails.roofUpstandHorizontal",
    rendererCall: () => renderer.displayModel(Models.ROOF_UPSTAND_HORIZONTAL),
  },
  {
    model: Models.ROOF_SURFACE,
    translationKey: "roofDetails.roofSurface",
    rendererCall: () => renderer.displayModel(Models.ROOF_SURFACE),
  },
  {
    model: Models.DOME,
    translationKey: "roofDetails.dome",
    rendererCall: () => renderer.displayModel(Models.DOME),
  },
  {
    model: Models.VERTICAL_WALL_BRICK,
    translationKey: "roofDetails.verticalWallBrick",
    rendererCall: () => renderer.displayModel(Models.VERTICAL_WALL_BRICK),
  },
  {
    model: Models.VERTICAL_WALL_CONCRETE,
    translationKey: "roofDetails.verticalWallConcrete",
    rendererCall: () => renderer.displayModel(Models.VERTICAL_WALL_CONCRETE),
  },
  {
    model: Models.DRAINAGE_PIPE_HORIZONTAL,
    translationKey: "roofDetails.drainagePipeHorizontal",
    rendererCall: () => renderer.displayModel(Models.DRAINAGE_PIPE_HORIZONTAL),
  },
  {
    model: Models.DRAINAGE_PIPE_VERTICAL,
    translationKey: "roofDetails.drainagePipeVertical",
    rendererCall: () => renderer.displayModel(Models.DRAINAGE_PIPE_VERTICAL),
  },
];

// FLAT ROOF - ATELIA
const flatRoofAteliaDetailOptions: detailOption[] = [
  {
    model: Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION,
    translationKey: "roofDetails.angleConnectionInside",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE,
    translationKey: "roofDetails.angleConnectionOutside",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT,
    translationKey: "roofDetails.settlementJoint",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL,
    translationKey: "roofDetails.roofTerminal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND,
    translationKey: "roofDetails.roofUpstandBoth",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL,
    translationKey: "roofDetails.roofUpstandVertical",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL,
    translationKey: "roofDetails.roofUpstandHorizontal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_ROOF_SURFACE,
    translationKey: "roofDetails.roofSurface",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_ROOF_SURFACE),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_DOME,
    translationKey: "roofDetails.dome",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_DOME),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK,
    translationKey: "roofDetails.verticalWallBrick",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE,
    translationKey: "roofDetails.verticalWallConcrete",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL,
    translationKey: "roofDetails.drainagePipeHorizontal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL),
  },
  {
    model: Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL,
    translationKey: "roofDetails.drainagePipeVertical",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL),
  },
];

// FLAT ROOF - E CIRCULAIR
const flatRoofECirculairDetailOptions: detailOption[] = [
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION,
    translationKey: "roofDetails.angleConnectionInside",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE,
    translationKey: "roofDetails.angleConnectionOutside",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT,
    translationKey: "roofDetails.settlementJoint",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL,
    translationKey: "roofDetails.roofTerminal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND,
    translationKey: "roofDetails.roofUpstandBoth",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL,
    translationKey: "roofDetails.roofUpstandVertical",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL,
    translationKey: "roofDetails.roofUpstandHorizontal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE,
    translationKey: "roofDetails.roofSurface",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_DOME,
    translationKey: "roofDetails.dome",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_DOME),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK,
    translationKey: "roofDetails.verticalWallBrick",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE,
    translationKey: "roofDetails.verticalWallConcrete",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL,
    translationKey: "roofDetails.drainagePipeHorizontal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL),
  },
  {
    model: Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL,
    translationKey: "roofDetails.drainagePipeVertical",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL),
  },
];

// FLAT ROOF - FLAME FREE
const flatRoofFlameFreeDetailOptions: detailOption[] = [
  {
    model: Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION,
    translationKey: "roofDetails.angleConnectionInside",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE,
    translationKey: "roofDetails.angleConnectionOutside",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT,
    translationKey: "roofDetails.settlementJoint",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL,
    translationKey: "roofDetails.roofTerminal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND,
    translationKey: "roofDetails.roofUpstandBoth",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL,
    translationKey: "roofDetails.roofUpstandVertical",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL,
    translationKey: "roofDetails.roofUpstandHorizontal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE,
    translationKey: "roofDetails.roofSurface",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_DOME,
    translationKey: "roofDetails.dome",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_DOME),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK,
    translationKey: "roofDetails.verticalWallBrick",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE,
    translationKey: "roofDetails.verticalWallConcrete",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL,
    translationKey: "roofDetails.drainagePipeHorizontal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL),
  },
  {
    model: Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL,
    translationKey: "roofDetails.drainagePipeVertical",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL),
  },
];

// FLAT ROOF - RETENTION
const flatRoofRetentionDetailOptions: detailOption[] = [
  {
    model: Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION,
    translationKey: "roofDetails.angleConnectionInside",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE,
    translationKey: "roofDetails.angleConnectionOutside",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT,
    translationKey: "roofDetails.settlementJoint",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL,
    translationKey: "roofDetails.roofTerminal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND,
    translationKey: "roofDetails.roofUpstandBoth",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL,
    translationKey: "roofDetails.roofUpstandVertical",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL,
    translationKey: "roofDetails.roofUpstandHorizontal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_ROOF_SURFACE,
    translationKey: "roofDetails.roofSurface",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_ROOF_SURFACE),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_DOME,
    translationKey: "roofDetails.dome",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_DOME),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK,
    translationKey: "roofDetails.verticalWallBrick",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE,
    translationKey: "roofDetails.verticalWallConcrete",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL,
    translationKey: "roofDetails.drainagePipeHorizontal",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL),
  },
  {
    model: Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL,
    translationKey: "roofDetails.drainagePipeVertical",
    rendererCall: () => renderer.displayModel(Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL),
  },
];

// CAVITY WALL
const cavityWallDetailOptions: detailOption[] = [
  {
    model: Models.CAVITY_WALL_FLAT_ROOF,
    translationKey: "cavityWall.detail.flatRoof",
    rendererCall: () => renderer.displayModel(Models.CAVITY_WALL_FLAT_ROOF),
  },
  {
    model: Models.CAVITY_WALL_SLOPED_ROOF,
    translationKey: "cavityWall.detail.slopedRoof",
    rendererCall: () => renderer.displayModel(Models.CAVITY_WALL_SLOPED_ROOF),
  },
  {
    model: Models.CAVITY_WALL_WINDOW_CONNECTION,
    translationKey: "cavityWall.detail.windowConnection",
    rendererCall: () =>
      renderer.displayModel(Models.CAVITY_WALL_WINDOW_CONNECTION),
  },
  {
    model: Models.CAVITY_WALL_FOOTING,
    translationKey: "cavityWall.detail.footing",
    rendererCall: () => renderer.displayModel(Models.CAVITY_WALL_FOOTING),
  },
  {
    model: Models.CAVITY_WALL_FACADE,
    translationKey: "cavityWall.detail.facade",
    rendererCall: () => renderer.displayModel(Models.CAVITY_WALL_FACADE),
  },
];

// SLOPED ROOF
const slopedRoofDetailOptions: detailOption[] = [
  {
    model: Models.SLOPED_ROOF_ROOF_SURFACE,
    translationKey: "slopedRoof.detail.roofSurface",
    rendererCall: () => renderer.displayModel(Models.SLOPED_ROOF_ROOF_SURFACE),
  },
  {
    model: Models.SLOPED_ROOF_VALLEY_GUTTER,
    translationKey: "slopedRoof.detail.valleyGutter",
    rendererCall: () => renderer.displayModel(Models.SLOPED_ROOF_VALLEY_GUTTER),
  },
  {
    model: Models.SLOPED_ROOF_FOOTING_BEAM,
    translationKey: "slopedRoof.detail.footingBeam",
    rendererCall: () => renderer.displayModel(Models.SLOPED_ROOF_FOOTING_BEAM),
  },
  {
    model: Models.SLOPED_ROOF_CHIMNEY,
    translationKey: "slopedRoof.detail.chimney",
    rendererCall: () => renderer.displayModel(Models.SLOPED_ROOF_CHIMNEY),
  },
  {
    model: Models.SLOPED_ROOF_SKYLIGHT,
    translationKey: "slopedRoof.detail.skylight",
    rendererCall: () => renderer.displayModel(Models.SLOPED_ROOF_SKYLIGHT),
  },
  {
    model: Models.SLOPED_ROOF_RIDGE,
    translationKey: "slopedRoof.detail.ridge",
    rendererCall: () => renderer.displayModel(Models.SLOPED_ROOF_RIDGE),
  },
];

// VENTILATED WALL
const ventilatedWallDetailOptions: detailOption[] = [
  {
    model: Models.VENTILATED_WALL_FACADE,
    translationKey: "ventilatedWall.detail.facade",
    rendererCall: () => renderer.displayModel(Models.VENTILATED_WALL_FACADE),
  },
  {
    model: Models.VENTILATED_WALL_WINDOW_CONNECTION,
    translationKey: "ventilatedWall.detail.windowConnection",
    rendererCall: () =>
      renderer.displayModel(Models.VENTILATED_WALL_WINDOW_CONNECTION),
  },
  {
    model: Models.VENTILATED_WALL_FOOTING,
    translationKey: "ventilatedWall.detail.footing",
    rendererCall: () => renderer.displayModel(Models.VENTILATED_WALL_FOOTING),
  },
  {
    model: Models.VENTILATED_WALL_WALL_CONNECTION,
    translationKey: "ventilatedWall.detail.wallConnection",
    rendererCall: () =>
      renderer.displayModel(Models.VENTILATED_WALL_WALL_CONNECTION),
  },
];

// FLOOR
const floorDetailOptions: detailOption[] = [
  {
    model: Models.FLOOR_PLANE,
    translationKey: "floor.detail.floorPlane",
    rendererCall: () => renderer.displayModel(Models.FLOOR_PLANE),
  },
  {
    model: Models.FLOOR_WALL_CONNECTION,
    translationKey: "floor.detail.wallConnection",
    rendererCall: () => renderer.displayModel(Models.FLOOR_WALL_CONNECTION),
  },
];

// SOL
const solDallporteeDetailOptions: detailOption[] = [
  {
    model: Models.SOL_PLANE_DALLEPORTEE,
    translationKey: "sol.detail.solPlane",
    rendererCall: () => renderer.displayModel(Models.SOL_PLANE_DALLEPORTEE),
  },
  {
    model: Models.SOL_WALL_CONNECTION_DALLEPORTEE,
    translationKey: "sol.detail.wallConnection",
    rendererCall: () => renderer.displayModel(Models.SOL_WALL_CONNECTION_DALLEPORTEE),
  },
];
const solSouschapeDetailOptions: detailOption[] = [
  {
    model: Models.SOL_PLANE_SOUSCHAPE,
    translationKey: "sol.detail.solPlane",
    rendererCall: () => renderer.displayModel(Models.SOL_PLANE_SOUSCHAPE),
  },
  {
    model: Models.SOL_WALL_CONNECTION_SOUSCHAPE,
    translationKey: "sol.detail.wallConnection",
    rendererCall: () => renderer.displayModel(Models.SOL_WALL_CONNECTION_SOUSCHAPE),
  },
];

const solSousdallageDetailOptions: detailOption[] = [
  {
    model: Models.SOL_PLANE_SOUSDALLAGE,
    translationKey: "sol.detail.solPlane",
    rendererCall: () => renderer.displayModel(Models.SOL_PLANE_SOUSDALLAGE),
  },
  {
    model: Models.SOL_WALL_CONNECTION_SOUSDALLAGE,
    translationKey: "sol.detail.wallConnection",
    rendererCall: () => renderer.displayModel(Models.SOL_WALL_CONNECTION_SOUSDALLAGE),
  }
];

const detailModelsMap: any = {
  // FLAT ROOF
  FLAT_ROOF: flatRoofDetailOptions,
  FLAT_ROOF_ATELIA: flatRoofAteliaDetailOptions,
  FLAT_ROOF_E_CIRCULAIR: flatRoofECirculairDetailOptions,
  FLAT_ROOF_FLAME_FREE: flatRoofFlameFreeDetailOptions,
  FLAT_ROOF_RETENTION: flatRoofRetentionDetailOptions,
  // CAVITY WALL
  CAVITY_WALL: cavityWallDetailOptions,
  // SLOPED ROOF
  SLOPED_ROOF: slopedRoofDetailOptions,
  // VENTILATED WALL
  OUTER_WALL: ventilatedWallDetailOptions,
  // FLOOR
  FLOOR: floorDetailOptions,
  // SOL
  SOL: solDallporteeDetailOptions,
};

type ViewerNavigationDropdownProps = {
  applicationType: ApplicationTypes | null;
  setApplicationType: (type: ApplicationTypes) => void;
  currentModel: Models;
};

const Nav = styled.nav`
  width: auto;
  display: flex;
  flex-direction: column;
`;

const NavHeader = styled.div<{
  displayFirstDropdown: boolean;
  displaySecondDropdown: boolean;
}>`
  display: flex;
  height: 36px;
  width: auto;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: ${(props) =>
    props.displayFirstDropdown ? 0 : 5}px;
  border-bottom-right-radius: ${(props) =>
    props.displaySecondDropdown || props.displayFirstDropdown ? 0 : 5}px;
  background: ${styles.colors.dark2};
`;

const Section = styled.div`
  cursor: pointer;
  display: flex;
  height: 100%;
  width: auto;
  align-items: center;
`;

const NavHeaderLeftSection = styled(Section) <{
  canDisplaySecondDropdown: boolean;
}>`
  padding-left: 20px;
  padding-right: ${(props) => (props.canDisplaySecondDropdown ? 0 : 20)}px;

  @media screen and (pointer: coarse) {
    padding: 0 16px;
  }
`;

const NavHeaderRightSection = styled(Section)`
  padding-right: 20px;

  @media screen and (pointer: coarse) {
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    p {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const NavBody = styled.div<{
  displayFirstDropdown: boolean;
  displaySecondDropdown: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.displayFirstDropdown ? "flex-start" : "flex-end"};
`;

const NavDropdown = styled.div`
  background: ${styles.colors.viewerNavigationDropdown};
  margin-top: -5px;
  padding: 5px 0;
  border-radius: 0 0 5px 5px;

  p {
    font-size: 14px;
    line-height: 22px;
  }
`;

const NavDropdownItem = styled.div<{
  borderTop: boolean;
  disableCursor: boolean;
}>`
  display: flex;
  align-items: center;
  height: 36px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  border-top: ${(props) =>
    props.borderTop
      ? `1px solid ${styles.colors.viewerNavigationDropdownBorder}`
      : `none`};
  cursor: ${(props) => (props.disableCursor ? `not-allowed` : "pointer")};
`;

export const ViewerNavigationDropdown = ({
  applicationType,
  currentModel,
  setApplicationType,
}: ViewerNavigationDropdownProps) => {
  // eslint-disable-next-line
  const [displayFirstDropdown, setDisplayFirstDropdown] = useState(false);
  const [displaySecondDropdown, setDisplaySecondDropdown] = useState(false);
  const { applicationOptions } = useHome();
  const { Routes } = useRoutes() as any;
  const { configurationState: { roofType } } = useFlatRoof();
  const location = useLocation();
  const isPreviewPage = location.pathname === Routes.PREVIEW

  if (!isPreviewPage && !applicationType) return null;

  const canDisplaySecondDropdown =
    // FLAT ROOF
    currentModel === Models.ANGLE_CONNECTION ||
    currentModel === Models.ANGLE_CONNECTION_OUTSIDE ||
    currentModel === Models.SETTLEMENT_JOINT ||
    currentModel === Models.ROOF_TERMINAL ||
    currentModel === Models.ROOF_UPSTAND ||
    currentModel === Models.ROOF_UPSTAND_VERTICAL ||
    currentModel === Models.ROOF_UPSTAND_HORIZONTAL ||
    currentModel === Models.ROOF_SURFACE ||
    currentModel === Models.DOME ||
    currentModel === Models.VERTICAL_WALL_BRICK ||
    currentModel === Models.VERTICAL_WALL_CONCRETE ||
    currentModel === Models.DRAINAGE_PIPE_HORIZONTAL ||
    currentModel === Models.DRAINAGE_PIPE_VERTICAL ||
    // FLAT ROOF - ATELIA
    currentModel === Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION ||
    currentModel === Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE ||
    currentModel === Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT ||
    currentModel === Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL ||
    currentModel === Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND ||
    currentModel === Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL ||
    currentModel === Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL ||
    currentModel === Models.FLAT_ROOF_ATELIA_ROOF_SURFACE ||
    currentModel === Models.FLAT_ROOF_ATELIA_DOME ||
    currentModel === Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK ||
    currentModel === Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE ||
    currentModel === Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL ||
    currentModel === Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL ||
    // FLAT ROOF - E CIRCULAIR
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_DOME ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL ||
    currentModel === Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL ||
    // FLAT ROOF - FLAME FREE
    currentModel === Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_DOME ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL ||
    currentModel === Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL ||
    // FLAT ROOF - RETENTION
    currentModel === Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION ||
    currentModel === Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE ||
    currentModel === Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT ||
    currentModel === Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL ||
    currentModel === Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND ||
    currentModel === Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL ||
    currentModel === Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL ||
    currentModel === Models.FLAT_ROOF_RETENTION_ROOF_SURFACE ||
    currentModel === Models.FLAT_ROOF_RETENTION_DOME ||
    currentModel === Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK ||
    currentModel === Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE ||
    currentModel === Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL ||
    currentModel === Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL ||
    // CAVITY WALL
    currentModel === Models.CAVITY_WALL_FLAT_ROOF ||
    currentModel === Models.CAVITY_WALL_SLOPED_ROOF ||
    currentModel === Models.CAVITY_WALL_WINDOW_CONNECTION ||
    currentModel === Models.CAVITY_WALL_FOOTING ||
    currentModel === Models.CAVITY_WALL_FACADE ||
    // SLOPED ROOF
    currentModel === Models.SLOPED_ROOF_ROOF_SURFACE ||
    currentModel === Models.SLOPED_ROOF_VALLEY_GUTTER ||
    currentModel === Models.SLOPED_ROOF_FOOTING_BEAM ||
    currentModel === Models.SLOPED_ROOF_CHIMNEY ||
    currentModel === Models.SLOPED_ROOF_SKYLIGHT ||
    currentModel === Models.SLOPED_ROOF_RIDGE ||
    // VENTILATED WALL
    currentModel === Models.VENTILATED_WALL_FACADE ||
    currentModel === Models.VENTILATED_WALL_WINDOW_CONNECTION ||
    currentModel === Models.VENTILATED_WALL_FOOTING ||
    currentModel === Models.VENTILATED_WALL_WALL_CONNECTION ||
    // FLOOR
    currentModel === Models.FLOOR_PLANE ||
    currentModel === Models.FLOOR_WALL_CONNECTION ||
    // SOL
    currentModel === Models.SOL_PLANE_DALLEPORTEE ||
    currentModel === Models.SOL_PLANE_SOUSCHAPE ||
    currentModel === Models.SOL_PLANE_SOUSDALLAGE ||
    currentModel === Models.SOL_WALL_CONNECTION_DALLEPORTEE ||
    currentModel === Models.SOL_WALL_CONNECTION_SOUSCHAPE ||
    currentModel === Models.SOL_WALL_CONNECTION_SOUSDALLAGE ||

    isMobile();

  function onLeftMenuMouseEnter() {
    setDisplayFirstDropdown(true);
  }
  function onLeftMenuMouseLeave() {
    setDisplayFirstDropdown(false);
  }
  function onRightMenuMouseEnter() {
    setDisplaySecondDropdown(canDisplaySecondDropdown && true);
  }
  function onRightMenuMouseLeave() {
    setDisplaySecondDropdown(false);
  }

  function handleApplicationItemClick({
    disabled,
    rendererCall,
    type,
  }: ApplicationOption) {
    if (!disabled && rendererCall) {
      setApplicationType(type);
      if (applicationType === ApplicationTypes.FLAT_ROOF) {
        let model = Models.FLAT_ROOF
        switch (currentModel) {
          case Models.FLAT_ROOF_ATELIA:
          case Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION:
          case Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE:
          case Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT:
          case Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL:
          case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND:
          case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL:
          case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL:
          case Models.FLAT_ROOF_ATELIA_ROOF_SURFACE:
          case Models.FLAT_ROOF_ATELIA_DOME:
          case Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK:
          case Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE:
          case Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL:
          case Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL:
            model = Models.FLAT_ROOF_ATELIA
            break;
          case Models.FLAT_ROOF_E_CIRCULAIR:
          case Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION:
          case Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE:
          case Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT:
          case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL:
          case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND:
          case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL:
          case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL:
          case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE:
          case Models.FLAT_ROOF_E_CIRCULAIR_DOME:
          case Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK:
          case Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE:
          case Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL:
          case Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL:
            model = Models.FLAT_ROOF_E_CIRCULAIR
            break;
          case Models.FLAT_ROOF_FLAME_FREE:
          case Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION:
          case Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE:
          case Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT:
          case Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL:
          case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND:
          case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL:
          case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL:
          case Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE:
          case Models.FLAT_ROOF_FLAME_FREE_DOME:
          case Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK:
          case Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE:
          case Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL:
          case Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL:
            model = Models.FLAT_ROOF_FLAME_FREE
            break;
          case Models.FLAT_ROOF_RETENTION:
          case Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION:
          case Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE:
          case Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT:
          case Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL:
          case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND:
          case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL:
          case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL:
          case Models.FLAT_ROOF_RETENTION_ROOF_SURFACE:
          case Models.FLAT_ROOF_RETENTION_DOME:
          case Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK:
          case Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE:
          case Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL:
          case Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL:
            model = Models.FLAT_ROOF_RETENTION
            break;
        }

        renderer.displayModel(model).then(() => {
          if (roofType.type === RoofTypeTypes.STANDARD_ROOF) {
            renderer.toggleLayers("DAKBEDEKKING", false)
          }
        });
      } else {
        rendererCall();
      }
      if (isPreviewPage) renderer.displayMarkers();
    }

  }

  function handleDetailClick({ rendererCall }: detailOption) {
    if (rendererCall) {
      rendererCall();
      setDisplaySecondDropdown(false);
    }
  }

  const templateHeader = {
    type: ApplicationTypes.FLAT_ROOF,
    translationKey: "home.chooseApplication",
    icon: () => (<div></div>),
    rendererCall: () => renderer.displayModel(Models.DOLLHOUSE),
    disabled: true
  }

  let headerApplicationOption = isPreviewPage && !applicationType ?
    templateHeader : applicationOptions.find(
      (opt) => opt.type === applicationType
    );

  let dropdownApplicationOptions = applicationOptions.filter(
    (opt) => !opt.disabled
  );

  let headerDetailOption = !applicationType ? [] : detailModelsMap[applicationType].find(
    (opt: detailOption) => opt.model === currentModel
  )!;

  let detailOptions = !applicationType ? [] : detailModelsMap[applicationType]

  // FLAT ROOF
  if (applicationType === ApplicationTypes.FLAT_ROOF) {

    switch (currentModel) {
      case Models.FLAT_ROOF_ATELIA:
      case Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION:
      case Models.FLAT_ROOF_ATELIA_ANGLE_CONNECTION_OUTSIDE:
      case Models.FLAT_ROOF_ATELIA_SETTLEMENT_JOINT:
      case Models.FLAT_ROOF_ATELIA_ROOF_TERMINAL:
      case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND:
      case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_HORIZONTAL:
      case Models.FLAT_ROOF_ATELIA_ROOF_UPSTAND_VERTICAL:
      case Models.FLAT_ROOF_ATELIA_ROOF_SURFACE:
      case Models.FLAT_ROOF_ATELIA_DOME:
      case Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_BRICK:
      case Models.FLAT_ROOF_ATELIA_VERTICAL_WALL_CONCRETE:
      case Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_HORIZONTAL:
      case Models.FLAT_ROOF_ATELIA_DRAINAGE_PIPE_VERTICAL:
        headerDetailOption = flatRoofAteliaDetailOptions.find(
          (opt: detailOption) => opt.model === currentModel
        )!;
        detailOptions = flatRoofAteliaDetailOptions
        break
      case Models.FLAT_ROOF_E_CIRCULAIR:
      case Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION:
      case Models.FLAT_ROOF_E_CIRCULAIR_ANGLE_CONNECTION_OUTSIDE:
      case Models.FLAT_ROOF_E_CIRCULAIR_SETTLEMENT_JOINT:
      case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_TERMINAL:
      case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND:
      case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_HORIZONTAL:
      case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_UPSTAND_VERTICAL:
      case Models.FLAT_ROOF_E_CIRCULAIR_ROOF_SURFACE:
      case Models.FLAT_ROOF_E_CIRCULAIR_DOME:
      case Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_BRICK:
      case Models.FLAT_ROOF_E_CIRCULAIR_VERTICAL_WALL_CONCRETE:
      case Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_HORIZONTAL:
      case Models.FLAT_ROOF_E_CIRCULAIR_DRAINAGE_PIPE_VERTICAL:
        headerDetailOption = flatRoofECirculairDetailOptions.find(
          (opt: detailOption) => opt.model === currentModel
        )!;
        detailOptions = flatRoofECirculairDetailOptions
        break
      case Models.FLAT_ROOF_FLAME_FREE:
      case Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION:
      case Models.FLAT_ROOF_FLAME_FREE_ANGLE_CONNECTION_OUTSIDE:
      case Models.FLAT_ROOF_FLAME_FREE_SETTLEMENT_JOINT:
      case Models.FLAT_ROOF_FLAME_FREE_ROOF_TERMINAL:
      case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND:
      case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_HORIZONTAL:
      case Models.FLAT_ROOF_FLAME_FREE_ROOF_UPSTAND_VERTICAL:
      case Models.FLAT_ROOF_FLAME_FREE_ROOF_SURFACE:
      case Models.FLAT_ROOF_FLAME_FREE_DOME:
      case Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_BRICK:
      case Models.FLAT_ROOF_FLAME_FREE_VERTICAL_WALL_CONCRETE:
      case Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_HORIZONTAL:
      case Models.FLAT_ROOF_FLAME_FREE_DRAINAGE_PIPE_VERTICAL:
        headerDetailOption = flatRoofFlameFreeDetailOptions.find(
          (opt: detailOption) => opt.model === currentModel
        )!;
        detailOptions = flatRoofFlameFreeDetailOptions
        break
      case Models.FLAT_ROOF_RETENTION:
      case Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION:
      case Models.FLAT_ROOF_RETENTION_ANGLE_CONNECTION_OUTSIDE:
      case Models.FLAT_ROOF_RETENTION_SETTLEMENT_JOINT:
      case Models.FLAT_ROOF_RETENTION_ROOF_TERMINAL:
      case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND:
      case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_HORIZONTAL:
      case Models.FLAT_ROOF_RETENTION_ROOF_UPSTAND_VERTICAL:
      case Models.FLAT_ROOF_RETENTION_ROOF_SURFACE:
      case Models.FLAT_ROOF_RETENTION_DOME:
      case Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_BRICK:
      case Models.FLAT_ROOF_RETENTION_VERTICAL_WALL_CONCRETE:
      case Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_HORIZONTAL:
      case Models.FLAT_ROOF_RETENTION_DRAINAGE_PIPE_VERTICAL:
        headerDetailOption = flatRoofRetentionDetailOptions.find(
          (opt: detailOption) => opt.model === currentModel
        )!;
        detailOptions = flatRoofRetentionDetailOptions
        break
    }
  }


  // SOL
  if (applicationType === ApplicationTypes.SOL) {
    switch (currentModel) {
      case Models.SOL_DALLEPORTEE:
      case Models.SOL_PLANE_DALLEPORTEE:
      case Models.SOL_WALL_CONNECTION_DALLEPORTEE:
        headerApplicationOption = {
          type: headerApplicationOption?.type ?? templateHeader.type,
          translationKey: headerApplicationOption?.translationKey ?? templateHeader.translationKey,
          icon: headerApplicationOption?.icon ?? templateHeader.icon,
          rendererCall: () => renderer.displayModel(Models.SOL_DALLEPORTEE),
          disabled: headerApplicationOption?.disabled ?? templateHeader.disabled
        }
        detailOptions = solDallporteeDetailOptions
        break;
      case Models.SOL_SOUSCHAPE:
      case Models.SOL_PLANE_SOUSCHAPE:
      case Models.SOL_WALL_CONNECTION_SOUSCHAPE:
        headerApplicationOption = {
          type: headerApplicationOption?.type ?? templateHeader.type,
          translationKey: headerApplicationOption?.translationKey ?? templateHeader.translationKey,
          icon: headerApplicationOption?.icon ?? templateHeader.icon,
          rendererCall: () => renderer.displayModel(Models.SOL_SOUSCHAPE),
          disabled: headerApplicationOption?.disabled ?? templateHeader.disabled
        }
        detailOptions = solSouschapeDetailOptions
        break;
      case Models.SOL_SOUSDALLAGE:
      case Models.SOL_PLANE_SOUSDALLAGE:
      case Models.SOL_WALL_CONNECTION_SOUSDALLAGE:
        headerApplicationOption = {
          type: headerApplicationOption?.type ?? templateHeader.type,
          translationKey: headerApplicationOption?.translationKey ?? templateHeader.translationKey,
          icon: headerApplicationOption?.icon ?? templateHeader.icon,
          rendererCall: () => renderer.displayModel(Models.SOL_SOUSDALLAGE),
          disabled: headerApplicationOption?.disabled ?? templateHeader.disabled
        }
        detailOptions = solSousdallageDetailOptions
        break;
    }
  }

  if (applicationType === ApplicationTypes.SOL && isPreviewPage) {
    dropdownApplicationOptions = [
      {
        type: ApplicationTypes.SOL,
        translationKey: "sol.solInsulation.CHAPETG",
        icon: FloorIcon,
        rendererCall: () => renderer.displayModel(Models.SOL_SOUSCHAPE),
        disabled: false
      },
      {
        type: ApplicationTypes.SOL,
        translationKey: "sol.solInsulation.KRALU_SOUSDALLAGE",
        icon: FloorIcon,
        rendererCall: () => renderer.displayModel(Models.SOL_SOUSDALLAGE),
        disabled: false
      },
      {
        type: ApplicationTypes.SOL,
        translationKey: "sol.solInsulation.KRALU_DALLEPORTEE",
        icon: FloorIcon,
        rendererCall: () => renderer.displayModel(Models.SOL_DALLEPORTEE),
        disabled: false
      },
    ]
  }

  // @ts-ignore
  return (
    <Nav>
      <NavHeader
        displaySecondDropdown={displaySecondDropdown}
        displayFirstDropdown={displayFirstDropdown}
      >
        <NavHeaderLeftSection
          canDisplaySecondDropdown={canDisplaySecondDropdown}
          onMouseEnter={onLeftMenuMouseEnter}
          onMouseLeave={onLeftMenuMouseLeave}
          onClick={() => handleApplicationItemClick(headerApplicationOption!)}
        >
          <ApplicationItem applicationOption={headerApplicationOption!} />
          {!canDisplaySecondDropdown && <Spacer widthPx={16} />}
        </NavHeaderLeftSection>
        {canDisplaySecondDropdown && (
          <NavHeaderRightSection
            onClick={() => {
              if (isMobile()) {
                onRightMenuMouseEnter();
              }
            }}
            onMouseEnter={onRightMenuMouseEnter}
            onMouseLeave={onRightMenuMouseLeave}
          >
            <SubNavigationIcon />
            <Detail
              translationKey={headerDetailOption?.translationKey ?? "Detail"}
              isHeader={true}
            />
          </NavHeaderRightSection>
        )}
      </NavHeader>
      <NavBody
        displaySecondDropdown={displaySecondDropdown}
        displayFirstDropdown={displayFirstDropdown}
      >
        {(displayFirstDropdown && isPreviewPage) && (
          <NavDropdown
            onMouseEnter={onLeftMenuMouseEnter}
            onMouseLeave={onLeftMenuMouseLeave}
          >
            {dropdownApplicationOptions.map((applicationOption, index) => (
              <NavDropdownItem
                key={applicationOption.type}
                borderTop={index !== 0}
                onClick={() => handleApplicationItemClick(applicationOption)}
                disableCursor={applicationOption.disabled}
              >
                <ApplicationItem applicationOption={applicationOption} />
              </NavDropdownItem>
            ))}
          </NavDropdown>
        )}
        {displaySecondDropdown && (
          <NavDropdown
            onMouseEnter={onRightMenuMouseEnter}
            onMouseLeave={onRightMenuMouseLeave}
          >
            {detailOptions.map((detailOption: detailOption, index: number) => (
              <NavDropdownItem
                key={detailOption.model}
                borderTop={index !== 0}
                onClick={() => handleDetailClick(detailOption)}
                disableCursor={false}
              >
                <Detail
                  translationKey={detailOption.translationKey}
                  active={detailOption.model === currentModel}
                />
              </NavDropdownItem>
            ))}
          </NavDropdown>
        )}
      </NavBody>
    </Nav>
  );
};

const ApplicationItemWrapper = styled.div`
  @media screen and (pointer: coarse) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    svg {
      height: 24px;
      width: 24px;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const ApplicationItem = ({
  applicationOption,
}: {
  applicationOption: ApplicationOption;
}) => {
  const { t } = useTranslation();

  if (!applicationOption) {
    return null
  }

  const { icon: Icon, translationKey } = applicationOption;

  return (
    <ApplicationItemWrapper style={{ display: "flex", alignItems: "center" }}>
      <Icon lineColor={styles.colors.white} />
      <Spacer widthPx={13} />
      <P fontSizePx={14} lineHeightPx={16.41} color={styles.colors.white}>
        {
          //@ts-ignore
          t(translationKey)
        }
      </P>
    </ApplicationItemWrapper>
  );
};

const Detail = ({
  translationKey,
  active = true,
  isHeader = false,
}: {
  translationKey: string;
  active?: boolean;
  isHeader?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <P
      color={active ? styles.colors.white : styles.colors.white65}
      fontSizePx={14}
      lineHeightPx={22}
      fontWeight={isHeader ? 400 : active ? 500 : 400}
    >
      {
        //@ts-ignore
        t(translationKey)
      }
    </P>
  );
};

const SubNavigationIcon = () => {
  return (
    <div style={{ display: "flex" }}>
      {!isMobile() && <Spacer widthPx={20} />}
      <svg
        width={!isMobile() ? "6" : "8"}
        height={!isMobile() ? "10" : "12"}
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.40042 9.80005H0.600422C0.446022 9.80005 0.305222 9.71085 0.238822 9.57125C0.172422 9.43165 0.192822 9.26645 0.290822 9.14645L3.68362 5.00005L0.290822 0.85325C0.192822 0.733649 0.172822 0.568449 0.238822 0.428449C0.304822 0.288449 0.446022 0.200049 0.600422 0.200049H1.40042C1.52042 0.200049 1.63402 0.254049 1.71002 0.346849L5.31002 4.74685C5.43042 4.89445 5.43042 5.10605 5.31002 5.25365L1.71002 9.65365C1.63402 9.74605 1.52042 9.80005 1.40042 9.80005Z"
          fill="#D4D4DB"
        />
      </svg>
      {!isMobile() && <Spacer widthPx={20} />}
    </div>
  );
};

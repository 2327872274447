import React from "react";
import styled from "styled-components";
import { isMobile } from "../../services/helpers.service";
import { styles } from "../../styles";
import { Spacer } from "./Spacer";
import { H2, P } from "./Typography";

export type OverviewItemProps = {
  title: string;
  text: string;
  suffix?: string;
};

const NameAndTypeContainer = styled.div`
  display: flex;
  
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: ${20 / 16}rem;
    margin-top: 0 !important;

    @media screen and (pointer: coarse) {
      font-size: 1.2rem;
    }
  }
`;

const OverflowText = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: ${styles.colors.dark2};
  line-height: ${20 / 16}rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

    @media screen and (pointer: coarse) {
      font-size: 1.2rem;
    }
`;

const DividerLine = styled.div`
  width: 0.1rem;
  height: 0.9rem;
  opacity: 0.7;
  background: ${styles.colors.primary};
  align-self: center;
`;

export const OverviewItem = ({ title, text, suffix }: OverviewItemProps) => {
  return (
    <div>
      <H2 fontSizePx={12} lineHeightPx={14.06} color={styles.colors.darkBlue50}>
        {title}
      </H2>
      <Spacer heightPx={2} />
      <NameAndTypeContainer>
        <OverflowText>{text}</OverflowText>
        {suffix && (
          <>
            {!isMobile() && <Spacer widthPx={5} />}
            {!isMobile() && (<>
              <DividerLine></DividerLine>
            </>
            )}
            {!isMobile() && <Spacer widthPx={5} />}
            <P fontSizePx={14} color={styles.colors.darkBlue75}>
              {suffix}
            </P>
          </>
        )}
      </NameAndTypeContainer>
    </div >
  );
};

export const possibleCombinations = [

    // TWO_BITUMINOUS
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_360P60 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_360P60 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_360P60 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_360P60 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_360P60 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "SUMMA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_360P60 / Loose", topLayer: "SUMMA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K14 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K14 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_P14 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_ALU4_TF / Loose", insulation: "ENERTHERM_ALU / Loose", bottomLayer: "BASE_460P60 / Loose", topLayer: "POWERGUM_470K24_DGRIJS / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "POWERGUM_470K24_WIT / Welding", finishing: "", },

    // TWO_BITUMINOUS_AIR_PURIFYING
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },

    // TWO_BITUMINOUS_AIR_PURIFYING_FLAME_FREE
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Energy roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Energy roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Energy roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },

    // TWO_BITUMINOUS_CIRCULAIR
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Loose", bottomLayer: "BASE_460P60_ATELIA / Loose", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Loose", bottomLayer: "BASE_460P60_ATELIA / Loose", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Loose", bottomLayer: "BASE_460P60_ATELIA / Loose", topLayer: "POWERGUM_470K14_ATELIA / Welding", finishing: "Ballast", },

    // TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular_Air_cleaning", vaporShield: "P3_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_460P60_ATELIA / Mechanical", topLayer: "POWERGUM_4AWF_ATELIA / Welding", finishing: "", },

    // TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR_FLAME_FREE
    { default: true, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_360P60_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_360P60_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_360P60_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_360P60_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_360P60_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: true, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_360P60_ATELIA / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },

    // TWO_BITUMINOUS_FLAME_FREE
    { default: true, roofType: "Green roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "COMPACT_ALU3 / Warm bitumen", insulation: "ENERTHERM_MG / Warm bitumen", bottomLayer: "BASE_V3_TT / Warm bitumen", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "COMPACT_ALU3 / Warm bitumen", insulation: "ENERTHERM_MG / Warm bitumen", bottomLayer: "BASE_V3_TT / Warm bitumen", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "COMPACT_ALU3 / Warm bitumen", insulation: "ENERTHERM_MG / Warm bitumen", bottomLayer: "BASE_V3_TT / Warm bitumen", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "COMPACT_ALU3 / Warm bitumen", insulation: "ENERTHERM_MG / Warm bitumen", bottomLayer: "BASE_V3_TT / Warm bitumen", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_P14 / Loose", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_460P60 / Mechanical", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_360P60 / Mechanical", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "PANTERA / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARBON_HI_SPEED / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU / Glue", bottomLayer: "QUADRA_FSA / Self adhering", topLayer: "CARRARA / Welding", finishing: "", },

    // ONE_BITUMINOUS_AIR_PURIFYING_CIRCULAIR
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_single_Circular_Air_cleaning", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA_TECNO_SN / Mechanical", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_single_Circular_Air_cleaning", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA_TECNO_SN / Mechanical", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_single_Circular_Air_cleaning", vaporShield: "P3_SBS_ATELIA / Loose", insulation: "ENERTHERM_ATELIA / Mechanical", bottomLayer: "BASE_P3_SBS_TF_ATELIA / Mechanical", topLayer: "CARRARA_TECNO_SN / Mechanical", finishing: "", },

    // ONE_BITUMINOUS_FLAME_FREE
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Bituminous_single_Flame_free_installation", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "", topLayer: "SECURA / Self adhering", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Bituminous_single_Flame_free_installation", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "", topLayer: "SECURA / Self adhering", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Bituminous_single_Flame_free_installation", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "", topLayer: "SECURA / Self adhering", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Bituminous_single_Flame_free_installation", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "", topLayer: "SECURA / Self adhering", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Bituminous_single_Flame_free_installation", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "", topLayer: "SECURA / Self adhering", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Bituminous_single_Flame_free_installation", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "", topLayer: "SECURA / Self adhering", finishing: "", },

    // LIQUID
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "HYBRITECH / Liquid", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "HYBRITECH / Liquid", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "HYBRITECH / Liquid", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "HYBRITECH / Liquid", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "HYBRITECH / Liquid", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "HYBRITECH / Liquid", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Retention roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_ALU / Mechanical", bottomLayer: "BASE_P14 / Mechanical", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Retention roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Retention roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Retention roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "METATECH / Liquid", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "TANETECH / Liquid", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "TANETECH / Liquid", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "I-III", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "BASE_STICK_TSA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "TANETECH / Liquid", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Wood", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "TANETECH / Liquid", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Concrete", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "TANETECH / Liquid", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "IV", roofFloor: "Steel deck", waterproofing: "Liquid", vaporShield: "SHIELD_PRO_ALU_SA / Self adhering", insulation: "ENERTHERM_MG / Glue", bottomLayer: "BASE_STICK_TSA / Self adhering", topLayer: "TANETECH / Liquid", finishing: "", },

]
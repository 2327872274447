import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { renderer } from "../../viewer/Renderer"
import { useEffect, useState } from "react"
import { parseMatrixStringValue } from "../../../services/helpers.service"
import { useMatrix } from "./matrix.hook"

export function useSecuringMethodsInsulation() {
  const { getRemainingCombinations } = useMatrix()
  const [insulationOptions, setInsulationOptions] = useState<any>([])
  const { configurationState, setSecuringMethodInsulation } = useFlatRoof()
  const { insulation: stateInsulation } = configurationState.securingMethods
  const {
    roofType: stateRoofType,
    roofFloor: stateRoofFloor,
    waterproofing: stateWaterproofing,
  } = configurationState

  useEffect(() => {
    if (insulationOptions.length && !stateInsulation) {
      const configInsulation = insulationOptions[0]
      if (stateInsulation !== configInsulation) {
        setSecuringMethodInsulation(configInsulation)
        renderer.displayInsulationMaterial(configInsulation.type)
        renderer.displaySecuringMethod(
          "ISOLATIE-BEVESTIGING",
          configInsulation.method
        )
      }
    }
  }, [stateInsulation, setSecuringMethodInsulation, insulationOptions])

  useEffect(() => {
    // MATRIX FILTERING
    if (!insulationOptions.length) {
      const insulationOptions = getRemainingCombinations(
        stateRoofType.type,
        stateRoofType.climateClass,
        stateRoofFloor.type,
        stateWaterproofing.type
      ).map((x: any) => x.insulation)
      const uniqueInsulationOptions = insulationOptions
        .filter((item, index) => insulationOptions.indexOf(item) === index)
        .map((x: any) => parseMatrixStringValue(x))
      setInsulationOptions(uniqueInsulationOptions)
    }
  }, [
    getRemainingCombinations,
    stateRoofType,
    stateRoofFloor,
    stateWaterproofing,
    insulationOptions,
  ])

  function setInsulationType(value: string) {
    const option = parseMatrixStringValue(value)
    setSecuringMethodInsulation(option)
    if (option) {
      renderer.displayInsulationMaterial(option.type)
      renderer.displaySecuringMethod("ISOLATIE-BEVESTIGING", option.method)
    }
  }

  return {
    stateInsulation,
    insulationOptions,
    setInsulationType,
  }
}

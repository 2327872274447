import styled from "styled-components";

function getRowCSS(rows: number): string {
  let str = "";
  let row = "1fr ";
  for (let i = 1; i <= rows; i++) {
    str += row;
  }
  return str;
}

/*
 * The grid items need to be square. This is non-trivial with css.
 * The aspect-ratio css property, which would make this a breeze, is not supported on Safari
 * as of yet.
 *
 * The grid currently breaks when the sidebar reaches it's min-width of 340px
 */

export const Grid = styled.div<{ rows: number; gaps: boolean, autoHeight?: boolean }>`
  width: 100%;
  min-height: ${(props) => props.autoHeight ? 'auto' : `${props.theme.designWidth(448) - props.theme.designWidth(40) * 2}px`};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: ${(props) => getRowCSS(props.rows)};
  column-gap: ${(props) =>
    props.gaps ? `${props.theme.designWidth(11.3)}px` : `unset`};
  row-gap: ${(props) =>
    props.gaps ? `${props.theme.designWidth(11.3)}px` : `unset`};

  @media screen and (pointer: coarse) {
    min-height: unset;
  }
`;
